import * as React from 'react';

function ReviewIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M8 11l4 3 7-8"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <circle cx={12} cy={12} r={7} stroke="currentColor" strokeWidth={2} />
    </svg>
  );
}

export default React.memo(ReviewIcon);
