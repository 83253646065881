import * as React from 'react';

function SuccessCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx="12" cy="12" r="8" fill="currentColor" />
      <path
        d="M8.72294 12.2143C8.34138 11.8151 7.70839 11.8007 7.30911 12.1823C6.90983 12.5638 6.89547 13.1968 7.27704 13.5961L10.2345 16.6908C10.6842 17.1614 11.4564 17.0838 11.8034 16.5332L16.8458 8.53333C17.1403 8.06612 17.0003 7.44864 16.5331 7.15415C16.0659 6.85967 15.4484 6.99969 15.1539 7.4669L10.7949 14.3825L8.72294 12.2143Z"
        fill="#FFFFFF"
      />
    </svg>
  );
}

export default React.memo(SuccessCircleIcon);
