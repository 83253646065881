/**
 * Determines if the webpage is currently being driven
 * by Selenium, which we can be reasonably certain of if
 * the user agent of the webpage is "HeadlessChrome": a
 * user agent specifically set by our QA team.
 *
 * @returns {boolean}
 */
export function isSelenium() {
  return navigator.userAgent === 'HeadlessChrome';
}
