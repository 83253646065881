import {
  Actions,
  PrepAndSendAction,
} from 'hellospa/page/prep-and-send/data/types';
import { CoverPage } from '../types/coverPage';

export const setCoverPage = (
  coverpage: Partial<CoverPage>,
): PrepAndSendAction => ({
  type: Actions.UpdateCoverPage,
  payload: coverpage,
});
