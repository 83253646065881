import { defineMessages } from 'react-intl';

export const pendingTranslations = defineMessages({
  '29c0c24424fb173085ac7a54ac1f21243b2baa9a2ed5aee79265922c40a488ab': {
    id: '',
    defaultMessage:
      '<strong>{fieldName}</strong> "<strong>{operator, select,\n          text_match {satisfies custom regex}\n          text_is {is}\n          text_not {is not}\n          checkbox_is {is checked}\n          checkbox_not {is not checked}\n          radiobutton_is {is}\n          radiobutton_not {is not}\n          radiobutton_any {is one of these}\n          radiobutton_none {is none of these}\n          dropdown_is {is}\n          dropdown_not {is not}\n          dropdown_any {is one of these}\n          dropdown_none {is none of these}\n          other {}\n        }</strong>" <strong>{value}</strong>',
    description:
      'This is the triggerText part of conditional-logic-description.trigger or conditional-logic-description.action.',
  },
});
