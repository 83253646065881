import { StoreShape, ThunkAction } from 'hellospa/redux/types';
import * as selectors from 'hellospa/page/prep-and-send/data/selectors';
import { CsvEditorSelectors } from 'hellospa/page/prep-and-send/data/csv-editor/csv-editor-selectors';
import { Workflow, getWorkflowConfiguration } from '../types/workflow';
import { Actions } from '../types';
import { UserFileTypes } from '../types/file';

const configure = async (
  state: StoreShape,
  workflow: Workflow,
): Promise<Workflow> => {
  const pages = await Promise.all(
    workflow.pages.map(async (page) => ({
      ...page,
      text: page.component.getText(workflow, state),
    })),
  );

  return {
    ...workflow,
    pages,
  };
};

export const dispatchActionArray =
  (
    actions: (() => ThunkAction<void>)[],
  ): ThunkAction<void | Promise<unknown>> =>
  async (dispatch) => {
    for (let i = 0; i < actions.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      await dispatch(actions[i]());
    }
  };

/**
 * Changes workflow. Strongly suggested to await this
 */
export const setWorkflow =
  (workflow: Workflow): ThunkAction<void> =>
  async (dispatch, getState) => {
    const payload = await configure(getState(), workflow);
    await dispatch({
      type: Actions.SetWorkflow,
      payload,
    });
    const { currentPath } = selectors.getMeta(getState());
    const pathIndex = payload.pages.findIndex(
      (page) => page.path === currentPath,
    );
    const newPath =
      pathIndex >= 0 ? payload.pages[pathIndex].path : payload.pages[0].path;

    /**
     * If this is a fresh init, currentPath will be empty.
     * Set it to the first Page in Workflow
     */
    if (currentPath !== newPath) {
      await dispatch({
        type: Actions.UpdateMeta,
        payload: {
          currentPath: newPath,
        },
      });
    }
  };

export const nextPageInWorkflow =
  (): ThunkAction<void> => async (dispatch, getState) => {
    const workflow = await configure(
      getState(),
      selectors.getWorkflow(getState()),
    );
    const currentPageIndex = selectors.getCurrentWorkflowPageIndex(getState());

    dispatch({
      type: Actions.SetWorkflow,
      payload: workflow,
    });

    // If there is no next step, do not change history
    if (workflow.pages[currentPageIndex + 1] != null) {
      dispatch({
        type: Actions.UpdateMeta,
        payload: {
          currentPath: workflow.pages[currentPageIndex + 1].path,
        },
      });
    }
  };

export const previousPageInWorkflow =
  (): ThunkAction<void> => async (dispatch, getState) => {
    const workflow = await configure(
      getState(),
      selectors.getWorkflow(getState()),
    );
    const currentPageIndex = selectors.getCurrentWorkflowPageIndex(getState());

    dispatch({
      type: Actions.SetWorkflow,
      payload: workflow,
    });

    // Already on first page of workflow
    if (currentPageIndex === 0) {
      return;
    }

    await dispatch({
      type: Actions.UpdateMeta,
      payload: {
        currentPath: workflow.pages[currentPageIndex - 1].path,
      },
    });
  };

export const changeWorkflowFromFiles =
  (): ThunkAction<void> => async (dispatch, getState, getExtra) => {
    if (!selectors.allowWorkflowChange(getState())) {
      return;
    }

    const { featureFlags } = getExtra();
    const useNewEditorPage = Boolean(
      featureFlags.sign_core_2024_06_12_new_editor_page,
    );

    const count = {
      [UserFileTypes.External]: 0,
      [UserFileTypes.Template]: 0,
      [UserFileTypes.Upload]: 0,
      [UserFileTypes.Signer]: 0,
    };
    selectors.getFiles(getState()).forEach((file) => {
      count[file.type]++;
    });

    // No Templates attached - either only direct uploads or from external
    if (count[UserFileTypes.Template] === 0) {
      await dispatch(
        setWorkflow(
          await getWorkflowConfiguration('SignatureRequestDocument', {
            useNewEditorPage,
          }),
        ),
      );
      return;
    }

    // No Upload or External files attached
    if (
      count[UserFileTypes.Upload] === 0 &&
      count[UserFileTypes.External] === 0
    ) {
      await dispatch(
        setWorkflow(
          await getWorkflowConfiguration('SignatureRequestTemplate', {
            useNewEditorPage,
          }),
        ),
      );
      return;
    }

    // Both Uploads/External and Templates attached
    await dispatch(
      setWorkflow(
        await getWorkflowConfiguration('SignatureRequestTemplateAndDocument', {
          useNewEditorPage,
        }),
      ),
    );
  };

export function changeWorkflowFromSignersUpload(): ThunkAction<void> {
  return async function signersUploadThunk(dispatch, getState, getExtra) {
    const { featureFlags } = getExtra();
    const useNewEditorPage = Boolean(
      featureFlags.sign_core_2024_06_12_new_editor_page,
    );

    if (CsvEditorSelectors.hasErrors(getState())) {
      await dispatch(
        setWorkflow(
          await getWorkflowConfiguration('BulkSendFixErrors', {
            useNewEditorPage,
          }),
        ),
      );
      return;
    }
    await dispatch(
      setWorkflow(
        await getWorkflowConfiguration('BulkSend', { useNewEditorPage }),
      ),
    );
  };
}

export const changeWorkflowDeepIntegration =
  (): ThunkAction<void> => async (dispatch, getState, getExtra) => {
    if (!selectors.allowWorkflowChange(getState())) {
      return;
    }

    const { featureFlags } = getExtra();
    const useNewEditorPage = Boolean(
      featureFlags.sign_core_2024_06_12_new_editor_page,
    );

    if (selectors.isMeOnly(getState())) {
      await dispatch(
        setWorkflow(
          await getWorkflowConfiguration('DeepIntegrationSelfSign', {
            useNewEditorPage,
          }),
        ),
      );
      return;
    }

    if (selectors.isEmbeddedTemplate(getState())) {
      await dispatch(
        setWorkflow(
          await getWorkflowConfiguration('DeepIntegrationTemplate', {
            useNewEditorPage,
          }),
        ),
      );
      return;
    }

    // Default to DeepIntegration
    await dispatch(
      setWorkflow(
        await getWorkflowConfiguration('DeepIntegration', { useNewEditorPage }),
      ),
    );
  };

export const changeWorkflowUseTemplate =
  (): ThunkAction<void> => async (dispatch, getState, getExtra) => {
    const { featureFlags } = getExtra();
    const useNewEditorPage = Boolean(
      featureFlags.sign_core_2024_06_12_new_editor_page,
    );

    if (selectors.getAllMergeFields(getState()).length) {
      await dispatch(
        setWorkflow(
          await getWorkflowConfiguration(
            'DeepIntegrationUseTemplatesMergeFields',
            { useNewEditorPage },
          ),
        ),
      );
      return;
    }

    await dispatch(
      setWorkflow(
        await getWorkflowConfiguration('DeepIntegrationUseTemplate', {
          useNewEditorPage,
        }),
      ),
    );
  };

export const changeWorkflowDeepIntegrationRecipientsProvidedByHostPage =
  (): ThunkAction<void> => async (dispatch, getState, getExtra) => {
    if (!selectors.allowWorkflowChange(getState())) {
      return;
    }

    const { featureFlags } = getExtra();
    const useNewEditorPage = Boolean(
      featureFlags.sign_core_2024_06_12_new_editor_page,
    );

    if (selectors.isMeOnly(getState())) {
      await dispatch(
        setWorkflow(
          await getWorkflowConfiguration(
            'DeepIntegrationSelfSignRecipientsProvidedByHostPage',
            { useNewEditorPage },
          ),
        ),
      );
      return;
    }

    // Default to DeepIntegration
    await dispatch(
      setWorkflow(
        await getWorkflowConfiguration(
          'DeepIntegrationRecipientsProvidedByHostPage',
          { useNewEditorPage },
        ),
      ),
    );
  };

export const changeWorkFlowIntegrationSelfSign =
  (): ThunkAction<void> => async (dispatch, getState, getExtra) => {
    const { featureFlags } = getExtra();
    const useNewEditorPage = Boolean(
      featureFlags.sign_core_2024_06_12_new_editor_page,
    );

    if (selectors.isMeOnly(getState())) {
      await dispatch(
        setWorkflow(
          await getWorkflowConfiguration('IntegrationSelfSign', {
            useNewEditorPage,
          }),
        ),
      );
      return;
    }

    // Default to Integration
    await dispatch(
      setWorkflow(
        await getWorkflowConfiguration('Integration', { useNewEditorPage }),
      ),
    );
  };
