import React, { FC, RefObject } from 'react';
import * as yup from 'yup';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { FormikProps, FormikContextType } from 'formik';

import { Actions, RequestTypes } from './index';
import { StoreShape, ThunkAction } from 'hellospa/redux/types';
import * as actions from 'hellospa/page/prep-and-send/data/actions';
import { FixErrorsIcon } from 'hellospa/components/prep-and-send/stepper/icons/fix-errors-icon';

import DocumentIcon from 'hellospa/components/prep-and-send/stepper/icons/document';
import SignersIcon from 'hellospa/components/prep-and-send/stepper/icons/signers';
import FieldsIcon from 'hellospa/components/prep-and-send/stepper/icons/fields';
import ReviewIcon from 'hellospa/components/prep-and-send/stepper/icons/review';
import FileIcon from 'hellospa/components/prep-and-send/stepper/icons/file';
import { UIIconProps } from '@dropbox/dig-icons';
import {
  CheckmarkCircleLine,
  FileLine,
  ShareLine,
  TextBoxLine,
  UnorderedListLine,
} from '@dropbox/dig-icons/assets';
import styles from './workflow.scss';
import { UnreachableError } from 'js/sign-components/common/ts-utils';
import { FeatureFlags } from 'js/sign-components/common/feature-flags';
import { useRawFeatureFlagContext } from 'js/sign-components/common/feature-flag';
import { useSelector } from 'react-redux';

export const workflowTitles = defineMessages({
  sendForSignature: {
    id: '',
    defaultMessage: 'Send for signature',
    description: 'Name of the current flow that the user is in',
  },
  signatureRequest: {
    id: '',
    defaultMessage: 'Signature request',
    description: 'Name of the current flow that the user is in',
  },
  OnlySigner: {
    id: '',
    defaultMessage: "I'm the only signer",
    description: 'Name of the current flow that the user is in',
  },
  sendAFax: {
    id: '',
    defaultMessage: 'Send a fax',
    description: 'Name of the current flow that the user is in',
  },
  createTemplate: {
    id: '',
    defaultMessage: 'Create template',
    description: 'Name of the current flow that the user is in',
  },
  createTemplateFromGallery: {
    id: '',
    defaultMessage: 'Create template from gallery',
    description: 'Name of the current flow that the user is in',
  },
  createTemplateLink: {
    id: '',
    defaultMessage: 'Create template link',
    description: 'Name of the current flow that the user is in',
  },
  editTemplate: {
    id: '',
    defaultMessage: 'Edit template',
    description: 'Name of the current flow that the user is in',
  },
  bulkSend: {
    id: '',
    defaultMessage: 'Bulk send',
    description: 'Name of the current flow that the user is in',
  },
});

// All Page's getText() functions must return an object of defineMessages()
export type PageText = {
  title?: MessageDescriptor;
  next?: MessageDescriptor;
  shortNext?: MessageDescriptor;
};

const emptyText: PageText = defineMessages({
  title: {
    id: '',
    description: 'emptyTextTitleDescription',
    defaultMessage: 'emptyTextTitleDefault',
  },
  next: {
    id: '',
    description: 'emptyTextNextDescription',
    defaultMessage: 'emptyTextNextDefault',
  },
});

// An array of redux-thunk action callbacks
type ThunkActions = () => ThunkAction<void>;

export type FormikRefType = RefObject<FormikProps<{}>>;
export type OnPreviousCallback = () => Promise<unknown>;

export type TriggerStage = 'load' | 'submit' | 'done';

type TriggerComponentProps = {
  onCancel: () => void;
  onSkip: () => void;
  stage: TriggerStage;
};

export type TriggerComponent<P = {}> = FC<P & TriggerComponentProps> & {
  needsTrigger: (state: StoreShape, formik: FormikContextType<any>) => boolean;
};

export type TriggerStageComponents = {
  [key in TriggerStage]: TriggerComponent[];
};

export type TriggerCallback<TSubmit> = {
  load: () => void;
  submit: TSubmit;
  done: () => void;
};

export type WorkflowComponentProps<Values> = {
  // Called on component's Formik.onSubmit()
  onSaveActions: ThunkActions[];

  initialValues: Values;
  validationSchema: yup.Schema<Values>;

  /**
   * Used by the Trigger hook to automatically call stage-based components
   */
  triggers: TriggerStageComponents;

  /**
   * Used by Stepper next/back buttons to trigger active Page Formik.submitForm()
   * However, <Formik innerRef> is incorrectly typed so we can't pass the ref
   * object directly. Use formikRefAdapter() below to quiet TS
   */
  formRef: FormikRefType;

  /**
   * Called when the "Back" button is clicked from the place fields step
   * to trigger an autoSave of data from the Editor.
   */
  onPreviousCallbackRef?: React.MutableRefObject<OnPreviousCallback | null>;
};

/**
 * This is actually RefObject<FormikProps{} | undefined> but
 * Formik's type definition is not correct
 * see https://github.com/jaredpalmer/formik/issues/2290
 */
export const formikRefAdapter = (ref: FormikRefType) => {
  // @ts-ignore
  return ref as (instance: any) => void;
};

/**
 * All Pages will be passed WorkflowComponentProps via props,
 * and must implement validates() and getText() functions
 */
export type WorkflowComponent<Values extends {} = {}> = FC<
  WorkflowComponentProps<Values>
> & {
  getText: (workflow: Workflow, state: StoreShape) => PageText;
  selectFormValues: (state: StoreShape, featureFlags: FeatureFlags) => Values;
  selectFormSchema: (
    state: StoreShape,
    featureFlags: FeatureFlags,
    // Formik's type definition also uses an any here.  I tried to make it use
    // `Values`, but if the validation is more restrictive then the types aren't
    // compatible.
    //
    // On the upload files page `Values` can be an empty array with files of any
    // status, but to continue none of the files can be errored.
  ) => yup.ObjectSchema<any> | null;
};

const emptyValidator = yup.object({});
function useWorkflowComponent(component: WorkflowComponent<any>) {
  const rawFeatureFlags = useRawFeatureFlagContext() ?? {};
  const validationSchema = useSelector(
    (state: StoreShape) =>
      component.selectFormSchema?.(state, rawFeatureFlags) ?? emptyValidator,
  );

  const initialValues = useSelector((state: StoreShape) =>
    component.selectFormValues?.(state, rawFeatureFlags),
  );
  return { validationSchema, initialValues };
}

export const wrapWorkflowComponent = <T extends {}>(
  Component: WorkflowComponent<T>,
) => {
  type WrappedProps = Omit<
    WorkflowComponentProps<T>,
    'initialValues' | 'validationSchema'
  >;

  return function WrappedWorkflowComponent(props: WrappedProps) {
    const { validationSchema, initialValues } = useWorkflowComponent(Component);
    return React.createElement(Component, {
      ...props,
      validationSchema,
      initialValues,
    });
  };
};

/**
 * All Page definitions implement the following. Some properties will be
 * set at run-time by actions/workflow whenever a Workflow is changed or set
 */
interface Page {
  component: WorkflowComponent<any>;

  // icon is memoized SVG element
  icon: FC;
  uiIcon: UIIconProps['src'];
  path: string;
  // Set by actions/workflow.configure()
  text: PageText;
  onSave: ThunkActions[];
  onBack?: ThunkActions[];
  triggers: TriggerStageComponents;
  // Local override to hide the stepper.
  hideProgressStepper?: boolean;
  // decide wether or not this page shows footer
  hideFooter: boolean;
  // hides controls in stepper
  hideStepperBtnContainer?: boolean;
  hideNextBtn?: boolean;
  fullWidthStepper?: boolean;
  hideMergeFields?: boolean;
  containerClassName?: string;
  disableBack?: boolean;
}
export type WorkflowPage = Page;

/**
 * Default action to take when user clicks "Next" button on Stepper
 * Can be changed at Page level for each Workflow
 */
const getDefaultOnSave = () => [
  // Save needs to happen before moving to the next page. When this was reversed
  // the Place Fields page was able to start trying to prepare the Editor before
  // the save from leaving the recipients page completed
  actions.save,
  actions.nextPageInWorkflow,
];

export const defaultTrigger: TriggerStageComponents = {
  load: [],
  submit: [],
  done: [],
};

/**
 * finalOnSave was moved into a function, because when you `import * as
 * actions`, that object isn't garunteed to have the properties on it while it's
 * still in the process of loading modules and executing all of the top-level
 * code. This code was fine for a long time, but now modules are resolving in a
 * different order and we're seeing exactly the behavior that ECMAScript modules
 * are supposed to display.
 */
// const finalOnSave = [ actions.sendSRAndRedirect, ]; // [ undefined ]

const getPageDefaults = () => ({
  validates: false,
  text: emptyText,
  onSave: getDefaultOnSave(),
  triggers: defaultTrigger,
  hideFooter: false,
});
export { getPageDefaults as workflowPageDefaults };

export const pages = {
  FileUploaderPage: async (props: Partial<Page>): Promise<Page> => ({
    ...getPageDefaults(),
    component: (await import('hellospa/components/prep-and-send/file-uploader'))
      .default,
    icon: DocumentIcon,
    path: 'documents',
    uiIcon: FileLine,
    triggers: {
      ...defaultTrigger,
      load: [
        (
          await import(
            'hellospa/components/prep-and-send/quota-alerts/template-quota-alert'
          )
        ).default,
        (
          await import(
            'hellospa/components/prep-and-send/quota-alerts/document-quota-alert'
          )
        ).default,
      ],
    },
    ...props,
  }),
  RecipientPage: async (props: Partial<Page>): Promise<Page> => ({
    ...getPageDefaults(),
    component: (await import('hellospa/components/prep-and-send/recipient'))
      .default,
    icon: SignersIcon,
    uiIcon: ShareLine,
    path: 'recipients',
    ...props,
  }),
  FixCsvErrorsPage: async (props: Partial<Page>): Promise<Page> => ({
    ...getPageDefaults(),
    component: (
      await import('hellospa/components/prep-and-send/review/csv-editor-page')
    ).CsvEditorPage,
    icon: FixErrorsIcon,
    uiIcon: UnorderedListLine,
    path: 'fix-errors',
    containerClassName: styles.fullPage,
    ...props,
  }),
  PlaceFieldsPage: async (props: Partial<Page>): Promise<Page> => {
    const { default: PlaceFieldsPage, containerClassName } = await import(
      'hellospa/components/prep-and-send/place-fields'
    );
    return {
      ...getPageDefaults(),
      component: PlaceFieldsPage,
      icon: FieldsIcon,
      uiIcon: TextBoxLine,
      path: 'place-fields',
      hideFooter: true,
      fullWidthStepper: true,
      containerClassName,
      ...props,
    };
  },
  EditorPage: async (props: Partial<Page>): Promise<Page> => {
    const { default: PlaceFieldsPage, containerClassName } = await import(
      'hellospa/components/prep-and-send/editor-page'
    );
    return {
      ...getPageDefaults(),
      component: PlaceFieldsPage,
      icon: FieldsIcon,
      uiIcon: TextBoxLine,
      path: 'place-fields',
      hideFooter: true,
      fullWidthStepper: true,
      containerClassName,
      ...props,
    };
  },
  MergeFieldsPage: async (props: Partial<Page>): Promise<Page> => ({
    ...getPageDefaults(),
    component: (
      await import('hellospa/components/deep-integration/merge-fields')
    ).default,
    icon: FieldsIcon,
    uiIcon: TextBoxLine,
    path: 'merge-fields',
    hideFooter: true,
    ...props,
  }),
  ReviewPage: async (props: Partial<Page>): Promise<Page> => {
    const { default: ReviewPage, reviewTriggerComponents } = await import(
      'hellospa/components/prep-and-send/review'
    );
    return {
      ...getPageDefaults(),
      component: ReviewPage,
      icon: ReviewIcon,
      uiIcon: CheckmarkCircleLine,
      path: 'review',
      triggers: {
        ...defaultTrigger,
        submit: [
          (
            await import(
              'hellospa/components/prep-and-send/quota-alerts/document-quota-alert'
            )
          ).default,
          reviewTriggerComponents.UpgradeSendEmailModal,
          reviewTriggerComponents.UpgradeSendMultiFaxModal,
          reviewTriggerComponents.SignerInfoModal,
        ],
      },
      ...props,
    };
  },
  SelfSignReviewPage: async (props: Partial<Page>): Promise<Page> => {
    const { default: ReviewPage, reviewTriggerComponents } = await import(
      'hellospa/components/prep-and-send/review/self-sign.dig'
    );
    return {
      ...getPageDefaults(),
      component: ReviewPage,
      icon: ReviewIcon,
      uiIcon: CheckmarkCircleLine,
      path: 'review',
      triggers: {
        ...defaultTrigger,
        submit: [
          (
            await import(
              'hellospa/components/prep-and-send/quota-alerts/document-quota-alert'
            )
          ).default,
          reviewTriggerComponents.UpgradeSendEmailModal,
          reviewTriggerComponents.UpgradeSendMultiFaxModal,
          reviewTriggerComponents.SignerInfoModal,
        ],
      },
      ...props,
    };
  },

  EmbeddedPreLoadingPage: async (props: Partial<Page>): Promise<Page> => ({
    ...getPageDefaults(),
    component: (
      await import('hellospa/components/prep-and-send/embedded/pre-loading')
    ).default,
    icon: FileIcon,
    uiIcon: FileLine,
    path: 'embedded-pre-loading',
    hideStepperBtnContainer: true,
    disableBack: true,
    ...props,
  }),
  DeepIntegrationRecipientLoadingPage: async (
    props: Partial<Page>,
  ): Promise<Page> => ({
    ...getPageDefaults(),
    component: (
      await import(
        'hellospa/components/prep-and-send/embedded/recipient-loading'
      )
    ).default,
    icon: FileIcon,
    uiIcon: FileLine,
    path: 'load-recipients',
    hideStepperBtnContainer: true,
    ...props,
  }),
  DeepIntegrationDropinPage: async (props: Partial<Page>): Promise<Page> => ({
    ...getPageDefaults(),
    component: (await import('hellospa/components/deep-integration/dropin'))
      .default,
    icon: FileIcon,
    uiIcon: FileLine,
    path: 'dropin',
    ...props,
  }),
  DeepIntegrationFinalLoading: async (props: Partial<Page>): Promise<Page> => ({
    ...getPageDefaults(),
    component: (
      await import('hellospa/components/deep-integration/final-loading')
    ).default,
    icon: SignersIcon,
    uiIcon: ShareLine,
    path: 'deep-integration-save-back-loading',
    hideStepperBtnContainer: true,
    ...props,
  }),
  DeepIntegrationFinalLoadingForTemplate: async (
    props: Partial<Page>,
  ): Promise<Page> => ({
    ...getPageDefaults(),
    component: (
      await import(
        'hellospa/components/deep-integration/final-loading/final-loading-for-template'
      )
    ).default,
    icon: SignersIcon,
    uiIcon: ShareLine,
    path: 'deep-integration-saving-template-loading',
    hideStepperBtnContainer: true,
    ...props,
  }),
  DeepIntegrationSelfSignFinalLoading: async (
    props: Partial<Page>,
  ): Promise<Page> => ({
    ...getPageDefaults(),
    component: (
      await import('hellospa/components/deep-integration/final-loading')
    ).default,
    icon: SignersIcon,
    uiIcon: ShareLine,
    path: 'deep-integration-save-back-loading',
    hideStepperBtnContainer: true,
    ...props,
  }),
  GmailIntegrationFinalLoading: async (
    props: Partial<Page>,
  ): Promise<Page> => ({
    ...getPageDefaults(),
    component: (
      await import('hellospa/components/integration/gmail-final-loading')
    ).default,
    icon: SignersIcon,
    uiIcon: ShareLine,
    path: 'integration-gmail-loading',
    hideStepperBtnContainer: true,
    ...props,
  }),
};

export enum WorkflowTags {
  DeepIntegration,
  DeepIntegrationSelfSign,
  DeepIntegrationRecipientsProvidedByHostPage,
  SignatureRequestEmail,
  SignatureRequest,
  SignatureRequestUnknown,
  SignatureRequestDocument,
  SignatureRequestTemplate,
  SignatureRequestFax,
  CreateTemplate,
  CreateTemplateLink,
  /**
   * @deprecated HideStepperToolbar is ignored in the new design.
   * use hideStepperBtnContainer on any pages that shouldn't show
   * the next and back buttons, like loading screens.
   */
  HideStepperToolbar,
  // I don't know what to call this. It attempts to restore the pre-rebrand
  // behavior that we had when we hide the stepper and let Editor handle its own
  // next button. Workflows with this tag use the text "Continue" or "Send".
  DEV24370,
  BulkSend,
  ShowInlinePreview,
  IntegrationOneTimeDoc,
  IntegrationCreateTemplate,
  GmailSelfSign,
}

export interface Workflow {
  id: WorkflowName;
  // useful for tying frontend workflow type to backend request type when
  // saving to the backend
  requestType: RequestTypes;
  tags: WorkflowTags[];
  name: string;
  title: MessageDescriptor | null;
  pages: Page[];
}
export const FAX_NO_EDITOR = 'fax-no-editor';
export const SELF_SIGN = 'self-sign';

export type WorkflowArgs = {
  useNewEditorPage: boolean;
};

export type WorkflowName =
  | 'SignatureRequestUnknown'
  | 'SignatureRequestDocument'
  | 'SelfSign'
  | 'SignatureRequestTemplate'
  | 'SignatureRequestTemplateAndDocument'
  | 'SignatureRequestFax'
  | 'SignatureRequestFaxNoEditor'
  | 'CreateTemplate'
  | 'CreateTemplateFromGallery'
  | 'CreateTemplateLink'
  | 'BulkSend'
  | 'BulkSendFixErrors'
  | 'DeepIntegration'
  | 'DeepIntegrationSelfSign'
  | 'DeepIntegrationRecipientsProvidedByHostPage'
  | 'DeepIntegrationSelfSignRecipientsProvidedByHostPage'
  | 'DeepIntegrationTemplate'
  | 'DeepIntegrationEditTemplate'
  | 'DeepIntegrationUseTemplate'
  | 'DeepIntegrationUseTemplatesMergeFields'
  | 'EmbeddedTemplate'
  | 'EmbeddedTemplateRecipient'
  | 'EmbeddedTemplateReview'
  | 'EmbeddedTemplateRecipientReview'
  | 'EmbeddedRequest'
  | 'EmbeddedRequestRecipient'
  | 'EmbeddedRequestReview'
  | 'EmbeddedRequestRecipientReview'
  | 'EmbeddedRequestWithTemplate'
  | 'EmbeddedRequestRecipientWithTemplate'
  | 'EmbeddedRequestReviewWithTemplate'
  | 'EmbeddedRequestRecipientReviewWithTemplate'
  | 'OfficeSelfSign'
  | 'Integration'
  | 'IntegrationOneTimeDoc'
  | 'IntegrationSelfSign'
  | 'IntegrationCreateTemplate'
  | 'IntegrationSendTemplate'
  | 'GmailSelfSign';

export async function getWorkflowConfiguration(
  id: WorkflowName,
  { useNewEditorPage }: WorkflowArgs,
): Promise<Workflow> {
  const EditorPage = useNewEditorPage
    ? pages.EditorPage
    : pages.PlaceFieldsPage;
  const finalOnSave = [actions.sendSRAndRedirect];

  switch (id) {
    case 'SignatureRequestUnknown':
      return {
        id,
        requestType: RequestTypes.RequestSig,
        tags: [
          WorkflowTags.SignatureRequest,
          WorkflowTags.SignatureRequestUnknown,
        ],
        name: 'unknown',
        title: workflowTitles.sendForSignature,
        pages: [
          await pages.FileUploaderPage({
            onSave: [actions.changeWorkflowFromFiles, ...getDefaultOnSave()],
          }),
          await pages.RecipientPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'SignatureRequestDocument':
      return {
        id,
        requestType: RequestTypes.RequestSig,
        tags: [
          WorkflowTags.SignatureRequest,
          WorkflowTags.SignatureRequestDocument,
        ],
        name: 'one-time doc',
        title: workflowTitles.sendForSignature,
        pages: [
          await pages.FileUploaderPage({
            onSave: [actions.changeWorkflowFromFiles, ...getDefaultOnSave()],
          }),
          await pages.RecipientPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'SignatureRequestTemplate':
      return {
        id,
        requestType: RequestTypes.SendTemplate,
        tags: [
          WorkflowTags.SignatureRequest,
          WorkflowTags.SignatureRequestTemplate,
        ],
        name: 'template',
        title: workflowTitles.sendForSignature,
        pages: [
          await pages.FileUploaderPage({
            onSave: [actions.changeWorkflowFromFiles, ...getDefaultOnSave()],
          }),
          await pages.RecipientPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'SignatureRequestTemplateAndDocument':
      return {
        id,
        requestType: RequestTypes.SendTemplate,
        tags: [
          WorkflowTags.SignatureRequest,
          WorkflowTags.SignatureRequestDocument,
          WorkflowTags.SignatureRequestTemplate,
        ],
        name: 'template with one-time doc',
        title: workflowTitles.sendForSignature,
        pages: [
          await pages.FileUploaderPage({
            onSave: [actions.changeWorkflowFromFiles, ...getDefaultOnSave()],
          }),
          await pages.RecipientPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'SignatureRequestFax':
      return {
        id,
        requestType: RequestTypes.SendDoc,
        tags: [WorkflowTags.SignatureRequest, WorkflowTags.SignatureRequestFax],
        name: 'fax',
        title: workflowTitles.sendAFax,
        pages: [
          await pages.FileUploaderPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'SignatureRequestFaxNoEditor':
      return {
        id,
        requestType: RequestTypes.SendDoc,
        tags: [WorkflowTags.SignatureRequest, WorkflowTags.SignatureRequestFax],
        name: FAX_NO_EDITOR,
        title: workflowTitles.sendAFax,
        pages: [
          await pages.FileUploaderPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'CreateTemplate':
      return {
        id,
        requestType: RequestTypes.Template,
        tags: [WorkflowTags.CreateTemplate],
        name: 'create template',
        title: workflowTitles.createTemplate,
        pages: [
          await pages.FileUploaderPage({}),
          await pages.RecipientPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'CreateTemplateFromGallery':
      return {
        id,
        requestType: RequestTypes.Template,
        tags: [WorkflowTags.CreateTemplate],
        name: 'create template from gallery',
        title: workflowTitles.createTemplateFromGallery,
        pages: [
          await pages.FileUploaderPage({}),
          await pages.RecipientPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'CreateTemplateLink':
      return {
        id,
        requestType: RequestTypes.ReusableLink,
        tags: [WorkflowTags.CreateTemplateLink],
        name: 'create template link',
        title: workflowTitles.createTemplateLink,
        pages: [
          await pages.FileUploaderPage({}),
          await pages.RecipientPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'BulkSend':
      return {
        id,
        requestType: RequestTypes.BulkSend,
        tags: [WorkflowTags.BulkSend],
        name: 'bulk send',
        title: workflowTitles.bulkSend,
        pages: [
          await pages.FileUploaderPage({
            onSave: [
              actions.initBulkSend,
              actions.getBulkSendExample,
              actions.nextPageInWorkflow,
              actions.save,
            ],
          }),
          await pages.RecipientPage({
            onSave: [actions.nextPageInWorkflow],
          }),
          await pages.ReviewPage({
            onSave: [actions.sendBulkRequestAndRedirect],
          }),
        ],
      };
    case 'BulkSendFixErrors':
      return {
        id,
        requestType: RequestTypes.BulkSend,
        tags: [WorkflowTags.BulkSend],
        name: 'bulk send fix errors',
        title: workflowTitles.bulkSend,
        pages: [
          await pages.FileUploaderPage({
            onSave: [
              actions.initBulkSend,
              actions.getBulkSendExample,
              actions.nextPageInWorkflow,
              actions.save,
            ],
          }),
          await pages.RecipientPage({
            onSave: [actions.nextPageInWorkflow],
          }),
          await pages.FixCsvErrorsPage({}),
          await pages.ReviewPage({
            onSave: [actions.sendBulkRequestAndRedirect],
          }),
        ],
      };
    case 'DeepIntegration':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [
          WorkflowTags.DeepIntegration,
          WorkflowTags.SignatureRequestEmail,
          WorkflowTags.SignatureRequest,
          WorkflowTags.SignatureRequestDocument,
        ],
        name: 'deep integration',
        title: workflowTitles.sendForSignature,
        pages: [
          await pages.RecipientPage({
            onSave: [
              actions.changeWorkflowDeepIntegration,
              ...getDefaultOnSave(),
            ],
          }),
          await EditorPage({
            onSave: [
              actions.changeWorkflowDeepIntegration,
              ...getDefaultOnSave(),
            ],
          }),
          await pages.DeepIntegrationDropinPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
          await pages.DeepIntegrationFinalLoading({}),
        ],
      };
    case 'DeepIntegrationSelfSign':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [
          WorkflowTags.DeepIntegration,
          WorkflowTags.DeepIntegrationSelfSign,
          WorkflowTags.SignatureRequestEmail,
          WorkflowTags.SignatureRequest,
          WorkflowTags.SignatureRequestDocument,
        ],
        name: 'deep integration self-sign',
        title: null,
        pages: [
          await pages.RecipientPage({
            onSave: [
              actions.changeWorkflowDeepIntegration,
              ...getDefaultOnSave(),
            ],
          }),
          await EditorPage({
            onSave: [
              actions.changeWorkflowDeepIntegration,
              ...getDefaultOnSave(),
            ],
          }),
          await pages.DeepIntegrationDropinPage({
            onSave: [actions.send],
          }),
          await pages.DeepIntegrationSelfSignFinalLoading({}),
        ],
      };
    case 'DeepIntegrationRecipientsProvidedByHostPage':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [
          WorkflowTags.DeepIntegration,
          WorkflowTags.DeepIntegrationRecipientsProvidedByHostPage,
          WorkflowTags.SignatureRequestEmail,
          WorkflowTags.SignatureRequest,
          WorkflowTags.SignatureRequestDocument,
        ],
        name: 'deep integration recipients provided by host page',
        title: workflowTitles.sendForSignature,
        pages: [
          await pages.DeepIntegrationRecipientLoadingPage({
            disableBack: true,
          }),
          await EditorPage({
            onSave: [
              actions.changeWorkflowDeepIntegrationRecipientsProvidedByHostPage,
              ...getDefaultOnSave(),
            ],
          }),
          await pages.DeepIntegrationDropinPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
          await pages.DeepIntegrationFinalLoading({}),
        ],
      };
    case 'DeepIntegrationSelfSignRecipientsProvidedByHostPage':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [
          WorkflowTags.DeepIntegration,
          WorkflowTags.DeepIntegrationRecipientsProvidedByHostPage,
          WorkflowTags.DeepIntegrationSelfSign,
          WorkflowTags.SignatureRequestEmail,
          WorkflowTags.SignatureRequest,
          WorkflowTags.SignatureRequestDocument,
        ],
        name: 'deep integration self sign recipients provided by host page',
        title: null,
        pages: [
          await pages.DeepIntegrationRecipientLoadingPage({
            disableBack: true,
          }),
          await EditorPage({
            onSave: [
              actions.changeWorkflowDeepIntegrationRecipientsProvidedByHostPage,
              ...getDefaultOnSave(),
            ],
          }),
          await pages.DeepIntegrationDropinPage({
            onSave: [actions.send],
          }),
          await pages.DeepIntegrationSelfSignFinalLoading({}),
        ],
      };
    case 'DeepIntegrationTemplate':
      return {
        id,
        requestType: RequestTypes.EmbeddedTemplate,
        tags: [WorkflowTags.DeepIntegration, WorkflowTags.CreateTemplate],
        name: 'deep integration template',
        title: workflowTitles.createTemplate,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await pages.RecipientPage({
            onSave: [
              actions.changeWorkflowDeepIntegration,
              ...getDefaultOnSave(),
            ],
          }),
          await EditorPage({
            onSave: [
              actions.changeWorkflowDeepIntegration,
              ...getDefaultOnSave(),
            ],
          }),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
          await pages.DeepIntegrationFinalLoadingForTemplate({}),
        ],
      };
    case 'DeepIntegrationEditTemplate':
      return {
        id,
        requestType: RequestTypes.EmbeddedTemplate,
        tags: [WorkflowTags.DeepIntegration, WorkflowTags.CreateTemplate],
        name: 'deep integration edit template',
        title: workflowTitles.editTemplate,
        pages: [
          await pages.EmbeddedPreLoadingPage({
            disableBack: true,
          }),
          await pages.RecipientPage({
            onSave: [
              actions.changeWorkflowDeepIntegration,
              ...getDefaultOnSave(),
            ],
          }),
          await EditorPage({
            onSave: [
              actions.changeWorkflowDeepIntegration,
              ...getDefaultOnSave(),
            ],
          }),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
          await pages.DeepIntegrationFinalLoadingForTemplate({}),
        ],
      };
    case 'DeepIntegrationUseTemplate':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [
          WorkflowTags.DeepIntegration,
          WorkflowTags.SignatureRequestTemplate,
          WorkflowTags.ShowInlinePreview,
        ],
        name: 'deep integration use template',
        title: workflowTitles.sendForSignature,
        pages: [
          await pages.EmbeddedPreLoadingPage({
            disableBack: true,
          }),
          await pages.RecipientPage({
            onSave: [actions.changeWorkflowUseTemplate, ...getDefaultOnSave()],
          }),
          await pages.DeepIntegrationDropinPage({}),
          await pages.ReviewPage({
            hideMergeFields: true,
            onSave: finalOnSave,
          }),
        ],
      };
    case 'DeepIntegrationUseTemplatesMergeFields':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [
          WorkflowTags.DeepIntegration,
          WorkflowTags.SignatureRequestTemplate,
          WorkflowTags.ShowInlinePreview,
        ],
        name: 'deep integration use template no merge fields',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({
            disableBack: true,
          }),
          await pages.RecipientPage({}),
          await pages.MergeFieldsPage({}),
          await pages.DeepIntegrationDropinPage({}),
          await pages.ReviewPage({
            hideMergeFields: true,
            onSave: finalOnSave,
          }),
        ],
      };
    case 'EmbeddedTemplate':
      return {
        id,
        requestType: RequestTypes.EmbeddedTemplate,
        tags: [
          WorkflowTags.CreateTemplate,
          WorkflowTags.HideStepperToolbar,
          WorkflowTags.DEV24370,
        ],
        name: 'embedded template',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await EditorPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'EmbeddedTemplateRecipient':
      return {
        id,
        requestType: RequestTypes.EmbeddedTemplate,
        tags: [WorkflowTags.CreateTemplate],
        name: 'embedded template - show signers',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await pages.RecipientPage({}),
          await EditorPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'EmbeddedTemplateReview':
      return {
        id,
        requestType: RequestTypes.EmbeddedTemplate,
        tags: [WorkflowTags.CreateTemplate],
        name: 'embedded template - show review',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'EmbeddedTemplateRecipientReview':
      return {
        id,
        requestType: RequestTypes.EmbeddedTemplate,
        tags: [WorkflowTags.CreateTemplate],
        name: 'embedded template - show signers and review',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await pages.RecipientPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'EmbeddedRequest':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [
          WorkflowTags.SignatureRequestDocument,
          WorkflowTags.HideStepperToolbar,
          WorkflowTags.DEV24370,
        ],
        name: 'embedded request',
        title: workflowTitles.sendForSignature,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await EditorPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'EmbeddedRequestRecipient':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [WorkflowTags.SignatureRequestDocument],
        name: 'embedded request - show signers',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await pages.RecipientPage({}),
          await EditorPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'EmbeddedRequestReview':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [WorkflowTags.SignatureRequestDocument],
        name: 'embedded request - show review',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'EmbeddedRequestRecipientReview':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [WorkflowTags.SignatureRequestDocument],
        name: 'embedded request - show signers and review',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await pages.RecipientPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'EmbeddedRequestWithTemplate':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [
          WorkflowTags.SignatureRequestTemplate,
          WorkflowTags.HideStepperToolbar,
          WorkflowTags.DEV24370,
        ],
        name: 'embedded request with template',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await EditorPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'EmbeddedRequestRecipientWithTemplate':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [WorkflowTags.SignatureRequestTemplate],
        name: 'embedded request with template - show signers',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await pages.RecipientPage({}),
          await EditorPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'EmbeddedRequestReviewWithTemplate':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [WorkflowTags.SignatureRequestTemplate],
        name: 'embedded request with template - show review',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'EmbeddedRequestRecipientReviewWithTemplate':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [WorkflowTags.SignatureRequestTemplate],
        name: 'embedded request with template - show signers and review',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await pages.RecipientPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'OfficeSelfSign':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [WorkflowTags.SignatureRequestDocument],
        name: 'office add in - self sign',
        title: null,
        pages: [
          await EditorPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'Integration':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [WorkflowTags.SignatureRequestDocument],
        name: 'Integration - Signature Request',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await pages.RecipientPage({
            onSave: [
              actions.changeWorkFlowIntegrationSelfSign,
              ...getDefaultOnSave(),
            ],
          }),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'IntegrationOneTimeDoc':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [
          WorkflowTags.IntegrationOneTimeDoc,
          WorkflowTags.SignatureRequestDocument,
        ],
        name: 'Integration - One Time Document',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await pages.FileUploaderPage({}),
          await pages.RecipientPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'IntegrationSelfSign':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [WorkflowTags.SignatureRequestDocument],
        name: 'Integration - Self Sign',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await pages.RecipientPage({}),
          await EditorPage({
            onSave: [actions.selfSaveAfterEditor],
          }),
        ],
      };
    case 'IntegrationCreateTemplate':
      return {
        id,
        requestType: RequestTypes.EmbeddedTemplate,
        tags: [
          WorkflowTags.IntegrationCreateTemplate,
          WorkflowTags.CreateTemplate,
        ],
        name: 'Integration embedded template - show signers and review',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await pages.FileUploaderPage({}),
          await pages.RecipientPage({}),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'IntegrationSendTemplate':
      return {
        id,
        requestType: RequestTypes.EmbeddedRequest,
        tags: [WorkflowTags.SignatureRequestTemplate],
        name: 'Integration embedded send template - show signers and review',
        title: null,
        pages: [
          await pages.EmbeddedPreLoadingPage({}),
          await pages.RecipientPage({
            onBack: [actions.integrationReloadSendTemplate],
          }),
          await EditorPage({}),
          await pages.ReviewPage({
            onSave: finalOnSave,
          }),
        ],
      };
    case 'SelfSign':
      return {
        id,
        requestType: RequestTypes.RequestSig,
        tags: [
          WorkflowTags.SignatureRequest,
          WorkflowTags.SignatureRequestDocument,
        ],
        name: SELF_SIGN,
        title: workflowTitles.OnlySigner,
        pages: [
          await pages.FileUploaderPage({
            onSave: [actions.changeWorkflowFromFiles, ...getDefaultOnSave()],
          }),
          await pages.RecipientPage({}),
          await EditorPage({}),

          await pages.SelfSignReviewPage({
            hideStepperBtnContainer: false,
            hideNextBtn: true,
            onSave: finalOnSave,
          }),
        ],
      };
    case 'GmailSelfSign':
      return {
        id,
        requestType: RequestTypes.SelfSave,
        tags: [
          WorkflowTags.SignatureRequestDocument,
          WorkflowTags.GmailSelfSign,
        ],
        name: 'gmail add on - self sign',
        title: null,
        pages: [
          await pages.PlaceFieldsPage({
            onSave: [actions.gmailSaveAfterEditor, actions.nextPageInWorkflow],
          }),
          await pages.GmailIntegrationFinalLoading({}),
        ],
      };
    default: {
      throw new UnreachableError(id);
    }
  }
}

export interface SetWorkflowAction {
  type: Actions.SetWorkflow;
  payload: Workflow;
}

export type WorkflowActions = SetWorkflowAction;
