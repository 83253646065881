/**
 * Theme
 *
 * This object defines the themeable properties throughout the component
 * library.  The theme is provided by the `StyleProvider` from the top level to
 * all child components.
 */
const defaultTheme = {
  breakpoints: [40, 52, 64],
  space: ['0rem', '0.25rem', '0.5rem', '1rem', '2rem', '4rem', '8rem'],
  fontSizes: ['0.85rem', '1rem', '1.25rem', '1.5rem', '2.25rem', '3rem'],
  colors: {
    primary: 'black',
    secondary: 'black',
  },
};
export default defaultTheme;
