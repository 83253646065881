import React from 'react';
import { ThunkAction, StoreShape } from 'hellospa/redux/types';
import { PAP_Start_SignatureDoc } from 'pap-events/sign/start_signature_doc';
import { PAP_Send_SignatureDoc } from 'pap-events/sign/send_signature_doc';
import { PAP_Save_Template } from 'pap-events/sign/save_template';
import { handleFileResponse } from './file';
import * as recipientActions from './recipient';
import * as workflowActions from './workflow';
import * as deepIntegrationActions from './deep-integration';
import { notEmpty, unreachable } from 'js/sign-components/common/ts-utils';
import { includes } from 'hellospa/js-utils';
import {
  dataSchema,
  SendRequestResponse,
  SelfSaveRequestResponse,
  SendRequestUiResponse,
  DataSchema,
  SaveDataType,
  SendRequestEmbeddedRequestResponse,
  SendRequestEmbeddedTemplateResponse,
} from 'hello-react/web-app-client/namespace/prep-and-send';
import * as selectors from 'hellospa/page/prep-and-send/data/selectors';
import * as types from 'hellospa/page/prep-and-send/data/types';
import { getTemplateRedirectUrl } from './meta';
import { createBannerMessage } from '../../../../components/notification-banner/data/actions';
import { defineMessages } from 'react-intl';
import { submitBulkSendRequest } from './bulk-send';
import * as hsEmbedded from 'js/sign-components/common/messages';
import { MessageType, postTo } from 'js/sign-components/common/ts-message';
import { redirectTo } from 'hellospa/common/utils/redirect';
import { queryParams } from 'js/sign-components/common/fetch';
import {
  logPAPEvent,
  retrieveFormattedIntegrationSource,
} from 'js/sign-components/common/product-analytics-pipeline';
import { finalizeEmbeddedFlow } from 'hellospa/common/utils/embedded';
import { NotificationBannerType } from 'hellospa/components/notification-banner/data/types';
import { trackHeapCustomEvent } from 'js/sign-components/common/heap';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { CCTypes, CC } from '../types/cc';
import { FilePollResponseStatus, UserFileTypes } from '../types/file';
import { Flags, IntegrationNames } from '../types/flags';
import { RecipientTypes } from '../types/recipient';
import {
  WorkflowTags,
  getWorkflowConfiguration,
  Workflow,
  WorkflowArgs,
} from '../types/workflow';
import { SHARING_INVITE_RATE_LIMIT } from 'common/constants/user-presentable-verdict';
import { validateYupSchema } from 'formik';
import { nextPageInWorkflow } from './workflow';

export * from './settings';
export * from './meta';
export * from './user';
export * from './document';
export * from './file';
export * from './recipient';
export * from './cc';
export * from './contacts';
export * from '../../../../components/notification-banner/data/actions';
export * from './workflow';
export * from './integration';
export * from './coverPage';
export * from './bulk-send';
export * from './deep-integration';
export * from './error-handling';
export * from './embedded';

/**
 * This action should only be used during tests or storybook
 */
export const setFlags = (payload: Partial<Flags>): types.PrepAndSendAction => ({
  type: types.Actions.SetFlags,
  payload,
});

// Currently only called for web UI, non deep integration flows, non charge flows
export const updateRequestIsFinished = (
  requestIsFinished: boolean,
): types.PrepAndSendAction => ({
  type: types.Actions.UpdateRequestIsFinished,
  payload: requestIsFinished,
});

const buildSaveData = (state: StoreShape): SaveDataType => {
  const settings = selectors.getSettings(state);

  return {
    guid: selectors.getTransmissionGroupGuid(state),
    files: selectors.getFiles(state),
    editorFields: selectors.getEditorFields(state),
    recipients: selectors.getRecipients(state),
    ccs: selectors.getCCs(state),
    coverPage: selectors.getCoverPage(state),
    document: selectors.getDocument(state),
    requestType: selectors.getRequestType(state),
    templates: selectors.getTemplates(state),
    settings: {
      recipientOrder: settings.recipientOrder,
      recipientReassignment: settings.recipientReassignment,
      sendHighResFax: settings.sendHighResFax,
      addCoverPage: settings.addCoverPage,
      qes: settings.qes,
      nom151: settings.nom151,
      hasForm: settings.hasForm,
    },
    bulkSend: selectors.getBulkSendData(state),
    shouldConvertDocToTemplate:
      selectors.getFlags(state).shouldConvertDocToTemplate,
    isMeOnly: selectors.isMeOnly(state),
  };
};

const finalizeEmbedded =
  (
    response:
      | SendRequestEmbeddedRequestResponse
      | SendRequestEmbeddedTemplateResponse,
  ): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();

    const embeddedData = selectors.getEmbeddedData(state);
    if (!embeddedData) {
      throw new Error('finalizeEmbedded cannot be run without embeddedData');
    }

    let eventData;
    if ('signature_request_id' in response) {
      eventData = {
        success: response.success,
        signatureRequestId: response.signature_request_id,
        signatureRequestInfo: {
          title: response.signature_request_info.title,
          expiresAt: response.signature_request_info.expiresAt,
          message: response.signature_request_info.message,
          signatures: response.signature_request_info.signatures,
          ccEmailAddresses: response.signature_request_info.cc_email_addresses,
        },
      };
    } else if ('template_id' in response) {
      eventData = {
        success: response.success,
        templateId: response.template_id,
        templateStatusId: response.template_status_id,
        templateInfo: {
          title: response.template_info.title,
          message: response.template_info.message,
          signerRoles: response.template_info.signer_roles,
          ccRoles: response.template_info.cc_roles,
        },
      };
    }

    if (!eventData) {
      throw new Error('Response is empty!');
    }

    const { parentUrl = '', redirectUrl } = embeddedData;
    return finalizeEmbeddedFlow({
      parentUrl,
      redirectUrl,
      eventData,
    });
  };

let saveLock: undefined | Promise<any>;
let lastSaveData: undefined | SaveDataType;
export const save =
  (): ThunkAction<Promise<void>> => async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();
    const files = selectors.getFiles(getState());
    const readyFiles = files.filter(
      (file) => file.status === FilePollResponseStatus.Ok,
    );
    const state = getState();
    const requestIsFinished = selectors.getRequestIsFinished(state);

    // DEV-11462 - The BE doesn't save data (roles/recipients) if files haven't
    // finished converting, so this avoids calling the BE.
    if (files.length === readyFiles.length && !requestIsFinished) {
      const data = buildSaveData(getState());
      // Skip saving if the previous save had the exact same data
      if (JSON.stringify(lastSaveData) === JSON.stringify(data)) {
        return;
      }
      if (saveLock) {
        // wait for the last promise to finish
        await saveLock;
      }
      lastSaveData = data;
      const promise = appActions.prepAndSend.savePrepAndSendData(data);
      saveLock = promise;
      try {
        await promise;
      } catch (ex) {
        // Not handling if not doing fallback for Unified JSON
        throw ex;
      }

      saveLock = undefined;
    }
  };

export const send =
  (
    isFromChargeModal: boolean = false,
  ): ThunkAction<Promise<SendRequestResponse | null>> =>
  async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();
    const state = getState();
    const isDeepIntegration = selectors.isDeepIntegration(state);
    const isStrictlyEmbedded = selectors.isStrictlyEmbedded(state);
    const requestIsFinished = selectors.getRequestIsFinished(state);
    const user = selectors.getUser(state);
    const isTemplateRequest = selectors.isTemplateRequest(state);
    const isEmbedded = selectors.isEmbedded(state);
    const integrationName = selectors.getIntegrationName(state);
    const signatureActionFlow = selectors.getPAPSignatureActionFlow(state);
    const actionSurface = selectors.getPAPActionSurface(state);

    if (requestIsFinished) {
      throw new Error('Request has already been successfully sent');
    }
    const res = await appActions.prepAndSend.sendPrepAndSendRequest(
      buildSaveData(state),
      isFromChargeModal,
    );

    if (
      !res.success &&
      (('errors' in res && res.errors && res.errors.length > 0) ||
        ('status' in res && res.status === 'error') ||
        ('error' in res && 'code' in res && res.code === 'csrf_protection'))
    ) {
      return res;
    }

    const workflow = selectors.getWorkflow(state);
    trackHeapCustomEvent('Signature Request Sent', {
      workflow: workflow.name,
    });

    if (isTemplateRequest) {
      logPAPEvent(
        PAP_Save_Template({
          integrationSource:
            retrieveFormattedIntegrationSource(integrationName),
          eventState: 'success',
          actionSurface,
          isEmbedded,
        }),
        user.dbxUserId,
        user.id,
      );
    } else {
      logPAPEvent(
        PAP_Send_SignatureDoc({
          actionSurface,
          signatureActionFlow,
          eventState: 'success',
          funnelStep: 'review_and_send',
        }),
        user.dbxUserId,
        user.id,
      );
    }

    if (
      isStrictlyEmbedded ||
      (isDeepIntegration &&
        includes(workflow.tags, [WorkflowTags.SignatureRequestTemplate]))
    ) {
      // asserting the type for embedded response
      const resp = res as
        | SendRequestEmbeddedRequestResponse
        | SendRequestEmbeddedTemplateResponse;
      await dispatch(finalizeEmbedded(resp));
      return null;
    }
    // otherwise assert the response as UI
    const response = res as SendRequestUiResponse;
    if (
      includes(workflow.tags, [
        WorkflowTags.SignatureRequestFax,
        WorkflowTags.DeepIntegration,
      ])
    ) {
      if (isDeepIntegration) {
        if (includes(workflow.tags, [WorkflowTags.CreateTemplate])) {
          const response = res as SendRequestEmbeddedTemplateResponse;
          // Deep Integration create template flow - kick off polling for the template status
          dispatch(
            deepIntegrationActions.pollTemplateStatusForDeepIntegration(
              response.template_status_id,
            ),
          );
        }
        // Deep Integration - just move to the final loading page
        await dispatch(workflowActions.nextPageInWorkflow());
        return null;
      } else {
        // TODO: Based on this comment,
        // https://hellosign.atlassian.net/browse/DEV-9104?focusedCommentId=123120
        // holding off on implementing send for HF
        // until we have outlined the flow for HF requests.
        // eslint-disable-next-line no-console
        console.error('Not implemented');
      }
    }

    if (
      includes(workflow.tags, [
        WorkflowTags.SignatureRequest,
        WorkflowTags.SignatureRequestDocument,
        WorkflowTags.SignatureRequestTemplate,
      ]) &&
      response.redirectUrl
    ) {
      await dispatch(updateRequestIsFinished(true));
      return response;
    }
    if (
      includes(workflow.tags, [
        WorkflowTags.CreateTemplate,
        WorkflowTags.CreateTemplateLink,
      ]) &&
      response.templateGuid
    ) {
      const templateRedirectUrl = await dispatch(
        getTemplateRedirectUrl(response.templateGuid),
      );
      await dispatch(updateRequestIsFinished(true));
      return templateRedirectUrl;
    }

    if (
      includes(workflow.tags, [WorkflowTags.SignatureRequestFax]) &&
      response.needsCharge
    ) {
      return response;
    }
    return null;
  };

export const selfSave =
  (): ThunkAction<Promise<SelfSaveRequestResponse | null>> =>
  async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();
    const state = getState();

    const response = await appActions.prepAndSend.selfSavePrepAndSendRequest(
      buildSaveData(state),
    );
    // If it wasn't successfull AND has errors to display, return response so that
    // it can be handled by handleSendResponse
    if (!response.success && response.errors && response.errors.length > 0) {
      return response;
    }

    // If it's one of these workflows for some reason, return the response so it
    // can can be handled by handleSendResponse
    const workflow = selectors.getWorkflow(state);
    if (
      includes(workflow.tags, [
        WorkflowTags.SignatureRequest,
        WorkflowTags.SignatureRequestDocument,
      ]) &&
      response.redirectUrl
    ) {
      return response;
    }

    // Response will only contain the super group guid if the transmission group type is gmail
    // Return the response so the handler can call /send/readyCallback with it.
    if (
      includes(workflow.tags, [WorkflowTags.GmailSelfSign]) &&
      response.signatureRequestId
    ) {
      return response;
    }

    // If none of the conditions above match, return null to stop processing the response.
    //
    // What happens if this just always returns the response? Does anything break? :shrug:
    return null;
  };

const messages = defineMessages({
  backendValidationError: {
    id: '',
    description:
      'text for displaying a validation error identified by the backend',
    defaultMessage: 'The following error was encountered: {error}',
  },
  backendRestrictedError: {
    id: '',
    description:
      'A message displayed to the user when signature request or bulk send is restricted due to rate limiting.',
    defaultMessage:
      'You have sent too many requests or invitations. Please read more about our <a>usage policy</a> and contact us at {supportEmail} if you have questions.',
  },
});

const handleSendResponse =
  (
    response: SendRequestUiResponse | SelfSaveRequestResponse | null,
  ): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    if (response) {
      if (response.error && response.code === 'csrf_protection') {
        dispatch(
          createBannerMessage(
            messages.backendValidationError,
            NotificationBannerType.Err,
            { error: response.error },
          ),
        );
      } else if (response.errors && response.errors.length > 0) {
        response.errors.forEach((error) =>
          dispatch(
            createBannerMessage(
              messages.backendValidationError,
              NotificationBannerType.Err,
              { error: error.message },
            ),
          ),
        );
      } else if (
        !response.success &&
        'status' in response &&
        response.status === 'error'
      ) {
        if (response.errorMsg === SHARING_INVITE_RATE_LIMIT) {
          dispatch(
            createBannerMessage(
              messages.backendRestrictedError,
              NotificationBannerType.Err,
              {
                // eslint-disable-next-line react/display-name
                a: (messageBody: string) =>
                  React.createElement(
                    'a',
                    { href: '/acceptable-use-policy' },
                    messageBody,
                  ),
                supportEmail: React.createElement(
                  'a',
                  {
                    href: `mailto:${HfReactHelper.HfConstants.supportEmail.hellosign}`,
                  },
                  HfReactHelper.HfConstants.supportEmail.hellosign,
                ),
              },
            ),
          );
        } else {
          dispatch(
            createBannerMessage(
              messages.backendValidationError,
              NotificationBannerType.Err,
              { error: response.errorMsg },
            ),
          );
        }
      } else if (response.needsCharge) {
        dispatch(setFlags(response));
      } else if (response.redirectUrl) {
        const state = getState();
        const isHelloFax = selectors.isHelloFax(state);
        let url = response.redirectUrl;

        if (url === '/' || url === '/home/index' || url === '/home/manage') {
          url += queryParams({
            success:
              'success' in response && response.success ? true : undefined,
            // SelfSaveRequestResponse doesn't have these flags, so I need the
            // `'property' in response` for TypeScript
            isFirstFax:
              isHelloFax && 'isFirstFax' in response && response.isFirstFax
                ? true
                : undefined,
            isAtFaxLimit:
              isHelloFax && 'isAtFaxLimit' in response && response.isAtFaxLimit
                ? true
                : undefined,
            templateConversionCandidateId:
              'templateConversionCandidateId' in response &&
              response.templateConversionCandidateId
                ? response.templateConversionCandidateId
                : undefined,
            shouldTemplateCreateShow:
              'shouldTemplateCreateShow' in response &&
              response.shouldTemplateCreateShow
                ? response.shouldTemplateCreateShow
                : undefined,
            simplifyDocToTemplateModal:
              'simplifyDocToTemplateModal' in response &&
              response.simplifyDocToTemplateModal
                ? response.simplifyDocToTemplateModal
                : undefined,
            shouldEnableGdriveModal:
              'shouldEnableGdriveModal' in response &&
              response.shouldEnableGdriveModal
                ? response.shouldEnableGdriveModal
                : undefined,
            shouldEnableDropboxModal:
              'shouldEnableDropboxModal' in response &&
              response.shouldEnableDropboxModal
                ? response.shouldEnableDropboxModal
                : undefined,
            shouldEnableEditAndResendModal:
              'shouldEnableEditAndResendModal' in response &&
              response.shouldEnableEditAndResendModal
                ? response.shouldEnableEditAndResendModal
                : undefined,
            isEditAndResend:
              'isEditAndResend' in response && response.isEditAndResend
                ? response.isEditAndResend
                : undefined,
          });
        }
        redirectTo(url);

        // DEV-12172 - Adding an empty promise so as to keep the Send response to
        // not resolve. This will keep the button from becoming enabled again
        // before the redirectTo() can finish executing.
        await new Promise(() => {});
      }
    }
  };

export const sendSRAndRedirect =
  (isFromChargeModal: boolean = false): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    const result = await dispatch(send(isFromChargeModal));
    await dispatch(handleSendResponse(result));
  };

export const handleEmbeddedDownloadFile =
  (result: SelfSaveRequestResponse): ThunkAction<Promise<void>> =>
  async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();
    const state = getState();
    const embeddedData = selectors.getEmbeddedData(state);
    if (result && result.signatureRequestId && embeddedData) {
      const url = `https:${HfReactHelper.urlHelper(`attachment/downloadCopy/guid/${result.signatureRequestId}`)}`;
      await appActions.prepAndSend.downloadFile(url);
      const { parentUrl = '', redirectUrl } = embeddedData;
      const { title, message, expiresAt } = selectors.getDocument(state);
      const ccs = selectors.getCCs(state);
      finalizeEmbeddedFlow({
        parentUrl,
        redirectUrl,
        eventData: {
          success: true,
          signatureRequestId: result.signatureRequestId,
          signatureRequestInfo: {
            title,
            message,
            expiresAt,
            signatures: [],
            ccEmailAddresses: ccs.flatMap((cc) =>
              cc.type === CCTypes.CCEmail ? [cc.email] : [],
            ),
          },
        },
      });
      return;
    }
    await dispatch(handleSendResponse(result));
  };

export const selfSaveAndRedirect =
  (): ThunkAction<Promise<boolean>> => async (dispatch, getState) => {
    const result = await dispatch(selfSave());
    const state = getState();
    const embeddedSelfSign = selectors.getEmbeddedData(state)?.isSelfSign;
    if (embeddedSelfSign && result) {
      await dispatch(handleEmbeddedDownloadFile(result));
    } else {
      await dispatch(handleSendResponse(result));
    }
    return result?.success || false;
  };

export const selfSaveAfterEditor =
  (): ThunkAction<Promise<void>> => async (dispatch, getState) => {
    const result = await dispatch(selfSave());

    const state = getState();
    const embeddedData = selectors.getEmbeddedData(state);
    // SURPRISE! If the response wasn't an error and doesn't contain a redirect,
    // it won't get returned from selfSave. To make sure I don't break anything in
    // this ticket, I expect the backend to return any `redirectUrl` to get through
    // `selfSave` and the `signatureRequestId` that I need.
    if (result && result.signatureRequestId && embeddedData) {
      const { parentUrl = '', redirectUrl } = embeddedData;
      const { title, message, expiresAt } = selectors.getDocument(state);
      const ccs = selectors.getCCs(state);

      finalizeEmbeddedFlow({
        parentUrl,
        redirectUrl,
        eventData: {
          success: true,
          signatureRequestId: result.signatureRequestId,
          signatureRequestInfo: {
            title,
            message,
            expiresAt,
            signatures: [],
            ccEmailAddresses: ccs.flatMap((cc) =>
              cc.type === CCTypes.CCEmail ? [cc.email] : [],
            ),
          },
        },
      });
      return;
    }

    await dispatch(handleSendResponse(result));
  };

export const gmailSaveAfterEditor =
  (): ThunkAction<Promise<void>> => async (dispatch) => {
    const selfSaveResult = await dispatch(selfSave());
    if (!selfSaveResult) {
      // selfSave() should only return null if someone changed the workflow tags for gmail and
      // the backend didn't return superGroupGuid.
      throw new Error('selfSave returned null');
    }

    // Backend shouldn't return a redirectUrl so we don't need to worry about it here.
    // Call handleSendResponse for error handling.
    await dispatch(handleSendResponse(selfSaveResult));

    await dispatch({
      type: types.Actions.SetSignatureRequestId,
      payload: selfSaveResult.signatureRequestId,
    });
  };

export const integrationReloadSendTemplate =
  (): ThunkAction<Promise<void>> => async () => {
    postTo(window.parent, { type: MessageType.ReloadSendTemplate });
  };

export const sendBulkRequestAndRedirect =
  (): ThunkAction<Promise<void>> => async (dispatch) => {
    const result = await dispatch(submitBulkSendRequest());
    await dispatch(handleSendResponse(result));
  };

export function filterDuplicateCCs(payload: DataSchema): DataSchema {
  // Backend might return duplicate CC Roles, filtering on frontend
  const ccNameMap: Record<string, boolean> = {};
  const filteredCCs = payload.data.ccs.filter((cc: CC) => {
    if ('role' in cc && cc.role) {
      if (ccNameMap[cc.role.name]) {
        return false;
      }
      ccNameMap[cc.role.name] = true;
      return true;
    }

    return true;
  });

  return {
    ...payload,
    data: {
      ...payload.data,
      ccs: filteredCCs,
    },
  };
}

/**
 * Validation fails if the server sends an empty CCEmail or an empty Signer. To
 * work around it, the BE sends a role and it's converted to an empty CCEmail
 * or Signer.
 */
export function convertRoles(payload: DataSchema): DataSchema {
  const recipients = payload.data.recipients.map((recipient) => {
    switch (recipient.type) {
      case RecipientTypes.Role: {
        return recipientActions.generateSigner({
          id: recipient.id,
          role: recipient,
          attachments: recipient.attachments,
        });
      }
      default:
        return recipient;
    }
  });

  return {
    ...payload,
    data: {
      ...payload.data,
      recipients,
    },
  };
}

export const cleanInit =
  (
    transmissionGroupGuid: string,
    currentPath?: string,
  ): ThunkAction<Promise<void>> =>
  async (dispatch, getState, getExtra) => {
    const { featureFlags, appActions } = getExtra();

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    await dispatch(init(transmissionGroupGuid));

    const selectCurrentPath = (state: StoreShape) => {
      const workflow = selectors.getWorkflow(state);
      const currentIndex = selectors.getCurrentWorkflowPageIndex(state);
      const currentPage = workflow.pages[currentIndex];
      return currentPage.path;
    };

    const isCurrentPageValid = async (state: StoreShape) => {
      const workflow = selectors.getWorkflow(state);
      const currentIndex = selectors.getCurrentWorkflowPageIndex(state);
      const currentPage = workflow.pages[currentIndex];

      const flags = {
        ...featureFlags,
        HelloSign_Prep_Send_Early_Next: true,
      };

      const schema = currentPage.component.selectFormSchema(state, flags);
      const formData = currentPage.component.selectFormValues(state, flags);

      const useEmailDeliverabilityValidation =
        featureFlags.HelloSign_Bees_20220324_Email_Deliverability_Validation;

      try {
        if (!schema) {
          return true;
        }

        await validateYupSchema(formData, schema, false, {
          appActions,
          useEmailDeliverabilityValidation,
          isSubmitting: false,
          emailValidationContext: {
            emailValidationErrors: null,
            emailValidationBypassList: {},
            updateEmailValidationBypassList: () => {},
            updateEmailValidationErrors: () => {},
          },
        });
      } catch (err) {
        return false;
      }
      return true;
    };

    if (currentPath) {
      while (
        selectCurrentPath(getState()) !== currentPath &&
        // eslint-disable-next-line no-await-in-loop
        (await isCurrentPageValid(getState()))
      ) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(nextPageInWorkflow());
      }
    }
  };

export function fixEmbeddedRoles(
  data: DataSchema,
  requestType: types.RequestTypes,
): DataSchema {
  const isEmbeddedUsingTemplateDI =
    data.embeddedData?.usingTemplate &&
    requestType === types.RequestTypes.EmbeddedRequest &&
    data.flags?.integration?.name === IntegrationNames.DeepIntegration;

  const isHubSpotV2Integration =
    data.embeddedData?.usingTemplate &&
    requestType === types.RequestTypes.EmbeddedRequest &&
    data.flags?.integration?.name === IntegrationNames.HubSpotV2;

  // if it's a send template request, the recipients needs to be updated
  // according to the updated map stored in cache for templates
  if (
    requestType === types.RequestTypes.SendTemplate ||
    isEmbeddedUsingTemplateDI ||
    isHubSpotV2Integration
  ) {
    let r = convertRoles(data);
    r = filterDuplicateCCs(r);
    return r;
  }
  return data;
}

export const init =
  (
    transmissionGroupGuid: string,
    isFromDashboard?: boolean,
    isRefresh?: boolean,
  ): ThunkAction<Promise<void>> =>
  async (dispatch, getState, getExtra) => {
    const { appActions, featureFlags } = getExtra();
    const data = await appActions.prepAndSend.getPrepAndSendData(
      transmissionGroupGuid,
    );
    let payload: DataSchema = await dataSchema.validate(data);
    const requestType = payload.flags.requestType;
    const nonTemplateFiles = payload.data.files.filter(
      (f) => notEmpty(f) && f.type !== UserFileTypes.Template,
    );

    // When new Hellofax users attempt to send faxes, they now get redirected to Sign.
    // These redirected P&S requests come with recipients without names.
    // This is a hacky alternative to loosening the schema to allow empty signer names.
    if (data.flags.isFromFax) {
      payload.data.recipients = payload.data.recipients.map((recipient) => {
        if (recipient.type === 'signer' && recipient.name === 'PLACEHOLDER') {
          return {
            ...recipient,
            name: '',
          };
        }
        return recipient;
      });
    }

    // Set up self sign data
    const useNewEditorPage = Boolean(
      featureFlags.sign_core_2024_06_12_new_editor_page,
    );

    const workflowArgs: WorkflowArgs = { useNewEditorPage };

    // When using a template for deep integration, roles need to be converted properly.
    payload = fixEmbeddedRoles(payload, requestType);

    dispatch({
      type: types.Actions.Init,
      payload,
    });

    // We set the initial workflow to Unknown for the first init (not on initRefresh)
    // because Unknown _looks_ like it has all the steps for most Prep&Send flows.
    // This provides visual continuity before we work out which actual workflow to use later.
    if (!isRefresh) {
      await dispatch(
        workflowActions.setWorkflow(
          await getWorkflowConfiguration(
            'SignatureRequestUnknown',
            workflowArgs,
          ),
        ),
      );
    }

    // doing this after the init because the init changes the workflow to the default
    // state when there is no key in state pointing to a request
    if (requestType === types.RequestTypes.SendTemplate) {
      // if the request type is template, then update the workflow so
      // we use the correct request type when converting document(s)
      let workflowType: Workflow = await getWorkflowConfiguration(
        'SignatureRequestTemplate',
        workflowArgs,
      );
      if (nonTemplateFiles.length > 0) {
        // Handling one-time-doc with a template
        workflowType = await getWorkflowConfiguration(
          'SignatureRequestTemplateAndDocument',
          workflowArgs,
        );
      }
      await dispatch(workflowActions.setWorkflow(workflowType));
    }
    // Setting the workflow here so /attachment/conversionStatus/ sends the correct form_type_code
    if (requestType === types.RequestTypes.Template) {
      await dispatch(
        workflowActions.setWorkflow(
          await getWorkflowConfiguration('CreateTemplate', workflowArgs),
        ),
      );
    } else if (requestType === types.RequestTypes.ReusableLink) {
      await dispatch(
        workflowActions.setWorkflow(
          await getWorkflowConfiguration('CreateTemplateLink', workflowArgs),
        ),
      );
    }

    const files = payload.data.files;
    const filesArray = Object.values(files).filter(notEmpty);
    const isHelloFax = payload.flags.isHelloFax;
    const templateGalleryId = payload.flags?.templateGalleryId;

    const showRecipient = selectors.isForceRoles(getState());
    const showReview = selectors.isForceSubjectMessage(getState());
    const showSigner = selectors.isForceSigner(getState());
    const apiApp = selectors.getApiApp(getState());
    const user = selectors.getUser(getState());
    const signatureActionFlow = selectors.getPAPSignatureActionFlow(getState());
    const actionSurface = selectors.getPAPActionSurface(getState());

    if (!isRefresh) {
      logPAPEvent(
        PAP_Start_SignatureDoc({
          actionSurface,
          signatureActionFlow,
          eventState: 'success',
        }),
        user.dbxUserId,
        user.id,
      );
    }

    const { integration } = payload.flags;

    // add templates into the state if it's not already part of the request
    const templateGuidsInRequest = selectors
      .getTemplates(getState())
      .map((t) => t.templateGuid);
    payload.data.templates.forEach((template) => {
      if (!templateGuidsInRequest.includes(template.templateGuid)) {
        dispatch({
          type: types.Actions.UseTemplate,
          payload: template,
        });
      }
    });
    // for all these types, just do regular file upload & poll
    // embedded needs to be a bit different
    switch (requestType) {
      case types.RequestTypes.SendDoc:
      case types.RequestTypes.RequestSig:
      case types.RequestTypes.SendTemplate:
      case types.RequestTypes.SelfSave:
      case types.RequestTypes.Template:
      case types.RequestTypes.ReusableLink:
      case types.RequestTypes.BulkSend:
      case types.RequestTypes.FinalizeDocGmail:
      case types.RequestTypes.EditAndResendDoc:
        filesArray.forEach((file) => {
          dispatch(handleFileResponse(file));
        });
        break;
      default:
        break;
    }

    const isMeOnly = selectors.isMeOnly(getState());

    // Init workflow separately from above to make it easier to grok
    switch (requestType) {
      case types.RequestTypes.SendDoc:
      case types.RequestTypes.RequestSig:
      case types.RequestTypes.SendTemplate:
      case types.RequestTypes.SelfSave:
      case types.RequestTypes.EditAndResendDoc:
        if (isHelloFax) {
          await dispatch(
            workflowActions.setWorkflow(
              await getWorkflowConfiguration(
                'SignatureRequestFaxNoEditor',
                workflowArgs,
              ),
            ),
          );
          break;
        }
        if (filesArray.length > 0) {
          if (isMeOnly) {
            await dispatch(
              workflowActions.setWorkflow(
                await getWorkflowConfiguration('SelfSign', workflowArgs),
              ),
            );
            break;
          }
          await dispatch(workflowActions.changeWorkflowFromFiles());
          break;
        }

        await dispatch(
          workflowActions.setWorkflow(
            await getWorkflowConfiguration(
              'SignatureRequestUnknown',
              workflowArgs,
            ),
          ),
        );
        break;
      case types.RequestTypes.EmbeddedRequest: {
        // if using a template, use the SignatureRequestTemplate types
        const isUsingTemplate = selectors.isEmbeddedUsingTemplate(getState());
        const isEditingTemplate =
          selectors.isEmbeddedEditingTemplate(getState());
        if (integration && integration.name) {
          switch (integration.name) {
            case IntegrationNames.DeepIntegration:
              if (isUsingTemplate) {
                await dispatch(
                  workflowActions.setWorkflow(
                    await getWorkflowConfiguration(
                      'DeepIntegrationUseTemplate',
                      workflowArgs,
                    ),
                  ),
                );
              } else if (isEditingTemplate) {
                await dispatch(
                  workflowActions.setWorkflow(
                    await getWorkflowConfiguration(
                      'DeepIntegrationEditTemplate',
                      workflowArgs,
                    ),
                  ),
                );
              } else if (integration.data.recipientsProvidedByHostPage) {
                await dispatch(
                  workflowActions.setWorkflow(
                    await getWorkflowConfiguration(
                      'DeepIntegrationRecipientsProvidedByHostPage',
                      workflowArgs,
                    ),
                  ),
                );
              } else {
                await dispatch(
                  workflowActions.setWorkflow(
                    await getWorkflowConfiguration(
                      'DeepIntegration',
                      workflowArgs,
                    ),
                  ),
                );
              }
              break;
            case IntegrationNames.OfficeSelfSign:
              await dispatch(
                workflowActions.setWorkflow(
                  await getWorkflowConfiguration(
                    'OfficeSelfSign',
                    workflowArgs,
                  ),
                ),
              );
              break;
            case IntegrationNames.SharePoint:
              await dispatch(
                workflowActions.setWorkflow(
                  await getWorkflowConfiguration('Integration', workflowArgs),
                ),
              );
              break;
            case IntegrationNames.HubSpot:
              break;
            case IntegrationNames.HubSpotV2:
              if (isUsingTemplate) {
                await dispatch(
                  workflowActions.setWorkflow(
                    await getWorkflowConfiguration(
                      'IntegrationSendTemplate',
                      workflowArgs,
                    ),
                  ),
                );
              } else {
                await dispatch(
                  workflowActions.setWorkflow(
                    await getWorkflowConfiguration(
                      'IntegrationOneTimeDoc',
                      workflowArgs,
                    ),
                  ),
                );
              }
              break;
            default:
              unreachable(integration);
          }
        } else if (showSigner && showReview) {
          const workflow = isUsingTemplate
            ? await getWorkflowConfiguration(
                'EmbeddedRequestRecipientReviewWithTemplate',
                workflowArgs,
              )
            : await getWorkflowConfiguration(
                'EmbeddedRequestRecipientReview',
                workflowArgs,
              );
          await dispatch(workflowActions.setWorkflow(workflow));
        } else if (showSigner) {
          const workflow = isUsingTemplate
            ? await getWorkflowConfiguration(
                'EmbeddedRequestRecipientWithTemplate',
                workflowArgs,
              )
            : await getWorkflowConfiguration(
                'EmbeddedRequestRecipient',
                workflowArgs,
              );
          await dispatch(workflowActions.setWorkflow(workflow));
        } else if (showReview) {
          const workflow = isUsingTemplate
            ? await getWorkflowConfiguration(
                'EmbeddedRequestReviewWithTemplate',
                workflowArgs,
              )
            : await getWorkflowConfiguration(
                'EmbeddedRequestReview',
                workflowArgs,
              );
          await dispatch(workflowActions.setWorkflow(workflow));
        } else {
          const workflow = isUsingTemplate
            ? await getWorkflowConfiguration(
                'EmbeddedRequestWithTemplate',
                workflowArgs,
              )
            : await getWorkflowConfiguration('EmbeddedRequest', workflowArgs);
          await dispatch(workflowActions.setWorkflow(workflow));
        }
        if (apiApp) {
          hsEmbedded.postMessage(
            { type: hsEmbedded.messages.APP_INITIALIZE },
            apiApp.parentUrl,
          );
        }

        // this is here because handleFileResponse looks at the workflow and skips polling
        filesArray.forEach((file) => {
          dispatch(handleFileResponse(file));
        });
        break;
      }
      case types.RequestTypes.EmbeddedTemplate:
        if (
          integration &&
          integration.name &&
          integration.name === IntegrationNames.DeepIntegration
        ) {
          const isEditingTemplate =
            selectors.isEmbeddedEditingTemplate(getState());
          if (isEditingTemplate) {
            await dispatch(
              workflowActions.setWorkflow(
                await getWorkflowConfiguration(
                  'DeepIntegrationEditTemplate',
                  workflowArgs,
                ),
              ),
            );
          } else {
            await dispatch(
              workflowActions.setWorkflow(
                await getWorkflowConfiguration(
                  'DeepIntegrationTemplate',
                  workflowArgs,
                ),
              ),
            );
          }
        } else if (
          integration &&
          integration.name === IntegrationNames.HubSpotV2
        ) {
          await dispatch(
            workflowActions.setWorkflow(
              await getWorkflowConfiguration(
                'IntegrationCreateTemplate',
                workflowArgs,
              ),
            ),
          );
        } else if (showRecipient && showReview) {
          await dispatch(
            workflowActions.setWorkflow(
              await getWorkflowConfiguration(
                'EmbeddedTemplateRecipientReview',
                workflowArgs,
              ),
            ),
          );
        } else if (showRecipient) {
          await dispatch(
            workflowActions.setWorkflow(
              await getWorkflowConfiguration(
                'EmbeddedTemplateRecipient',
                workflowArgs,
              ),
            ),
          );
        } else if (showReview) {
          await dispatch(
            workflowActions.setWorkflow(
              await getWorkflowConfiguration(
                'EmbeddedTemplateReview',
                workflowArgs,
              ),
            ),
          );
        } else {
          await dispatch(
            workflowActions.setWorkflow(
              await getWorkflowConfiguration('EmbeddedTemplate', workflowArgs),
            ),
          );
        }
        if (apiApp) {
          hsEmbedded.postMessage(
            { type: hsEmbedded.messages.APP_INITIALIZE },
            apiApp.parentUrl,
          );
        }

        // this is here because handleFileResponse looks at the workflow and skips polling
        filesArray.forEach((file) => {
          dispatch(handleFileResponse(file));
        });
        break;
      case types.RequestTypes.Template:
        await dispatch(
          workflowActions.setWorkflow(
            await getWorkflowConfiguration(
              templateGalleryId
                ? 'CreateTemplateFromGallery'
                : 'CreateTemplate',
              workflowArgs,
            ),
          ),
        );
        break;
      case types.RequestTypes.ReusableLink:
        await dispatch(
          workflowActions.setWorkflow(
            await getWorkflowConfiguration('CreateTemplateLink', workflowArgs),
          ),
        );
        break;
      case types.RequestTypes.BulkSend:
        await dispatch(
          workflowActions.setWorkflow(
            await getWorkflowConfiguration('BulkSend', workflowArgs),
          ),
        );
        break;
      case types.RequestTypes.FinalizeDocGmail:
        await dispatch(
          workflowActions.setWorkflow(
            await getWorkflowConfiguration('GmailSelfSign', workflowArgs),
          ),
        );
        break;
      case types.RequestTypes.Demo:
      case types.RequestTypes.ResponseSig:
        throw new Error('Not Implemented');
      default:
        unreachable(requestType);
    }

    if (isFromDashboard) {
      dispatch({
        type: types.Actions.UpdateMeta,
        payload: {
          currentPath: 'recipients',
        },
      });
    }
  };

export const initRefresh =
  (): ThunkAction<Promise<void>> => async (dispatch, getState) => {
    const id = selectors.getTransmissionGroupGuid(getState());
    await dispatch(init(id, undefined, true));
  };

export const getUploadIntegrations =
  (): ThunkAction<Promise<void>> => async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();
    const payload = await appActions.prepAndSend.getUploadIntegrations();
    dispatch({ type: types.Actions.SetFlags, payload });
  };

export const preloadTransmissionGroupGuid =
  (): ThunkAction<Promise<string>> => async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();
    const guid = await appActions.prepAndSend.preloadTransmissionGroupGuid();
    if (guid === null) {
      throw new Error(
        'Failed to preload a transmission group guid for prep and send',
      );
    }
    return guid;
  };

export const refreshEditorFields =
  (): ThunkAction<Promise<void>> => async (dispatch, getState, getExtra) => {
    const transmissionGroupGuid =
      selectors.getTransmissionGroupGuid(getState());
    const { appActions } = getExtra();
    const data = await appActions.prepAndSend.getPrepAndSendData(
      transmissionGroupGuid,
    );
    const payload: DataSchema = await dataSchema.validate(data);

    dispatch({
      type: types.Actions.UpdateEditorFields,
      payload: payload.data.editorFields,
    });
  };

export const updateEditorFields = (
  editorFields: DataSchema['data']['editorFields'],
): types.PrepAndSendAction => ({
  type: types.Actions.UpdateEditorFields,
  payload: editorFields,
});

export const prepareEditor =
  (): ThunkAction<Promise<string>> => async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();
    const state = getState();
    const templateOneOffFile = selectors.hasTemplateOneOffFile(state);
    const hasSignerOrder = selectors.getSettings(state).recipientOrder || false;
    const embeddedData = selectors.getEmbeddedData(state);
    const isStepperHidden = selectors.isStepperHidden(state);

    const url = await appActions.prepAndSend.prepareEditor({
      recipients: selectors.getRecipients(state),
      files: selectors.getFiles(state),
      preloadedTsmGroupKey: selectors.getTransmissionGroupGuid(state),
      requestType: selectors.getRequestType(state),
      templateOneOffFile,
      hasSignerOrder,
      templateGuid: undefined,
      hiddenStepper: isStepperHidden,
      parentUrl: embeddedData ? embeddedData.parentUrl : undefined,
      editedTemplateGuid: embeddedData
        ? embeddedData.editedTemplateGuid
        : undefined,
      locale: embeddedData ? embeddedData.locale : undefined,
    });
    return url;
  };

export const getContactImportStatus =
  (type: 'gmail' | 'yahoo'): ThunkAction<Promise<boolean>> =>
  async (_dispatch, _getState, getExtra) => {
    const { appActions } = getExtra();
    const status = await appActions.prepAndSend.getContactImportStatus(type);

    return status.complete;
  };

export const updateNumberFields = (
  numFields: number,
): types.PrepAndSendAction => ({
  type: types.Actions.UpdateNumberFields,
  payload: numFields,
});

// TODO: clean up when DEV-12786 is done
export const updateNextClickTracker = (
  updatedTimer: number,
): types.PrepAndSendAction => ({
  type: types.Actions.UpdateNextClickTracker,
  payload: updatedTimer,
});

export const updateShouldConvertDocToTemplate = (
  shouldConvertDocToTemplate: boolean,
): types.PrepAndSendAction => ({
  type: types.Actions.UpdateShouldConvertDocToTemplate,
  payload: shouldConvertDocToTemplate,
});
