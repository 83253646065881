import React from 'react';
import { appActionsPanel } from 'hello-react/components/app-actions-panel';
import { SignAppClientProvider } from 'js/sign-components/sign-app-client/context';
import type { WebAppClientNamespaces } from './build-web-app-client';

/**
 * <AppContextProvider is how components interact with the backend. It has a
 * pattern for enforcing that you write a type-compatible substitute for use in
 * Storybook and tests. As long as we can agree on the types, this allows for a
 * lot of development to just happen in Storybook before the backend code is in
 * place.
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764535232006793&cot=14
 */

export const AppContextProvider = (
  props: React.PropsWithChildren<{ value: WebAppClientNamespaces }>,
) => (
  <SignAppClientProvider client={props.value}>
    {props.children}

    {appActionsPanel}
  </SignAppClientProvider>
);
