import { Field, Signer } from 'signer-app/types/editor-types';
import { Rule } from 'signer-app/conditional-logic';

export type DocumentPreviewNamespaceState = {
  fields: Field[];
  rules: Rule[];
  signers: Signer[];
  signerOrderEnabled: boolean;
  selectedSignerId: undefined | Signer['id'];
  lastUpdatedFieldId?: undefined | Field['id'];
};

export enum Actions {
  Init = 'documentPreview/Init',
  SetPreviewSigner = 'documentPreview/SetPreviewSigner',
  UpdateField = 'documentPreview/UpdateField',
}

export interface InitAction {
  type: Actions.Init;
  payload: Omit<DocumentPreviewNamespaceState, 'selectedSignerId'>;
}
export interface SetPreviewSigner {
  type: Actions.SetPreviewSigner;
  payload: undefined | Signer['id'];
}
export interface UpdateField {
  type: Actions.UpdateField;
  payload: {
    id: Field['id'];
    updates: Partial<Field>;
  };
}

export type DocumentPreviewAction = InitAction | SetPreviewSigner | UpdateField;
