import React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styles from './index.scss';
import ErrorIcon from './icons/error-triangle';
import InfoIcon from './icons/info-circle';
import SuccessIcon from './icons/success-circle';
import WarningIcon from './icons/warning-circle';
import CloseX from './icons/close';
import { unreachable } from 'js/sign-components/common/ts-utils';
import * as actions from './data/actions';
import * as selectors from './data/selectors';
import {
  BannerMessage,
  NotificationBannerType,
  TranslatedBannerMessage,
} from './data/types';
import { useIsLastMountedInstanceOf } from 'signer-app/utils/use-is-last-mounted-instance-of';

type BannerProps = {
  message: BannerMessage | TranslatedBannerMessage;
  onClose: (id: BannerMessage['guid']) => void;
};

export function NotificationBanner({ message, onClose }: BannerProps) {
  const intl = useIntl();
  const { timeout, guid } = message;
  React.useEffect(() => {
    if (timeout) {
      const handle = setTimeout(() => onClose(guid), timeout);
      return () => clearTimeout(handle);
    }
    return () => {};
  }, [guid, onClose, timeout]);

  let bannerClass = styles.error;
  bannerClass +=
    message.guid === 'one-time-use-notification-banner-guid'
      ? ` ${styles.showOnTheTop}`
      : '';
  let IconType = ErrorIcon;

  switch (message.type) {
    case NotificationBannerType.Err:
      // use defaults from above
      break;
    case NotificationBannerType.Info:
      IconType = InfoIcon;
      bannerClass = styles.info;
      break;
    case NotificationBannerType.Success:
      IconType = SuccessIcon;
      bannerClass = styles.success;
      break;
    case NotificationBannerType.Warning:
      IconType = WarningIcon;
      bannerClass = styles.warning;
      break;
    default:
      unreachable(message.type);
  }

  return (
    <div className={bannerClass} data-qa-ref="notification-wrapper">
      <IconType className={styles.icon} aria-hidden="true" />

      <span data-qa-ref="validation-error">
        {'messageDescriptor' in message
          ? intl.formatMessage(message.messageDescriptor, message.values || {})
          : message.translatedMessage}
      </span>

      <div
        className={styles.closeBox}
        data-qa-ref={`notification-${message.type}-close`}
        onClick={() => onClose(message.guid)}
      >
        <CloseX />
      </div>
    </div>
  );
}

export { NotificationBannerType };
type NotificationListProps = {
  className?: string;
  enableTimeOut?: boolean;
};
export default function NotificationList({
  className,
  enableTimeOut,
}: NotificationListProps) {
  const dispatch = useDispatch();
  const activeList = useIsLastMountedInstanceOf(NotificationList);

  const messageList = useSelector(selectors.getBannerMessages);

  const onClose = React.useCallback(
    (id: BannerMessage['guid']) => {
      dispatch(actions.removeBannerMessage(id));
    },
    [dispatch],
  );

  React.useEffect(() => {
    const timeOut = setTimeout(() => {
      if (enableTimeOut) {
        messageList.map((message) => {
          return onClose(message.guid);
        });
      }
    }, 4000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [enableTimeOut, messageList, onClose]);

  if (!activeList) {
    return null;
  }
  const messageBanners = messageList.map((message) => {
    return (
      <NotificationBanner
        key={message.key || message.guid}
        message={message}
        onClose={onClose}
      />
    );
  });
  return (
    <div className={classNames(styles.container, className)}>
      {messageBanners}
    </div>
  );
}
