import { ThunkAction } from 'hellospa/redux/types';
import * as selectors from 'hellospa/page/prep-and-send/data/selectors';
import { Actions } from 'hellospa/page/prep-and-send/data/types';
import {
  Document,
  documentSchema,
} from 'hellospa/page/prep-and-send/data/types/document';
import capture from './capture';

export const validateDocument =
  (): ThunkAction<Promise<boolean>> => async (dispatch, getState) => {
    const state = getState();
    const document = selectors.getDocument(state);

    const validationErrors = await capture(documentSchema, document);
    return validationErrors.length === 0;
  };

export const setDocument =
  (document: Partial<Document>): ThunkAction<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const isStrictlyEmbedded = selectors.isStrictlyEmbedded(state);
    dispatch({
      type: Actions.UpdateDocument,
      payload: document,
      meta: {
        embeddedDocumentWasUpdated: isStrictlyEmbedded,
      },
    });
  };
