import * as React from 'react';

function DocumentIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3a1 1 0 00-1 1v14a1 1 0 001 1h10a1 1 0 001-1V8a1 1 0 00-.293-.707l-2-2-2-2A1 1 0 0013 3H7zm1 14V5h3v3a2 2 0 002 2h3v7H8zm7.586-9l-1.293-1.293L13 5.414V8h2.586z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(DocumentIcon);
