/**
 * I created this to resolve an issue where we need Redux selectors to return
 * stable values even when generating random values. Everything in Redux is built on the idea
 * that we replace the objects and arrays as they change instead of mutating them.
 *
 * WeakMap allows you to store values against a particular instance of an
 * object, and if that object get garbage collected, so will whatever got stored
 * in the WeakMap.
 *
 * I have ensured that the Redux store is always initialized with new objects,
 * so this cache will naturally reset between tests and any time any data it
 * depends on has been "changed". Because we don't mutate data, we replace it,
 * and that results in a cache miss on the next call.
 */
export function makeWeakCache<
  Target extends object,
  Value,
  Deps extends unknown[],
>(map: (t: Target, ...args: Deps) => Value) {
  type Meta = {
    deps: Deps;
    value: Value;
  };

  const cache = new WeakMap<Target, Meta[]>();

  return (t: Target, ...args: Deps): Value => {
    const cachedValues = cache.get(t) ?? [];

    let result = cachedValues.find((meta) => {
      return meta.deps.every((value, index) => value === args[index]);
    });

    if (!result) {
      result = {
        deps: args,
        value: map(t, ...args),
      };
      cachedValues.push(result);
      cache.set(t, cachedValues);
    }

    return result.value;
  };
}
