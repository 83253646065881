import { IntegrationNamespaceState, Actions, IntegrationAction } from './types';

export const defaultState: IntegrationNamespaceState = {
  selectedTemplateId: null,
  workflowError: null,
  statusPageEmail: null,
  templateListEmpty: false,
  renderCreateTemplate: false,
  renderCreateOneTimeDoc: false,
};

export function integrationReducer(
  state: IntegrationNamespaceState = defaultState,
  action: IntegrationAction,
) {
  switch (action.type) {
    case Actions.SetSelectedTemplateId:
      return {
        ...state,
        selectedTemplateId: action.payload,
      };
    case Actions.SetWorkflowError:
      return {
        ...state,
        workflowError: action.payload,
      };
    case Actions.SetStatusPageEmail:
      return {
        ...state,
        statusPageEmail: action.payload,
      };
    case Actions.SetTemplateListEmpty:
      return {
        ...state,
        templateListEmpty: action.payload,
      };
    case Actions.SetRenderCreateTemplate:
      return {
        ...state,
        renderCreateTemplate: action.payload,
      };
    case Actions.SetRenderCreateOneTimeDoc:
      return {
        ...state,
        renderCreateOneTimeDoc: action.payload,
      };
    default:
      return state;
  }
}
