import { Field } from 'signer-app/types/editor-types';
import {
  DocumentPreviewNamespaceState,
  Actions,
  DocumentPreviewAction,
} from 'js/sign-components/signer-preview/data/types';

export const defaultState: DocumentPreviewNamespaceState = {
  fields: [],
  rules: [],
  signers: [],
  signerOrderEnabled: false,
  selectedSignerId: undefined,
  lastUpdatedFieldId: undefined,
};

// The return type helps TypeScript to complain in this file if an action
// returns an invalid shape.
export function documentPreviewReducer(
  state: DocumentPreviewNamespaceState = defaultState,
  action: DocumentPreviewAction,
): DocumentPreviewNamespaceState {
  switch (action.type) {
    case Actions.Init:
      return {
        ...action.payload,
        selectedSignerId: undefined,
      };
    case Actions.SetPreviewSigner:
      return {
        ...state,
        selectedSignerId: action.payload,
      };
    case Actions.UpdateField: {
      const fields = state.fields.map((field): Field => {
        if (field.id === action.payload.id) {
          return {
            ...field,
            ...action.payload.updates,
            // This needs a type cast because TS recognizes that we could be
            // passing a Partial<Text> with an ID that updates a Checkbox field.
            // I think without this cast we end up with something like
            // Text & Partial<Checkbox> | Checkbox & Partial<Text> | ...
          } as Field;
        }
        return field;
      });

      const lastUpdatedFieldId = action.payload.id;

      return {
        ...state,
        fields,
        lastUpdatedFieldId,
      };
    }

    default:
      return state;
  }
}
