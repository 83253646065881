import { ThunkAction } from 'hellospa/redux/types';
import {
  DeepIntegrationErrorDescriptor,
  FileErrorDescriptor,
} from 'hellospa/components/prep-and-send/file-uploader/messages';
import { createBannerMessage } from '../../../../components/notification-banner/data/actions';

import { Actions } from 'hellospa/page/prep-and-send/data/types';
import { BannerMessage } from 'hellospa/components/notification-banner/data/types';

export const showError =
  (
    message: FileErrorDescriptor,
    type: BannerMessage['type'],
  ): ThunkAction<void> =>
  async (dispatch) => {
    dispatch(createBannerMessage(message.descriptor, type, message.values));
  };

export const showDeepIntegrationError =
  (payload: DeepIntegrationErrorDescriptor): ThunkAction<void> =>
  async (dispatch) => {
    dispatch({
      type: Actions.SetDeepIntegrationError,
      payload,
    });
  };
