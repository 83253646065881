import * as uuid from 'uuid';
import { ThunkAction, StoreShape } from 'hellospa/redux/types';

import * as selectors from 'hellospa/page/prep-and-send/data/selectors';
import {
  Recipient,
  RecipientTypes,
  RecipientDeliveryTypes,
  Signer,
  FaxRecipient,
  Role,
  Attachment,
} from 'hellospa/page/prep-and-send/data/types/recipient';
import { Omit } from 'js/sign-components/common/ts-utils';
import capture from './capture';
import { Actions } from '../types';

export const validateRecipients = async (
  state: StoreShape,
): Promise<boolean> => {
  const schema = selectors.getRecipientValidationSchema(state);
  const data = {
    recipients: selectors.getRecipientsForRequest(state),
  };
  if (!schema) {
    return true;
  }

  const errors = await capture(schema, data);

  return errors.length === 0;
};

type RecipientUpdates<T extends Recipient> = Partial<
  Omit<T, 'id' | 'type' | 'attachments'>
>;
type AttachmentUpdates = Partial<Omit<Attachment, 'id'>>;

function assertRecipientType<T extends Recipient>(
  state: StoreShape,
  id: Recipient['id'],
  types: RecipientTypes[],
) {
  const current = selectors.getRecipient(state, id) as T | undefined;
  if (!current) {
    throw new Error(`Recipient not found: ${id}`);
  }
  if (!types.includes(current.type)) {
    throw new Error(
      `Recipient type mismatch. Expected ${types.join(', ')}, found ${current.type}`,
    );
  }
}

// Generates new Attachment object, does not persist to Redux
export const generateAttachment = (
  payload: AttachmentUpdates = {},
): Attachment => ({
  id: uuid.v4(),
  name: '',
  instructions: '',
  required: false,
  ...payload,
});

// Generates new Signer object, does not persist to Redux
export const generateSigner = (payload: Partial<Signer> = {}): Signer => ({
  id: uuid.v4(),
  type: RecipientTypes.Signer,
  email: '',
  name: '',
  attachments: [],
  accessCode: '',
  smsAuthNumber: '',
  smsDeliveryMobileNumber: '',
  deliveryType: RecipientDeliveryTypes.Email,
  ...payload,
});

// Generates new Role object, does not persist to Redux
export const generateRole = (payload: Partial<Role> = {}): Role => ({
  id: uuid.v4(),
  type: RecipientTypes.Role,
  name: '',
  attachments: [],
  ...payload,
});

// Generates new FaxRecipient object, does not persist to Redux
export const generateFaxRecipient = (
  payload: Partial<FaxRecipient> = {},
): FaxRecipient => ({
  id: uuid.v4(),
  type: RecipientTypes.Fax,
  fax: '',
  ...payload,
});

export const createAttachment =
  (
    signerId: Recipient['id'],
    updates: AttachmentUpdates = {},
  ): ThunkAction<Attachment> =>
  (dispatch, getState) => {
    assertRecipientType(getState(), signerId, [
      RecipientTypes.Signer,
      RecipientTypes.Role,
    ]);
    const attachment = generateAttachment(updates);
    dispatch({
      type: Actions.CreateAttachment,
      payload: { signerId, attachment },
    });

    return attachment;
  };

export const updateAttachment =
  (
    signerId: Recipient['id'],
    id: Attachment['id'],
    updates: AttachmentUpdates,
  ): ThunkAction<void> =>
  (dispatch, getState) => {
    assertRecipientType(getState(), signerId, [RecipientTypes.Signer]);
    dispatch({
      type: Actions.UpdateAttachment,
      payload: {
        signerId,
        id,
        updates,
      },
    });
  };

export const deleteAttachment =
  (signerId: Recipient['id'], id: Attachment['id']): ThunkAction<void> =>
  (dispatch, getState) => {
    assertRecipientType(getState(), signerId, [
      RecipientTypes.Signer,
      RecipientTypes.Role,
    ]);
    dispatch({ type: Actions.DeleteAttachment, payload: { signerId, id } });
  };

export const createSigner =
  (recipient: Partial<Signer> = {}): ThunkAction<Signer> =>
  (dispatch, getState) => {
    let payload = generateSigner(recipient);
    let id = payload.id;
    if (isNaN(Number(payload.id))) {
      id = String(selectors.getNextRecipientId(getState()));
    }
    payload = {
      ...payload,
      id,
    };
    dispatch({ type: Actions.CreateRecipient, payload });

    return payload;
  };

export const createRole =
  (recipient: Partial<Role> = {}): ThunkAction<Role> =>
  (dispatch, getState) => {
    let payload = generateRole(recipient);
    let id = payload.id;
    if (isNaN(Number(payload.id))) {
      id = String(selectors.getNextRecipientId(getState()));
    }
    payload = {
      ...payload,
      id,
    };
    dispatch({ type: Actions.CreateRecipient, payload });

    return payload;
  };

export const createFaxRecipient =
  (recipient: Partial<FaxRecipient> = {}): ThunkAction<FaxRecipient> =>
  (dispatch, getState) => {
    let payload = generateFaxRecipient(recipient);
    payload = {
      ...payload,
      id: selectors.getNextRecipientId(getState()),
    };
    dispatch({ type: Actions.CreateRecipient, payload });

    return payload;
  };

export const updateSigner =
  <T extends Signer>(
    id: Recipient['id'],
    updates: RecipientUpdates<T>,
  ): ThunkAction<void> =>
  async (dispatch, getState) => {
    assertRecipientType<T>(getState(), id, [RecipientTypes.Signer]);

    dispatch({
      type: Actions.UpdateRecipient,
      payload: {
        id,
        updates,
      },
    });
  };

export const updateRole =
  <T extends Role>(
    id: Recipient['id'],
    updates: RecipientUpdates<T>,
  ): ThunkAction<void> =>
  async (dispatch, getState) => {
    assertRecipientType<T>(getState(), id, [RecipientTypes.Role]);

    dispatch({
      type: Actions.UpdateRecipient,
      payload: {
        id,
        updates,
      },
    });
  };

export const updateFaxRecipient =
  <T extends FaxRecipient>(
    id: Recipient['id'],
    updates: RecipientUpdates<T>,
  ): ThunkAction<void> =>
  async (dispatch, getState) => {
    assertRecipientType<T>(getState(), id, [RecipientTypes.Fax]);

    dispatch({
      type: Actions.UpdateRecipient,
      payload: {
        id,
        updates,
      },
    });
  };

export const deleteRecipient =
  (id: Recipient['id']): ThunkAction<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const recipient = selectors.getRecipient(state, id);

    if (!recipient) {
      return;
    }

    dispatch({
      type: Actions.DeleteRecipient,
      payload: id,
    });
  };

export const resetRecipients =
  (recipients: Recipient[]): ThunkAction<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const isStrictlyEmbedded = selectors.isStrictlyEmbedded(state);
    dispatch({
      type: Actions.ResetRecipients,
      payload: {
        recipients,
      },
      meta: {
        embeddedRecipientsWereUpdated: isStrictlyEmbedded,
      },
    });
  };
