/**
 * Creates an array of unique values that are included in both arrays
 */
export const intersect = <T>(a: Array<T>, b: Array<T>) =>
  a.filter(Set.prototype.has, new Set(b));

export const includes = <T>(a: Array<T>, b: Array<T>) => {
  return intersect(a, b).length > 0;
};

type Resolve<T> = (value: T | PromiseLike<T>) => void;
type Reject = (rejection: any) => void;
export function defer<T>(): [Promise<T>, Resolve<T>, Reject] {
  let resolve: Resolve<T>;
  let reject: Reject;
  const promise: Promise<T> = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return [promise, resolve!, reject!];
}
