import {
  PlanTier,
  HSUserSchema,
  PeriodType,
} from 'js/sign-components/generated/types/HSUserSchema';
import React, { DependencyList } from 'react';

/**
 * HSUser represents how the user object is being used today.
 *
 * @param data
 */
export const verifySchemaCompaibilityA = (data: HSUserSchema): HSUser => data;
export const verifySchemaCompaibilityB = (user: HSUser): HSUserSchema => user;

/**
 * This is the user that gets passed down through prepareSPA
 */

export type HSUser = {
  createdAt: string;
  isFree: boolean;
  emailAddress: string;
  canAccessBulkSendUI: boolean;
  hasTeam: boolean;
  guid: string;
  periodType?: PeriodType;
  // I'm not completely sure if this is an exhaustive list of the possible
  // plans. This type is really about documenting how `user` is already used.
  //

  // https://github.com/HelloFax/HelloFax/blob/ac8eb4a7115c160c3f895215ec300f2a7cef65f4/lib/model/doctrine/BillingPlanQuota.class.php#L28-L40
  plan:
    | 'API Basic'
    | 'Grandfathered'
    | '(Grandfathered 2016)'
    | 'Free'
    | 'Test Mode'
    | 'Free Premium'
    | 'Essentials'
    | 'Business'
    | 'Business 10'
    | 'Web 300k'
    | 'Standard'
    | 'Premium'
    | 'Enterprise'
    | 'Enterprise+';
  planTier: PlanTier;
  settings: {
    firstName: string;
    lastName: string;
  };
  id: number;
  numApiDocsLeft: number;
  numDocsLeft: number;
  name: string;
  numTemplatesLeft: number;
  numFaxPagesLeft: number;
  canAccessAdminConsole: boolean;
  canAccessTemplateConverter: boolean;
  isNewTemplateConverterEnabled?: boolean;
  isOldTemplateConverterEnabled?: boolean;
  referUrl: string;
  isFreeApiAccountEligibleForTemplates?: boolean;

  noTimeout: boolean;
  isFromSelfServeApi: boolean;
  primarySignatureGuid?: string;
  showApiCredits: boolean;
  canCreateEditTemplate: boolean;
  showDelinquencyBanner?: boolean;
  nextBillingDate?: number;
  endDate?: number;
  canManageBilling?: boolean;
  hasFrozenSubscription?: boolean;
  hasAlternativeBilling?: boolean;
  prevPlanName?: string;
  freeTrialEndDate?: number;
  showFreeTrialEndBanner?: boolean;
  marketingOptIn?: boolean;
  isApiUser?: boolean;
  dbxUserId?: string | null;
  canAccessEditAndResendUI?: boolean;
  ccExpiredBeforeRenewal?: boolean;
  ccExpiringBeforeRenewal?: boolean;
  showExpiredCCNotification?: boolean;
  isDropboxManaged?: boolean;
  canAccessSignFaxBridge?: boolean;
  isSandBoxedLeoUser?: boolean;
};

type HSUserUpdater = (user: HSUser) => HSUser;

type InternalUserContext = {
  user: HSUser;
  updateForTesting: (updater: HSUserUpdater) => void;
  resetKey: string;
};

const hsUserContext = React.createContext<InternalUserContext | null>(null);

export const HSUserProvider = ({
  value,
  children,
}: React.PropsWithChildren<{ value: HSUser }>) => {
  const [user, setUser] = React.useState(value);

  const context = React.useMemo(
    (): InternalUserContext => ({
      // This key will cause the components under the provider to unmount and
      // regenerate on changes.  In the webapp the user is passed in at the top of
      // the app and it NEVER changes.  This is a workaround to give us more
      // control over the user in stories From the perspective of any components,
      // the user never changes, because those components will unmount, and then
      // re-mount with a new user.
      resetKey: Math.random().toString(),
      user,
      updateForTesting: (updater) => {
        setUser((user) => {
          const updatedUser = updater(user);
          if (JSON.stringify(updatedUser) !== JSON.stringify(user)) {
            return updatedUser;
          }
          return user;
        });
      },
    }),
    [user],
  );

  return (
    <hsUserContext.Provider key={context.resetKey} value={context}>
      {children}
    </hsUserContext.Provider>
  );
};

export function useCustomStorybookUser(
  updater: HSUserUpdater,
  deps: DependencyList,
): HSUser {
  const context = React.useContext(hsUserContext);
  if (!context) {
    throw new Error(
      'useCustomStorybookUser must be rendered in an HSUserProvider',
    );
  }

  React.useEffect(() => {
    context.updateForTesting(updater);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, ...deps]);

  return context.user;
}

export function useHSUser(): HSUser {
  const context = React.useContext(hsUserContext);

  if (!context) {
    throw new Error('useHsUser must be rendered in an HSUserProvider');
  }

  return context.user;
}
