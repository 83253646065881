import monolithic from 'fela-monolithic';
import prefixer from 'fela-plugin-prefixer';
import fallbackValue from 'fela-plugin-fallback-value';
import { createRenderer as createFelaRenderer } from 'fela';

const isDev = NODE_ENV === 'development';
/**
 * Creates a style renderer with the given options.
 *
 * @options.dev {Boolean} - If `true`, the monolithic renderer will be used.
 * This will render a separate classname for each style rule and allow for
 * easier debugging.  This is used by default in development, but not in
 * production.
 */

export default function createRenderer(options = {}) {
  const rendererOptions = {
    enhancers: [],
    plugins: [prefixer(), fallbackValue()],
  };
  const opts = {
    dev: isDev,
    ...options,
  };

  if (opts.dev) {
    rendererOptions.enhancers.push(
      monolithic({
        prettySelectors: true,
      }),
    );
  }

  return createFelaRenderer(rendererOptions);
}
