/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MOAR_NAMESPACE_KEY } from 'hellospa/redux/namespaces';
import { ApiClientStatus, AsyncThunkConfig } from 'hellospa/redux/types';
import { getHasErrored, getPages, getRecipient, getResult } from './selectors';
import { MoarNamespaceState } from './types';

const tierPages = [5, 10, 25, 50, 100, 200];

export const defaultState: MoarNamespaceState = {
  result: null,
  recipient: '',
  hasErrored: false,
  tier: 0,
  pages: tierPages[0],
  resetKey: Math.random(),
};
type PagesAndRecipient = Pick<MoarNamespaceState, 'pages' | 'recipient'>;

// grant has to be declared before the slice because extraReducers needs to
// access it while the slice is being constructed.
export const grant = createAsyncThunk<
  PagesAndRecipient,
  void,
  AsyncThunkConfig
>('grant', async (_void, thunkAPI) => {
  const { dispatch, getState, extra: getExtra } = thunkAPI;
  const { appActions } = getExtra();

  if (getResult(getState()) != null) {
    dispatch(reset(false));
    await Promise.resolve();
  }

  try {
    const state = getState();
    if (!getHasErrored(state)) {
      throw new Error('NOPE');
    }

    const recipient = getRecipient(state);
    const pages = getPages(state);
    await appActions.moar.grantMoar({ recipient, pages });
    return { pages, recipient };
  } catch (e) {
    throw e;
  }
});

const moarSlice = createSlice({
  name: MOAR_NAMESPACE_KEY,
  initialState: defaultState,
  reducers: {
    reset(state, action: PayloadAction<boolean>) {
      const resetAll = action.payload;
      if (resetAll) {
        return defaultState;
      }

      return {
        ...state,
        result: null,
        resetKey: Math.random(),
      };
    },
    changeRecipient(state, action: PayloadAction<string>) {
      return {
        ...state,
        recipient: action.payload,
      };
    },
    increment(state) {
      const tier = (state.tier + 1 + tierPages.length) % tierPages.length;
      const pages = tierPages[tier];
      return {
        ...state,
        tier,
        pages,
      };
    },
    decrement(state) {
      const tier = (state.tier - 1 + tierPages.length) % tierPages.length;
      const pages = tierPages[tier];
      return {
        ...state,
        tier,
        pages,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(grant.pending, (state) => {
      // Redux toolkit uses Immer internally to allow writing code that looks
      // like it mutates state
      // https://redux-toolkit.js.org/usage/immer-reducers
      state.result = {
        apiStatus: ApiClientStatus.Fetch,
      };
    });

    builder.addCase(grant.rejected, (state, action) => {
      state.hasErrored = true;
      state.result = {
        apiStatus: ApiClientStatus.Error,
        message: action.error.message || '',
      };
    });

    builder.addCase(grant.fulfilled, (state, action) => {
      state.result = {
        apiStatus: ApiClientStatus.Success,
        pages: action.payload.pages,
        recipient: action.payload.recipient,
      };
    });
  },
});

export const { reset, changeRecipient, increment, decrement } =
  moarSlice.actions;

export default moarSlice.reducer;
