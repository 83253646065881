import { createSelector, Selector } from '@reduxjs/toolkit';

import { GenericObject } from 'common/types/generic-object';
import {
  PREP_AND_SEND_NAMESPACE_KEY,
  StoreShape,
} from 'hellospa/redux/namespaces';
import { CSV_EDITOR_KEY, CsvEditorState } from './csv-editor-types';
import { CsvEditorError } from '../types/csv-editor-error';

export class CsvEditorSelectors {
  static getRootState(state: StoreShape): CsvEditorState {
    return state[PREP_AND_SEND_NAMESPACE_KEY][CSV_EDITOR_KEY];
  }

  static getRows = createSelector(
    CsvEditorSelectors.getRootState,
    (state) => state?.rows ?? [],
  );

  static getDataHeaders = createSelector(
    CsvEditorSelectors.getRootState,
    (state) => state.rowHeaders,
  );

  static getRowErrors = createSelector(
    CsvEditorSelectors.getRootState,
    (state) => state?.errors ?? [],
  );

  static getUploadErrors = createSelector(
    CsvEditorSelectors.getRootState,
    (state) => state?.uploadErrors ?? [],
  );

  static getRowMetadata = createSelector(
    CsvEditorSelectors.getRootState,
    (state) => state?.rowMetadata ?? {},
  );

  static getRowGuidsWithErrors = createSelector(
    CsvEditorSelectors.getRowErrors,
    (errors) => errors.map((error) => error.row_guid),
  );

  static getFixedCellsMap = createSelector(
    CsvEditorSelectors.getRowMetadata,
    (rowMetadata) =>
      Object.entries(rowMetadata).reduce(
        (acc: Record<string, Set<string>>, [k, v]) => {
          if (v.fixed.length > 0) {
            acc[k] = new Set(v.fixed);
          }
          return acc;
        },
        {},
      ),
  );

  static getRowGuidsFixedOrWithErrors = createSelector(
    CsvEditorSelectors.getRowGuidsWithErrors,
    CsvEditorSelectors.getFixedCellsMap,
    (errors, fixedCellsMap) =>
      Array.from(
        new Set([...errors, ...Object.keys(fixedCellsMap).map(Number)]),
      ).sort(),
  );

  static getErrorsMap: Selector<
    StoreShape,
    GenericObject<GenericObject<string>>
  > = createSelector(CsvEditorSelectors.getRowErrors, (errorRows) =>
    errorRows.reduce(
      (acc: GenericObject<GenericObject<string>>, error: CsvEditorError) => {
        acc[error.row_guid] = error.errors;
        return acc;
      },
      {},
    ),
  );

  static isSaving = createSelector(
    CsvEditorSelectors.getRowMetadata,
    (rowMetadata) =>
      Object.values(rowMetadata).some((value) => value.saving.length > 0),
  );

  static hasDirtyData = createSelector(
    CsvEditorSelectors.getRowMetadata,
    (rowMetadata) =>
      Object.values(rowMetadata).some(
        (value) => value.dirty.length > 0 && !value.submitted,
      ),
  );

  static hasErrors = createSelector(
    CsvEditorSelectors.getRowErrors,
    (errors) => errors?.length > 0,
  );
}
