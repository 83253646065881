import { TsExampleNamespaceState, Actions, TsExampleAction } from './types';

export const defaultState: TsExampleNamespaceState = {
  count: 0,
};

export function tsExampleReducer(
  state: TsExampleNamespaceState = defaultState,
  action: TsExampleAction,
) {
  switch (action.type) {
    case Actions.Increment:
      return {
        ...state,
        count: state.count + 1,
      };
    case Actions.Decrement:
      return {
        ...state,
        count: state.count - 1,
      };
    default:
      return state;
  }
}
