import { ThunkAction } from 'hellospa/redux/types';
import {
  Actions,
  PrepAndSendAction,
} from 'hellospa/page/prep-and-send/data/types';
import { unreachable } from 'js/sign-components/common/ts-utils';
import { SendRequestResponse } from 'hello-react/web-app-client/namespace/prep-and-send';
import { Meta } from '../types/meta';

const MAX_POLL_COUNT = 50;
const POLL_TIME = NODE_ENV === 'test' ? 1 : 2500;
const delay = () => new Promise((resolve) => setTimeout(resolve, POLL_TIME));

export const setMeta = (payload: Partial<Meta>): PrepAndSendAction => ({
  type: Actions.UpdateMeta,
  payload,
});

export const getTemplateRedirectUrl =
  (guid: string): ThunkAction<Promise<SendRequestResponse | null>> =>
  async (dispatch, getState, getExtra) => {
    const { getCreateTemplateStatus } = getExtra().appActions.prepAndSend;

    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < MAX_POLL_COUNT; i++) {
      const data = await getCreateTemplateStatus(guid);
      switch (data.status) {
        case 'pending':
          break;
        case 'error':
          // TODO: dispatch a banner error message? the response from the backend isn't
          // localized so this needs to show a generic template/link error.
          return null;
        case 'ok':
          return { redirectUrl: data.managePageUrl };
        default:
          unreachable(data);
      }

      await delay();
    }
    /* eslint-enable no-await-in-loop */

    return null;
  };
