import { SharePointNamespaceState, Actions, SharePointAction } from './types';

export const defaultState: SharePointNamespaceState = {
  permissionGranted: false,
  hsConnected: false,
  redirectToContextTokenUrl: false,
  workflowError: null,
};

export function sharePointReducer(
  state: SharePointNamespaceState = defaultState,
  action: SharePointAction,
) {
  switch (action.type) {
    case Actions.SetHSConnected:
      return {
        ...state,
        hsConnected: action.payload,
      };
    case Actions.SetPermissionGranted:
      return {
        ...state,
        permissionGranted: action.payload,
      };
    case Actions.SetRedirectToContextTokenUrl:
      return {
        ...state,
        redirectToContextTokenUrl: action.payload,
      };
    case Actions.SetWorkflowError:
      return {
        ...state,
        workflowError: action.payload,
      };
    default:
      return state;
  }
}
