import { ThunkAction } from 'hellospa/redux/types';
import {
  Actions,
  PrepAndSendAction,
} from 'hellospa/page/prep-and-send/data/types';
import * as selectors from 'hellospa/page/prep-and-send/data/selectors';
import { Settings, UpdateAccountParams } from '../types/settings';

export const setSettings = (payload: Partial<Settings>): PrepAndSendAction => ({
  type: Actions.UpdateSettings,
  payload,
});

export const updateAccount =
  (values: UpdateAccountParams): ThunkAction<void> =>
  async (dispatch, getState, getExtra) => {
    const { updateAccount } = getExtra().appActions.prepAndSend;
    const isHelloFax = selectors.isHelloFax(getState());
    const data = await updateAccount(
      values.firstName,
      values.lastName,
      values.alwaysSendMeACopy,
      values.alwaysSendOthersACopy,
      isHelloFax,
    );

    if (data.success) {
      dispatch(
        setSettings({
          requireSignerInfo: false,
          ...values,
        }),
      );
    }
  };
