/**
 * This can be thought of as the internal "theme" for the UI library.  These
 * properties are not themeable, but keeping them in a config allows us to
 * configure the library internally.
 */
export default {
  colors: {
    muted: '#e6e6e6',
    alert: '#C9252C',
    success: '#A2BF00',
    checked: '#0085AB',
    unchecked: '#909090',
  },
};
