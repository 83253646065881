/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  react/no-string-refs
*/
import React from 'react';
import NotificationHandler from 'signer-app/parts/notifications/notification-handler';
import CondorNoticeBar from 'hellospa/common/layouts/condor/notice-bar';
import * as NoticeTypes from 'hellospa/common/constants/condor/notice-types';
import { useFlashMessages } from 'signer-app/parts/notifications/flash-messages';
import { useIsLastMountedInstanceOf } from 'signer-app/utils/use-is-last-mounted-instance-of';
import createReactClass from 'create-react-class';

const Notifications = createReactClass({
  displayName: 'NotificationHandler',

  getInitialState() {
    this.notification = new NotificationHandler();
    const notifications = this.notification.notifications;

    return {
      notifications,
    };
  },

  componentDidMount() {
    this.notification.removeNotifications();
  },

  removeNotification(nIndex) {
    this.setState((prevState) => ({
      notifications: prevState.notifications.filter((n, i) => i !== nIndex),
    }));
  },

  getCondorNoticeType(notification) {
    switch (notification.data.class_name) {
      case 'success':
        return NoticeTypes.SUCCESS;
      case 'warning':
        return NoticeTypes.WARNING;
      case 'error':
        return NoticeTypes.ERROR;
      default:
        return NoticeTypes.INFO;
    }
  },

  render() {
    const notices = this.state.notifications.map((n, i) => {
      const message = `${n.data.header} ${n.data.text || ''}`;

      const notice = {
        id: i,
        slug: n.data.slug,
        type: this.getCondorNoticeType(n),
        closeAutomatically: n.data.display_time !== 0,
        trackAsHeapEvent: n.data.track_as_heap_event === true,
      };

      if (n.data.messageId) {
        notice.msg = {
          messageId: n.data.messageId,
          values: n.data.messageValues,
        };
      } else if (n.data.very_dangerous_raw_html) {
        notice.html = message;
      } else {
        notice.message = message;
      }

      return notice;
    });

    if (!this.props.activeList) {
      return null;
    }

    return (
      <CondorNoticeBar
        notices={notices}
        onRequestCloseNotice={this.removeNotification}
      />
    );
  },
});

module.exports = function NotificationWrapper(props) {
  const activeList = useIsLastMountedInstanceOf(useFlashMessages);

  return <Notifications {...props} activeList={activeList} />;
};
