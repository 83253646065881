import * as React from 'react';

function InfoCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx="12" cy="12" r="8" fill="#019BCE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.956 7C12.616 7 13.072 7.468 13.072 8.068C13.072 8.68 12.616 9.136 11.956 9.136C11.296 9.136 10.828 8.68 10.828 8.068C10.828 7.468 11.296 7 11.956 7ZM12.096 10.336C12.6483 10.336 13.096 10.7837 13.096 11.336V14.596C13.096 15.0467 13.4613 15.412 13.912 15.412H14.08C14.4379 15.412 14.728 15.7021 14.728 16.06C14.728 16.4179 14.4379 16.708 14.08 16.708H10.048C9.69011 16.708 9.39999 16.4179 9.39999 16.06C9.39999 15.7021 9.69011 15.412 10.048 15.412H10.3C10.797 15.412 11.2 15.0091 11.2 14.512V12.0456C11.2 11.8172 11.0148 11.632 10.7864 11.632C10.558 11.632 10.3728 11.4468 10.3728 11.2184V10.984C10.3728 10.6261 10.6629 10.336 11.0208 10.336H12.096Z"
        fill="#FFFFFF"
      />
    </svg>
  );
}

export default React.memo(InfoCircleIcon);
