import React from 'react';
import classnames from 'classnames';
import styles from './icons.scss';

const SvgCircleSuccess = (props) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    className={classnames(styles.icon, props.className)}
  >
    <rect fill="#24b34a" height="16" rx="8" width="16" x="4" y="4" />
    <path
      d="m8.72294 12.2143c-.38156-.3992-1.01455-.4136-1.41383-.032-.39928.3815-.41364 1.0145-.03207 1.4138l2.95746 3.0947c.4497.4706 1.2219.393 1.5689-.1576l5.0424-7.99987c.2945-.46721.1545-1.08469-.3127-1.37918-.4672-.29448-1.0847-.15446-1.3792.31275l-4.359 6.9156z"
      fill="#fff"
    />
  </svg>
);

export default React.memo(SvgCircleSuccess);
