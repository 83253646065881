import { unreachable } from 'js/sign-components/common/ts-utils';
import {
  Actions,
  NotificationBannerActions,
  NotificationBannerNamespaceState,
} from './types';

const defaultState: NotificationBannerNamespaceState = [];

export function notificationBannerReducer(
  state: NotificationBannerNamespaceState = defaultState,
  action: NotificationBannerActions,
): NotificationBannerNamespaceState {
  switch (action.type) {
    case Actions.CreateBannerMessage: {
      if (action.payload.key) {
        let found = false;
        const nextState = state.map((m) => {
          if (m.key === action.payload.key) {
            found = true;
            return action.payload;
          }
          return m;
        });
        if (found) {
          return nextState;
        }
      }

      return [...state, action.payload];
    }
    case Actions.RemoveBannerMessage: {
      return state.filter((m) => m.guid !== action.payload);
    }
    default:
      unreachable(action);
  }
  return state;
}
