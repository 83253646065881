import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import classnames from 'classnames';

import * as NoticeTypes from 'hellospa/common/constants/condor/notice-types';
import { isSelenium } from 'hellospa/common/utils/driver';
import { trackHeapCustomEvent } from 'js/sign-components/common/heap';

import SvgCircleError from 'hellospa/icons/circle-error';
import SvgCircleInfo from 'hellospa/icons/circle-info';
import SvgCircleSuccess from 'hellospa/icons/circle-success';
import SvgCircleWarning from 'hellospa/icons/circle-warning';

const messages = defineMessages({
  closeNoticeLabel: {
    id: '', // IDs are procedurally generated
    description: 'Aria label for a button which will close the notice bar.',
    defaultMessage: 'Close notice',
  },
});

class Notice extends React.PureComponent {
  state = {
    isFading: false,
  };

  closeTimeout = null;

  closeFadeTimeout = null;

  closeNotice = () => {
    this.props.onCloseNotice();
  };

  onMouseEnter = () => {
    this.stopCloseTimeout();
    this.resetFadingState();
  };

  onMouseLeave = () => {
    this.maybeStartCloseTimeout();
  };

  componentDidMount() {
    this.maybeStartCloseTimeout();
    this.trackAsHeapEvent();
  }

  componentWillUnmount() {
    this.stopCloseTimeout();
  }

  maybeStartCloseTimeout() {
    if (!isSelenium() && this.props.closeAutomatically) {
      this.startCloseTimeout();
    }
  }

  startCloseTimeout() {
    this.closeTimeout = setTimeout(() => {
      this.closeNotice();
    }, 7500);

    this.closeFadeTimeout = setTimeout(() => {
      this.setState({
        isFading: true,
      });
    }, 5000);
  }

  stopCloseTimeout() {
    clearTimeout(this.closeTimeout);
    clearTimeout(this.closeFadeTimeout);
  }

  trackAsHeapEvent() {
    const { trackAsHeapEvent, slug } = this.props;

    if (trackAsHeapEvent && slug) {
      trackHeapCustomEvent('notice_displayed', { slug });
    }
  }

  resetFadingState() {
    this.setState({
      isFading: false,
    });
  }

  renderIcon() {
    switch (this.props.type) {
      case NoticeTypes.SUCCESS:
        return <SvgCircleSuccess />;
      case NoticeTypes.INFO:
        return <SvgCircleInfo />;
      case NoticeTypes.WARNING:
        return <SvgCircleWarning />;
      case NoticeTypes.ERROR:
        return <SvgCircleError />;
      default:
      // No default
    }
  }

  render() {
    const { intl, html, message, type } = this.props;
    const { isFading } = this.state;

    const classes = classnames('condor-notice', {
      'condor-notice--success': type === NoticeTypes.SUCCESS,
      'condor-notice--info': type === NoticeTypes.INFO,
      'condor-notice--warning': type === NoticeTypes.WARNING,
      'condor-notice--error': type === NoticeTypes.ERROR,
      'condor-notice--fade': isFading,
    });

    return (
      <div
        className={classes}
        data-qa-ref="notification-banner"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className="condor-notice__icon">{this.renderIcon()}</div>
        {html ? (
          <p
            className="condor-notice__message"
            data-qa-ref="notification-banner-message"
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        ) : (
          <p
            className="condor-notice__message"
            data-qa-ref="notification-banner-message"
          >
            {message}
          </p>
        )}
        <div
          className="condor-notice__close"
          role="button"
          data-qa-ref="notification-banner-close-btn"
          aria-label={intl.formatMessage(messages.closeNoticeLabel)}
          onClick={this.closeNotice}
        />
      </div>
    );
  }
}

Notice.DefaultProps = {
  closeAutomatically: true,
};

export default injectIntl(Notice);
