import { TsExampleNamespaceState } from 'hellospa/components/ts-example/data/types';
import { MoarNamespaceState } from 'hellospa/page/moar/data/types';
import { PrepAndSendNamespaceState } from 'hellospa/page/prep-and-send/data/types';
import { NotificationBannerNamespaceState } from 'hellospa/components/notification-banner/data/types';
import { DocumentPreviewNamespaceState } from 'js/sign-components/signer-preview/data/types';
import { SharePointNamespaceState } from 'hellospa/components/sharepoint/data/types';
import { TemplateNamespaceState } from 'hellospa/page/templates/template-slice';
import { PagePropsNamespaceState } from 'hellospa/slices/page-props';
import { IntegrationNamespaceState } from 'hellospa/components/integration/data/types';
import { RecentTemplatesNamespaceState } from 'hellospa/components/dashboard/recent-templates/recent-templates-slice';

export const TEMPLATE_NAMESPACE_KEY = 'template';
export const TS_EXAMPLE_NAMESPACE_KEY = 'tsExample';
export const MOAR_NAMESPACE_KEY = 'moar';
export const PREP_AND_SEND_NAMESPACE_KEY = 'prepAndSend';
export const NOTIFICAION_BANNER_NAMESPACE_KEY = 'notificationBanners';
export const DOCUMENT_PREVIEW_NAMESPACE_KEY = 'documentPreview';
export const SHAREPOINT_NAMESPACE_KEY = 'sharepoint';
export const INTEGRATION_NAMESPACE_KEY = 'integration';
export const RECENT_TEMPLATES_NAMESPACE_KEY = 'recentTemplates';

export type StoreShape = {
  pageProps: PagePropsNamespaceState;
  [TEMPLATE_NAMESPACE_KEY]: TemplateNamespaceState;
  [TS_EXAMPLE_NAMESPACE_KEY]: TsExampleNamespaceState;
  [MOAR_NAMESPACE_KEY]: MoarNamespaceState;
  [PREP_AND_SEND_NAMESPACE_KEY]: PrepAndSendNamespaceState;
  [NOTIFICAION_BANNER_NAMESPACE_KEY]: NotificationBannerNamespaceState;
  [DOCUMENT_PREVIEW_NAMESPACE_KEY]: DocumentPreviewNamespaceState;
  [SHAREPOINT_NAMESPACE_KEY]: SharePointNamespaceState;
  [INTEGRATION_NAMESPACE_KEY]: IntegrationNamespaceState;
  [RECENT_TEMPLATES_NAMESPACE_KEY]: RecentTemplatesNamespaceState;
};
