/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  camelcase,
  func-names
*/
import React from 'react';
import ReactDOM from 'react-dom';
import Button from 'common/components/button';
import Tooltip from 'common/components/tooltip';

// jQuery
// fancybox

function displayTutorial(isEnterprise) {
  const onboardingCtaOnClick = function () {
    $('#message-bar').toggle('blind');
    $('body').animate({ 'padding-top': '0px' }, 250);
    $('#onboarding-overlay').fadeOut(250);
    $('#emailTooltip').hide();
    $('#templatesTooltip').hide();
    $('div.m-app-leftmenu--templates').show();
    $('#documentsTooltip').hide();
    $('div.m-app-leftmenu--documents').show();
    $('#manageteamTooltip').hide();
    $('div.m-app-leftmenu--team').show();
  };

  // Display overlay and message bar with CTA button
  const overlay =
    '<div id="onboarding-overlay" style="background-color: #000;top: 0; bottom: 0; left: 0; right: 0; position: fixed;opacity: .6;z-index: 0;"></div>';
  let message_bar =
    '<div id="message-bar"><p><strong>Welcome!</strong> To start signing and sending documents quickly, please take a look at the tooltips below. <span id="onboarding-cta-button-container"></span></p></div>';
  let onboardingButtonTitle = "I'm ready to get started";

  if (isEnterprise) {
    message_bar =
      '<div id="message-bar"><p>Our sales team will be in touch shortly. In the meantime you can start exploring our amazing features without charge.<span id="onboarding-cta-button-container"></span></p></div>';
    onboardingButtonTitle = 'SHOW ME MORE';
  }

  $('body').prepend(message_bar).append(overlay);

  ReactDOM.render(
    React.createElement(Button, {
      buttonText: onboardingButtonTitle,
      buttonColor: 'grasshopper',
      buttonClass: 'm-button--onboarding-cta',
      onClick: onboardingCtaOnClick,
    }),
    document.getElementById('onboarding-cta-button-container'),
  );

  const height = $('#message-bar').show().height();
  $('body').css('padding-top', `${height}px`);
  $('#onboarding-overlay').click(onboardingCtaOnClick);

  // Helper function to render a tooltip, along with
  // display function during resize
  const displayTooltip = function (id, tooltipText) {
    const tooltipId = `${id}Tooltip`;
    const tooltipContainer = $(
      `<div id="${tooltipId}" class="m-tooltip--container"></div>`,
    );
    $('body').append(tooltipContainer);

    ReactDOM.render(
      React.createElement(Tooltip, {
        content: tooltipText,
        text: '',
        isToggled: true,
        isToggleable: false,
        arrowPosition: 'left',
        classes: 'm-tooltip--onboarding',
      }),
      document.getElementById(tooltipId),
    );

    const tooltipSelector = `div#${tooltipId}`;
    const selector = `div#${id}`;
    const tooltipTop =
      $(selector).offset().top - $(tooltipSelector).offset().top;

    // Set up repositioning if window is resized
    const repositionTooltip = function () {
      const tooltipLeft =
        $(selector).offset().left + $(`${selector} span`).width();
      $(tooltipSelector).css({
        top: `${tooltipTop}px`,
        left: `${tooltipLeft}px`,
        position: 'absolute',
        'z-index': 9999999,
      });
    };
    repositionTooltip();
    $(window).resize(() => {
      repositionTooltip();
    });
  };

  displayTooltip('templates', 'Set up and save frequently used documents');
  displayTooltip('documents', "Access anything you've signed or sent");
  displayTooltip('manageteam', 'Add/remove team members');

  // Tooltip for Email
  const tooltipContainer = $(
    '<div id="emailTooltip" class="m-tooltip--container"></div>',
  );
  $('body').append(tooltipContainer);
  ReactDOM.render(
    React.createElement(Tooltip, {
      content: 'Create a signature, edit settings and check credits',
      text: '',
      isToggled: true,
      isToggleable: false,
      arrowPosition: 'top',
      classes: 'm-tooltip--onboarding',
    }),
    document.getElementById('emailTooltip'),
  );
  $('.m-tooltip--bubble', tooltipContainer).css('width', 180);
  const emailEl = $('.m-app-topbar--menu--title-email');
  const tooltipTop = emailEl.offset().top + emailEl.height();
  const positionTooltip = function () {
    const tooltipLeft = emailEl.offset().left;
    $('div#emailTooltip').css({
      top: `${tooltipTop}px`,
      left: `${tooltipLeft}px`,
      position: 'absolute',
      'z-index': 9999999,
    });
  };
  positionTooltip();
  $(window).resize(() => {
    positionTooltip();
  });
}

/**
 * Runs any legacy javascript that needs to happen at boot time.  This will
 * eventually either be replaced or removed but it needs to be preserved for
 * now.
 */
export default function runLegacyJs(initialState) {
  const { showTutorial, enterpriseTutorial, collectInfoForm } = initialState;

  // This is user data originally created by the "global_js_block" from the
  // in-app template.  It's now passed as part of the initial state, but we
  // still need to start up the session timer in hellofax.js by setting the
  // user data.  To avoid having to refactor anything within hellofax.js, I'm
  // keeping all keys exactly the same as from the inApp template.
  const legacyUserData = {
    is_logged_in: true, // Always true in this context
    no_timeout: initialState.user.noTimeout,
    has_remember_me: initialState.user.hasRememberMe,
    email_address: initialState.user.emailAddress,
    has_paid_subscription: initialState.user.hasPaidSubscription,
    can_email_copy_fax: initialState.user.canEmailCopyFax,
    can_multi_fax: initialState.user.canMultiFax,
    timezone_offset: initialState.user.timezoneOffset,
    site_code: initialState.siteCode,
    base_url: initialState.baseUrl,
  };
  hellofaxJS.setUserData(legacyUserData);

  $(() => {
    if (showTutorial && !collectInfoForm) {
      displayTutorial(enterpriseTutorial);
    }
  });
}
