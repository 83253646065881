export interface EncodeCsvDataAsDataUrlOptions {
  delimiter: string;
  enclosing: string;
}

export function encodeCsv<T extends any = string>(
  data: T[] = [],
  { delimiter, enclosing }: EncodeCsvDataAsDataUrlOptions = {
    delimiter: ',',
    enclosing: '"',
  },
): string {
  return data
    .map((datum) => `${enclosing}${datum}${enclosing}`)
    .join(delimiter);
}

export function encodeCsvAsDataUrl(
  lines: [string[], string[]] = [[], []],
  { delimiter, enclosing }: EncodeCsvDataAsDataUrlOptions = {
    delimiter: ',',
    enclosing: '"',
  },
): string {
  return `data:text/plain;charset=utf-8,${encodeURIComponent(
    lines.map((line) => encodeCsv(line, { delimiter, enclosing })).join('\n'),
  )}`;
}
