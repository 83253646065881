import {
  Actions,
  PrepAndSendAction,
} from 'hellospa/page/prep-and-send/data/types';
import { User } from '../types/user';

export const setUser = (user: Partial<User>): PrepAndSendAction => ({
  type: Actions.UpdateUser,
  payload: user,
});
