import * as uuid from 'uuid';
import { PAP_Upload_SignDocument } from 'pap-events/sign/upload_sign_document';
import { ThunkAction, StoreShape } from 'hellospa/redux/types';
import * as selectors from 'hellospa/page/prep-and-send/data/selectors';
import {
  Actions,
  PrepAndSendAction,
} from 'hellospa/page/prep-and-send/data/types';
import {
  initRefresh,
  createSigner,
  resetRecipients,
  createAttachment,
  setWorkflow,
  pollEmbedded,
  setDocument,
  createBannerMessage,
} from 'hellospa/page/prep-and-send/data/actions';
import { notEmpty, unreachable } from 'js/sign-components/common/ts-utils';
import capture from './capture';
import delay from 'hellospa/common/utils/delay';
import { trackHeapCustomEvent } from 'js/sign-components/common/heap';
import { logPAPEvent } from 'js/sign-components/common/product-analytics-pipeline';
import {
  templateResponse,
  templateErrorResponse,
} from 'hello-react/web-app-client/namespace/prep-and-send';
import { createCC } from './cc';
import * as crypto from 'crypto';
import {
  UserFile,
  UserFileTypes,
  FileReorderResponse,
  FilePollResponseStatus,
  FileUploadResponse,
  MergeField,
} from '../types/file';
import { Recipient, RecipientTypes } from '../types/recipient';
import { getWorkflowConfiguration } from '../types/workflow';
import { defineMessages } from 'react-intl';
import { NotificationBannerType } from 'hellospa/components/notification-banner/data/types';

// Larger docx files take a while to convert, so increase the MAX POLL COUNT
// e.g. in prod: testing with a 160 page docx file polled around 135 times,
// so keeping it high enough for potentially bigger files, and until we
// change how we do this, increasing this count is the only solution
const MAX_POLL_COUNT = 300;
const POLL_TIME = NODE_ENV === 'test' ? 1 : 2500;

const messages = defineMessages({
  backendValidationError: {
    id: '',
    description:
      'text for displaying a validation error identified by the backend',
    defaultMessage: 'The following error was encountered: {error}',
  },
});

export const validateUserFiles = async (
  state: StoreShape,
): Promise<boolean> => {
  const schema = selectors.getUserFileValidationSchema();
  const data = {
    files: selectors.getFiles(state),
  };
  if (!schema) {
    return true;
  }

  const errors = await capture(schema, data);

  return errors.length === 0;
};

export const createFile = (file: UserFile): PrepAndSendAction => ({
  type: Actions.CreateFile,
  payload: file,
});

export const updateFile = (file: UserFile): PrepAndSendAction => ({
  type: Actions.UpdateFile,
  payload: file,
});

export const deleteFile =
  (id: UserFile['rootSnapshotGuid']): ThunkAction<void> =>
  async (dispatch, getState, getExtra) => {
    const { deleteFile } = getExtra().appActions.prepAndSend;
    const transmissionGroupGuid =
      selectors.getTransmissionGroupGuid(getState());
    const file = selectors.getFile(getState(), id);

    if (!file) {
      return;
    }

    // this shouldn't be deleting the template file because the action
    // will try to delete the root snapshot, we don't want that
    if (
      file.type === UserFileTypes.Upload ||
      file.type === UserFileTypes.External
    ) {
      dispatch({
        type: Actions.DeleteFile,
        payload: id,
      });
      // TODO: clean up when DEV-12786 is done
      dispatch({
        type: Actions.UpdateNextClickTracker,
        payload: Date.now(),
      });
      await deleteFile(transmissionGroupGuid, file);
    }
  };

export const deleteTemplateFiles =
  (templateGuid: UserFile['templateGuid']): ThunkAction<void> =>
  async (dispatch, getState, getExtra) => {
    const { deleteFiles } = getExtra().appActions.prepAndSend;
    const transmissionGroupGuid =
      selectors.getTransmissionGroupGuid(getState());

    if (!templateGuid) {
      return;
    }

    const templateFiles = selectors.getFilesByTemplateGuid(
      getState(),
      templateGuid,
    );
    // type casting since there should be at least 1 file
    const fileGuids = templateFiles.map((file) => file.guid) as string[];
    const uniqueTokenMap: { [key: string]: string } = {};
    templateFiles.forEach((file) => {
      uniqueTokenMap[file.rootSnapshotGuid] =
        file.rootSnapshotGuid + transmissionGroupGuid;
    });
    const data = await deleteFiles(
      fileGuids,
      transmissionGroupGuid,
      templateGuid,
      uniqueTokenMap,
    );

    if (data.success) {
      // loop through all the template files & delete them
      templateFiles.forEach((file) =>
        dispatch({
          type: Actions.DeleteFile,
          payload: file.rootSnapshotGuid,
        }),
      );

      // TODO: clean up when DEV-12786 is done
      dispatch({
        type: Actions.UpdateNextClickTracker,
        payload: Date.now(),
      });

      dispatch({
        type: Actions.DeleteTemplate,
        payload: templateGuid,
      });

      // this is to avoid initRefresh clearing out all the files when deleting template
      // files which kind of defeats the point of the story in SendTemplateReorder
      // in other cases, this will retrieve the updated data, such as the fixed
      // signer role map & the attachments from the backend, if, we don't get
      // it here, we have to update the recipient data when we call save()
      if (!IS_STORYBOOK) {
        await dispatch(initRefresh());
      }
    }
  };

export const reorderFiles =
  (): ThunkAction<Promise<FileReorderResponse>> =>
  async (dispatch, getState, getExtra) => {
    const { reorderFiles } = getExtra().appActions.prepAndSend;
    const state = getState();
    const transmissionGroupGuid = selectors.getTransmissionGroupGuid(state);
    const files = selectors.getFilesKeyed(state);

    // return early if any of the files haven't yet converted
    if (
      Object.values(files)
        .filter(notEmpty)
        .some((file) => file.status !== FilePollResponseStatus.Ok)
    ) {
      return { success: false };
    }

    const data = await reorderFiles(transmissionGroupGuid, files);

    return data;
  };

export const reorderFile =
  (
    id: UserFile['rootSnapshotGuid'],
    oldOrder: number,
    newOrder: number,
  ): ThunkAction<void> =>
  async (dispatch, _getState, _getExtra) => {
    dispatch({
      type: Actions.ReorderFile,
      payload: {
        id,
        oldOrder,
        newOrder,
      },
    });

    const data = await dispatch(reorderFiles());

    // if unsuccessful, revert the orders
    if (!data.success) {
      dispatch({
        type: Actions.ReorderFile,
        payload: {
          id,
          oldOrder: newOrder,
          newOrder: oldOrder,
        },
      });
    }

    // TODO: Clean up when DEV-12786 is done
    dispatch(
      dispatch({
        type: Actions.UpdateNextClickTracker,
        payload: Date.now(),
      }),
    );
  };

export const pollFileStatus =
  (id: UserFile['rootSnapshotGuid']): ThunkAction<Promise<null | UserFile>> =>
  async (dispatch, getState, getExtra) => {
    const { pollFile } = getExtra().appActions.prepAndSend;
    const transmissionGroupGuid =
      selectors.getTransmissionGroupGuid(getState());
    const isResend = selectors.isResend(getState());
    const requestType = selectors.getRequestType(getState());
    const clientId = selectors.getClientId(getState());
    const user = selectors.getUser(getState());
    const signatureActionFlow = selectors.getPAPSignatureActionFlow(getState());
    const actionSurface = selectors.getPAPActionSurface(getState());

    // Error if file doesn't exist in state
    const file = selectors.getFile(getState(), id);
    if (!file) {
      throw new Error(`File not found: ${id}`);
    }

    // Making a unique token that will allow the frontend to call attachment/conversionStatus
    // multiple times without changing the overlay data
    const uniqueToken = file.rootSnapshotGuid + transmissionGroupGuid;
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < MAX_POLL_COUNT; i++) {
      // User may have removed the file before it was finished processing
      if (!selectors.getFile(getState(), id)) {
        break;
      }

      let data = await pollFile(
        transmissionGroupGuid,
        file,
        requestType,
        uniqueToken,
        clientId,
        isResend,
      );

      if (data.status === FilePollResponseStatus.Error404) {
        // Retry once synce the BE might have timeout due to mutex lock.
        data = await pollFile(
          transmissionGroupGuid,
          file,
          requestType,
          uniqueToken,
          clientId,
          isResend,
        );
      }

      const storedFile = selectors.getFile(getState(), id)!;
      switch (data.status) {
        case FilePollResponseStatus.Ok:
          await dispatch(
            updateFile({
              ...file,
              guid: data.guid,
              tsmGroupGuid: data.tsmGroupGuid,
              status: data.status,
              pageCount: data.pageCount,
              pwRequired: false,
              documentGuid: data.documentGuid,
              replaceData: data.replaceData,
            }),
          );

          if (data.replaceData) {
            const id = data.replaceData.replaceSnapshotGuid;
            const fileToReplace = selectors
              .getFiles(getState())
              .find(
                (f) =>
                  f.guid === id && f.rootSnapshotGuid !== file.rootSnapshotGuid,
              );
            if (fileToReplace) {
              dispatch({
                type: Actions.DeleteFile,
                payload: fileToReplace.rootSnapshotGuid,
              });
            }
          }

          logPAPEvent(
            PAP_Upload_SignDocument({
              actionSurface,
              signatureActionFlow,
              eventState: 'success',
            }),
            user.dbxUserId,
            user.id,
          );

          // if it's converted, reorder the snapshots
          await dispatch(reorderFiles());

          return selectors.getFile(getState(), id) ?? null;
        case FilePollResponseStatus.Converting:
          if (
            storedFile != null &&
            (storedFile.progress !== data.progress ||
              storedFile.total !== data.total)
          ) {
            dispatch({
              type: Actions.ConvertFile,
              payload: {
                ...file,
                progress: data.progress,
                total: data.total,
              },
            });
          }
          break;
        case FilePollResponseStatus.Deleted:
          dispatch({
            type: Actions.DeleteFile,
            payload: id,
          });
          return null;
        case FilePollResponseStatus.PasswordRequired:
        case FilePollResponseStatus.TooManyPages:
        case FilePollResponseStatus.Error:
        case FilePollResponseStatus.Error404:
        case FilePollResponseStatus.Downloading:
        case FilePollResponseStatus.FileDownloaded:
        case FilePollResponseStatus.DownloadQueued:
        case FilePollResponseStatus.FileTooLarge:
        case FilePollResponseStatus.RetrieveError:
        case FilePollResponseStatus.BadAuth:
        case FilePollResponseStatus.BadRequest: {
          // defaulting to a banner error message if it reaches here for some reason
          // because these statuses aren't applicable for regular pollFile
          dispatch(
            updateFile({
              ...file,
              status: data.status,
            }),
          );

          logPAPEvent(
            PAP_Upload_SignDocument({
              actionSurface,
              signatureActionFlow,
              eventState: 'failed',
            }),
            user.dbxUserId,
            user.id,
          );

          return null;
        }
        default:
          unreachable(data);
      }

      await delay(POLL_TIME, 1000);
    }
    /* eslint-enable no-await-in-loop */

    return null;
  };

export const setFilePassword =
  (
    password: string,
    rootSnapshotGuid: UserFile['rootSnapshotGuid'],
  ): ThunkAction<Promise<boolean>> =>
  async (dispatch, getState, getExtra) => {
    const { setFilePassword } = getExtra().appActions.prepAndSend;
    const { success } = await setFilePassword(rootSnapshotGuid, password);
    const file = selectors.getFile(getState(), rootSnapshotGuid);
    if (file) {
      dispatch(
        updateFile({
          ...file,
          pwRequired: !success,
        }),
      );
    }
    return success;
  };

export const templateFile =
  (file: UserFile): ThunkAction<void> =>
  async (dispatch) => {
    dispatch(
      createFile({
        name: file.name,
        type: UserFileTypes.Template,
        order: file.order,
        fields: file.fields,
        templateGuid: file.templateGuid,
        rootSnapshotGuid: file.rootSnapshotGuid,
        status: FilePollResponseStatus.Converting,
        pwRequired: file.pwRequired,
      }),
    );

    await dispatch(pollFileStatus(file.rootSnapshotGuid));
  };

export const pollExternalFile =
  (cacheKey: string): ThunkAction<void> =>
  async (dispatch, getState, getExtra) => {
    const { externalFileProgress } = getExtra().appActions.prepAndSend;
    const transmissionGroupGuid =
      selectors.getTransmissionGroupGuid(getState());

    // get the temporary file from state
    const tempFile = selectors.getFile(getState(), cacheKey)!;

    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < MAX_POLL_COUNT; i++) {
      const data = await externalFileProgress(transmissionGroupGuid, cacheKey);

      switch (data.status) {
        case FilePollResponseStatus.FileDownloaded: {
          // delete temporary file
          await dispatch({
            type: Actions.DeleteFile,
            payload: cacheKey,
          });

          const { name, rootSnapshotGuid } = data;

          dispatch(
            createFile({
              name,
              type: UserFileTypes.External,
              order: tempFile.order,
              fields: [],
              rootSnapshotGuid,
              status: FilePollResponseStatus.Converting,
              pwRequired: false,
            }),
          );

          await dispatch(pollFileStatus(rootSnapshotGuid));
          return;
        }
        case FilePollResponseStatus.Downloading:
        case FilePollResponseStatus.DownloadQueued:
          break;
        case FilePollResponseStatus.FileTooLarge:
        case FilePollResponseStatus.RetrieveError:
        case FilePollResponseStatus.BadRequest: {
          // delete temporary file
          dispatch({
            type: Actions.DeleteFile,
            payload: cacheKey,
          });
          return;
        }
        case FilePollResponseStatus.BadAuth: {
          // delete temporary file
          dispatch({
            type: Actions.DeleteFile,
            payload: cacheKey,
          });
          return;
        }
        case FilePollResponseStatus.Converting:
        case FilePollResponseStatus.Ok:
        case FilePollResponseStatus.Deleted:
        case FilePollResponseStatus.PasswordRequired:
        case FilePollResponseStatus.TooManyPages:
        case FilePollResponseStatus.Error404:
        case FilePollResponseStatus.Error: {
          return;
        }
        default:
          unreachable(data);
      }

      await delay(POLL_TIME, 1000);
    }
    /* eslint-enable no-await-in-loop */
  };

const addTemporaryFile =
  (
    fileName: string,
    temporaryFileId: string,
    isExternalFile: boolean = false,
  ): ThunkAction<void> =>
  async (dispatch, getState) => {
    dispatch(
      createFile({
        name: fileName,
        type: isExternalFile ? UserFileTypes.External : UserFileTypes.Upload,
        rootSnapshotGuid: temporaryFileId, // creating a temporary file to show progress
        order: selectors.getNextFileOrder(getState()),
        fields: [],
        status: FilePollResponseStatus.Converting,
        pwRequired: false,
      }),
    );

    // TODO: Clean up when DEV-12786 is done
    dispatch(
      dispatch({
        type: Actions.UpdateNextClickTracker,
        payload: Date.now(),
      }),
    );

    if (isExternalFile) {
      await dispatch(pollExternalFile(temporaryFileId));
    }
  };

export const externalFile =
  (
    serviceType: string,
    fileReference: string,
    fileName: string,
  ): ThunkAction<void> =>
  async (dispatch, getState, getExtra) => {
    const { externalFileDownload } = getExtra().appActions.prepAndSend;
    const guid = selectors.getTransmissionGroupGuid(getState());
    const files = selectors.getFiles(getState());
    const externalFiles = files.filter((d) => d.type === 'External');
    const externalFileCounter = externalFiles.length + 1;
    const rawToken = `${guid}_${externalFileCounter}`;
    const token = crypto.createHash('md5').update(rawToken).digest('hex');

    const downloadData = await externalFileDownload(
      serviceType,
      fileReference,
      fileName,
      token,
      guid,
    );

    await dispatch(addTemporaryFile(fileName, downloadData.cacheKey, true));
  };

export const handleFileResponse =
  (
    file: FileUploadResponse,
    temporaryFileId?: string,
  ): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const isEmbedded = selectors.isEmbedded(getState());
    const isDeepIntegration = selectors.isDeepIntegration(getState());
    const isSendingTemplate = selectors.isSendingTemplate(getState());
    const isEditTemplate = selectors.isEmbeddedEditingTemplate(getState());
    const isHubSpotV2Integration = selectors.isHubSpotV2Integration(getState());

    // API response includes pending file from external service (Dropbox, Google Drive, Box, etc)
    if (file.externalServiceType && file.externalFileCacheKey) {
      await dispatch(
        addTemporaryFile(file.name, file.externalFileCacheKey, true),
      );
      return;
    }

    // if a temporary file ID is passed, it's most likely from the upload
    // so it can be deleted safely, since the following will create
    // the actual file from the response from the backend
    let tempFile;
    if (temporaryFileId != null) {
      tempFile = selectors.getFile(getState(), temporaryFileId);
      await dispatch({
        type: Actions.DeleteFile,
        payload: temporaryFileId,
      });
    }

    const fileType =
      file.type === 'External' ? UserFileTypes.External : UserFileTypes.Upload;
    dispatch(
      createFile({
        name: file.name,
        guid: file.guid || undefined,
        type: file.templateGuid ? UserFileTypes.Template : fileType,
        rootSnapshotGuid: file.rootSnapshotGuid,
        draftSnapshotGuid: file.templateGuid
          ? undefined
          : file.draftSnapshotGuid,
        // get original order from the temporary file if available
        order:
          tempFile != null
            ? tempFile.order
            : selectors.getNextFileOrder(getState()),
        fields: file.fields || [],
        status: FilePollResponseStatus.Converting,
        // data.pwRequired is optional, but payload.pwRequired isn't.
        pwRequired: file.pwRequired === true,
        templateGuid: file.templateGuid,
        documentGuid: file.documentGuid,
      }),
    );

    // Deep integration has it's own polling script for DI signature-request flow,
    // but not for the embedded-template flow.
    if (isDeepIntegration && !isSendingTemplate && !isEditTemplate) {
      return;
    }

    // poll file regularly if not an embedded request, embedded has it's own polling
    // endpoint where we just check for conversion status of all files at once and
    // doesn't check for some permissions which may or may not be applicable
    if (!isEmbedded || isHubSpotV2Integration) {
      await dispatch(pollFileStatus(file.rootSnapshotGuid));
      return;
    }
    await dispatch(pollEmbedded());
  };

const MB = 1000000;
export const uploadFile =
  (file: File): ThunkAction<Promise<void>> =>
  async (dispatch, getState, getExtra) => {
    const { uploadFile } = getExtra().appActions.prepAndSend;
    const transmissionGroupGuid =
      selectors.getTransmissionGroupGuid(getState());
    const isTemplate = selectors.isTemplateRequest(getState());
    const user = selectors.getUser(getState());
    const signatureActionFlow = selectors.getPAPSignatureActionFlow(getState());
    const actionSurface = selectors.getPAPActionSurface(getState());

    if (isTemplate) {
      trackHeapCustomEvent('template_upload_file');
    } else {
      trackHeapCustomEvent('sign_or_send_upload_file');
    }

    logPAPEvent(
      PAP_Upload_SignDocument({
        actionSurface,
        signatureActionFlow,
        eventState: 'start',
      }),
      user.dbxUserId,
      user.id,
    );

    // when uploading a file, we want to show the file right away, so the user
    // sees that a file is being uploaded, so we generate random UUID to
    // attach it to the file, we will overwrite it along the way
    const temporaryFileId = uuid.v4();
    await dispatch(addTemporaryFile(file.name, temporaryFileId));

    if (file.size > 40 * MB) {
      const file = selectors.getFile(getState(), temporaryFileId);
      if (!file) {
        return;
      }
      dispatch(
        updateFile({
          ...file,
          status: FilePollResponseStatus.FileTooLarge,
        }),
      );
      return;
    }

    const data = await uploadFile(transmissionGroupGuid, file);

    if (typeof data === 'string') {
      const file = selectors.getFile(getState(), temporaryFileId);
      if (!file) {
        return;
      }
      if (data === 'TooManyPages') {
        dispatch(
          updateFile({
            ...file,
            status: FilePollResponseStatus.TooManyPages,
          }),
        );
      } else if (data === 'unknown') {
        dispatch(
          updateFile({
            ...file,
            status: FilePollResponseStatus.Error,
          }),
        );
      } else {
        unreachable(data);
      }
    } else {
      return dispatch(handleFileResponse(data, temporaryFileId));
    }
  };

export const useTemplate =
  (templateId: string, isBulkSend: boolean): ThunkAction<Promise<void>> =>
  async (dispatch, getState, getExtra) => {
    const { appActions, featureFlags } = getExtra();

    const transmissionGroupGuid =
      selectors.getTransmissionGroupGuid(getState());
    const response = await appActions.prepAndSend.getTemplate(
      transmissionGroupGuid,
      templateId,
      isBulkSend,
    );
    const useNewEditorPage = Boolean(
      featureFlags.sign_core_2024_06_12_new_editor_page,
    );

    if ('error' in response) {
      await templateErrorResponse.validate(response);
      // dispatch a banner error message
      dispatch(
        createBannerMessage(
          messages.backendValidationError,
          NotificationBannerType.Err,
          { error: response.error },
        ),
      );
      return;
    }
    const data = await templateResponse.validate(response);

    await dispatch({
      type: Actions.SetFlags,
      payload: {
        requestType: data.requestType,
      },
    });

    await dispatch({
      type: Actions.UpdateSettings,
      payload: {
        recipientReassignment: data.recipientReassignment,
        recipientOrder: data.recipientOrder,
      },
    });

    const savedTitle = selectors.getDocument(getState()).title;
    const title = savedTitle || data.document.title;
    let message = selectors.getDocument(getState()).message;
    // update the document title & message if available for template
    // if multiple templates, honor title/message of first added template
    if (!savedTitle && !message) {
      message = data.document.message;
    }

    await dispatch(
      setDocument({
        ...data.document,
        title,
        message,
      }),
    );
    // when using a template, we need to make sure the workflow is changed to template if it's
    // not Bulk Send because the file conversion will need to know the correct form type
    if (!isBulkSend) {
      await dispatch(
        setWorkflow(
          await getWorkflowConfiguration('SignatureRequestTemplate', {
            useNewEditorPage,
          }),
        ),
      );
    }

    for (let i = 0; i < data.files.length; i++) {
      const file = data.files[i];
      dispatch(handleFileResponse(file));
    }

    data.ccs.forEach((cc) => {
      dispatch(createCC(cc));
    });

    // clear recipients because the backend will pass an updated role map
    dispatch(resetRecipients([]));
    data.recipients.forEach((recipient: Recipient) => {
      switch (recipient.type) {
        case RecipientTypes.Role:
          dispatch(
            createSigner({
              id: recipient.id,
              role: recipient,
            }),
          );

          recipient.attachments.forEach((attachment) =>
            dispatch(createAttachment(recipient.id, attachment)),
          );
          return;
        default:
          throw new Error(`Unexpected recipient type ${recipient.type}`);
      }
    });

    dispatch({
      type: Actions.UseTemplate,
      payload: data,
    });

    // TODO: clean up when DEV-12786 is done
    dispatch({
      type: Actions.UpdateNextClickTracker,
      payload: Date.now(),
    });
  };

export const updateField = (mergeField: MergeField): PrepAndSendAction => ({
  type: Actions.UpdateField,
  payload: mergeField,
});
