import * as React from 'react';

function File(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <mask
        id="path-3-outside-1"
        maskUnits="userSpaceOnUse"
        x="2.5"
        y="3"
        width="18"
        height="16"
        fill="black"
      >
        <rect fill="white" x="2.5" y="3" width="18" height="16" />
        <path d="M17.75 18H5.75C5.15326 18 4.58097 17.763 4.15901 17.341C3.73705 16.919 3.5 16.3468 3.5 15.75V4.00001H8.073C8.49112 3.99859 8.90128 4.11434 9.25698 4.33412C9.61268 4.5539 9.8997 4.86894 10.0855 5.24351L10.4635 6.00001H20V15.75C20 16.0455 19.9418 16.3381 19.8287 16.6111C19.7157 16.884 19.5499 17.1321 19.341 17.341C19.1321 17.5499 18.884 17.7157 18.611 17.8287C18.3381 17.9418 18.0455 18 17.75 18ZM5 5.50001V15.75C5 15.9489 5.07902 16.1397 5.21967 16.2803C5.36032 16.421 5.55109 16.5 5.75 16.5H17.75C17.9489 16.5 18.1397 16.421 18.2803 16.2803C18.421 16.1397 18.5 15.9489 18.5 15.75V7.50001H9.5365L8.744 5.91451C8.68215 5.78956 8.58647 5.68446 8.46785 5.61119C8.34923 5.53791 8.21243 5.49939 8.073 5.50001H5Z" />
      </mask>
      <path
        d="M17.75 18H5.75C5.15326 18 4.58097 17.763 4.15901 17.341C3.73705 16.919 3.5 16.3468 3.5 15.75V4.00001H8.073C8.49112 3.99859 8.90128 4.11434 9.25698 4.33412C9.61268 4.5539 9.8997 4.86894 10.0855 5.24351L10.4635 6.00001H20V15.75C20 16.0455 19.9418 16.3381 19.8287 16.6111C19.7157 16.884 19.5499 17.1321 19.341 17.341C19.1321 17.5499 18.884 17.7157 18.611 17.8287C18.3381 17.9418 18.0455 18 17.75 18ZM5 5.50001V15.75C5 15.9489 5.07902 16.1397 5.21967 16.2803C5.36032 16.421 5.55109 16.5 5.75 16.5H17.75C17.9489 16.5 18.1397 16.421 18.2803 16.2803C18.421 16.1397 18.5 15.9489 18.5 15.75V7.50001H9.5365L8.744 5.91451C8.68215 5.78956 8.58647 5.68446 8.46785 5.61119C8.34923 5.53791 8.21243 5.49939 8.073 5.50001H5Z"
        fill="currentColor"
      />
      <path
        d="M17.75 18H5.75C5.15326 18 4.58097 17.763 4.15901 17.341C3.73705 16.919 3.5 16.3468 3.5 15.75V4.00001H8.073C8.49112 3.99859 8.90128 4.11434 9.25698 4.33412C9.61268 4.5539 9.8997 4.86894 10.0855 5.24351L10.4635 6.00001H20V15.75C20 16.0455 19.9418 16.3381 19.8287 16.6111C19.7157 16.884 19.5499 17.1321 19.341 17.341C19.1321 17.5499 18.884 17.7157 18.611 17.8287C18.3381 17.9418 18.0455 18 17.75 18ZM5 5.50001V15.75C5 15.9489 5.07902 16.1397 5.21967 16.2803C5.36032 16.421 5.55109 16.5 5.75 16.5H17.75C17.9489 16.5 18.1397 16.421 18.2803 16.2803C18.421 16.1397 18.5 15.9489 18.5 15.75V7.50001H9.5365L8.744 5.91451C8.68215 5.78956 8.58647 5.68446 8.46785 5.61119C8.34923 5.53791 8.21243 5.49939 8.073 5.50001H5Z"
        stroke="#959CA3"
        strokeWidth="0.6"
        mask="url(#path-3-outside-1)"
      />
    </svg>
  );
}

export default React.memo(File);
