/* eslint-disable @typescript-eslint/no-use-before-define */
import { Text } from '@dropbox/dig-components/typography';
import { Tooltip } from '@dropbox/dig-components/tooltips';
import React from 'react';
import { DebugPanel, PanelComponent } from './debug-panel';
import { OverlayPlacement } from '@dropbox/dig-components/overlay';
import styles from './debug-test-ids.module.scss';
import { UIIcon } from '@dropbox/dig-icons';
import { CommentLine, SearchLine } from '@dropbox/dig-icons/assets';
import { FormRow } from '@dropbox/dig-components/form_row';
import { Checkbox } from '@dropbox/dig-components/controls';
import { PropsOf } from 'js/sign-components/common/ts-utils';

type TestId =
  | { value: string; type: 'data-testid'; tagName: string }
  | { value: string; type: 'data-qa-ref'; tagName: string };

function useMarkQaInaccessibleElements(
  isOpen: boolean,
  setBadgeState: PropsOf<PanelComponent>['setBadgeState'],
) {
  const markedElements = React.useRef<Array<Element>>([]);
  const [alwaysShowMissingMarkers, setAlwaysShowMissingMarkers] =
    React.useState(() => {
      try {
        return localStorage.getItem('testIdMissingMarkers') !== null;
      } catch (e) {
        return false;
      }
    });
  React.useEffect(() => {
    try {
      if (alwaysShowMissingMarkers) {
        localStorage.setItem('testIdMissingMarkers', '1');
      } else {
        localStorage.removeItem('testIdMissingMarkers');
      }
    } catch (e) {
      // ignore
    }
  }, [alwaysShowMissingMarkers]);

  const markElements = React.useCallback(() => {
    const elements = [
      ...document.querySelectorAll('button'),
      ...document.querySelectorAll('input'),
    ];

    const debugTools = document.getElementById('hs-debug-tools');

    let count = 0;
    elements.forEach((el) => {
      if (el.dataset.qaRef || el.dataset.testid) {
        // ignore
      } else if (el.type === 'hidden') {
        // ignore
      } else if (debugTools?.contains(el)) {
        // ignore
      } else {
        count++;

        if (!el.classList.contains(styles.inaccessible)) {
          el.classList.add(styles.inaccessible);
          markedElements.current.push(el);
          // eslint-disable-next-line no-console
          console.log('Missing data-testid:', el);
        }
      }
    });

    if (count === 0) {
      setBadgeState('green');
    } else {
      setBadgeState('yellow');
    }
  }, [setBadgeState]);

  const clearMarks = () => {
    const tmp = markedElements.current;
    markedElements.current = [];
    tmp.forEach((el) => {
      el.classList.remove(styles.inaccessible);
    });
  };

  React.useEffect(() => {
    if (alwaysShowMissingMarkers) {
      markElements();
      const i = setInterval(markElements, 5000);
      return () => clearInterval(i);
    } else if (isOpen) {
      markElements();
    } else {
      clearMarks();
    }

    return clearMarks;
  }, [isOpen, alwaysShowMissingMarkers, markElements]);

  return [alwaysShowMissingMarkers, setAlwaysShowMissingMarkers] as const;
}

export const DebugTestIds: PanelComponent = ({ isOpen, setBadgeState }) => {
  const [testIds, setIds] = React.useState<TestId[]>([]);
  const triggerRef = React.useRef<null | Element>(null);
  const [placement, setPlacement] = React.useState<OverlayPlacement>('top');

  const [selected, setSelected] = React.useState<TestId | null>(null);
  if (selected) {
    triggerRef.current = document.querySelector(
      `[${selected.type}="${selected.value}"]`,
    );
  }
  const [alwaysShowMarkers, setAlwaysShowMissingMarkers] =
    useMarkQaInaccessibleElements(isOpen, setBadgeState);
  React.useEffect(() => {
    if (isOpen) {
      const elements = document.querySelectorAll('[data-testid]');
      let ids = [...elements].map(
        (el): TestId => ({
          value: (el as any).dataset.testid,
          type: 'data-testid',
          tagName: el.tagName.toLowerCase(),
        }),
      );

      const qaRefs = document.querySelectorAll('[data-qa-ref]');
      ids = ids.concat(
        [...qaRefs].map(
          (el): TestId => ({
            value: (el as any).dataset.qaRef,
            type: 'data-qa-ref',
            tagName: el.tagName.toLowerCase(),
          }),
        ),
      );

      // Unique test IDs
      ids = ids.reduce((keep, next) => {
        const found = keep.find(
          (k) => k.type === next.type && k.value === next.value,
        );
        if (!found) {
          keep.push(next);
        }
        return keep;
      }, [] as TestId[]);

      ids.sort((a, b) => {
        // data-testids first
        if (a.type !== b.type) {
          return a.type === 'data-testid' ? -1 : 1;
        }
        // Then sort by tag
        if (a.tagName !== b.tagName) {
          return a.tagName < b.tagName ? -1 : 1;
        }
        return a.value < b.value ? -1 : 1;
      });

      setIds(ids);
    } else if (selected) {
      setSelected(null);
    }
    return () => {};
  }, [isOpen, selected]);

  return (
    <React.Fragment>
      <DebugPanel.SectionHeader>Test IDs</DebugPanel.SectionHeader>
      <div>
        <Text variant="paragraph">
          Hover over an item to see where it is on the page.
        </Text>
        <br />
        <Text variant="paragraph">
          Click it to log the matching node(s) to the console
        </Text>
        <FormRow variant="control">
          <Checkbox
            id="showMissingMarkers"
            checked={alwaysShowMarkers}
            onChange={(e) => setAlwaysShowMissingMarkers(e.target.checked)}
          />
          <Text tagName="label" variant="label" htmlFor="showMissingMarkers">
            Always show missing markers (when this panel is closed)
          </Text>
        </FormRow>

        <ul style={{ height: '30vh' }}>
          {testIds.length === 0 && (
            <Text>Nothing found with [data-testid] or [data-qa-ref]</Text>
          )}

          {testIds.map((testId) => (
            <li
              key={`${testId.type}=${testId.value}`}
              onMouseOver={() => setSelected(testId)}
              onClick={() => {
                // eslint-disable-next-line no-console
                console.log(testId.type, testId.value, [
                  ...document.querySelectorAll(
                    `[${testId.type}="${testId.value}"]`,
                  ),
                ]);
              }}
            >
              <Text size="medium">
                <UIIcon
                  size="small"
                  style={{
                    opacity:
                      testId.type === selected?.type &&
                      testId.value === selected?.value
                        ? 1
                        : 0,
                  }}
                  src={CommentLine}
                />
                {testId.tagName}[{testId.type}=&quot;{testId.value}&quot;]
              </Text>
            </li>
          ))}
        </ul>

        <Tooltip.Control
          key={`${selected?.type} ${selected?.value}`}
          open={triggerRef.current != null && selected != null}
          placement={placement}
          auto
          onChangePlacement={setPlacement}
          triggerRef={triggerRef}
        >
          {selected?.type === 'data-qa-ref' && 'data-qa-ref='}
          &quot;{selected?.value}&quot;
        </Tooltip.Control>
      </div>
    </React.Fragment>
  );
};

export const debugTestIds = NODE_ENV !== 'production' && (
  <DebugPanel Component={DebugTestIds} icon={SearchLine} title="data-testid" />
);
