import { ThunkAction } from 'hellospa/redux/types';
import * as selectors from '../selectors';
import { Actions, Contact } from 'hellospa/page/prep-and-send/data/types';

// I just picked a number for this.
const MAX_AGE = 60000;

export const loadContacts =
  (force = false): ThunkAction<Promise<void>> =>
  async (dispatch, getState, getExtra) => {
    const { appActions, featureFlags } = getExtra();
    const state = getState();
    const lastUpdated = selectors.selectContactsLastUpdated(state);
    const sortedData = selectors.getContacts(state);

    if (lastUpdated < Date.now() - MAX_AGE || force) {
      // This updates the timestamp so that we only ever have one request in
      // flight at a time.
      dispatch({
        type: Actions.UpdateContacts,
        payload: {
          lastUpdated: Date.now(),
          sortedData,
        },
      });
      let updatedContacts: Contact[];
      if (featureFlags.sign_core_20240222_get_all_contacts_oas_flag) {
        updatedContacts = await appActions.accountApi.accountContactsGet();
      } else {
        updatedContacts = await appActions.prepAndSend.getAllContacts();
      }

      updatedContacts.sort((a, b) => b.numUses - a.numUses);

      dispatch({
        type: Actions.UpdateContacts,
        payload: {
          lastUpdated: Date.now(),
          sortedData: updatedContacts,
        },
      });
    }
  };

export const clearContacts =
  (): ThunkAction<Promise<void>> => async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();
    dispatch({
      type: Actions.UpdateContacts,
      payload: {
        lastUpdated: Date.now(),
        sortedData: [],
      },
    });
    await appActions.accountSettings.clearContacts();
  };

export const loadReferAFriendURL =
  (): ThunkAction<Promise<void>> => async (dispatch, getState, getExtra) => {
    const url = selectors.selectReferAFriendUrl(getState());
    if (!url) {
      const { appActions } = getExtra();
      const referAFriendURL = await appActions.prepAndSend.getReferAFriendURL();
      dispatch({
        type: Actions.SetReferAFriendURL,
        payload: referAFriendURL,
      });
    }
  };

export const inviteFriends =
  (emails: string[]): ThunkAction<Promise<void>> =>
  async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();

    await appActions.prepAndSend.inviteFriends(emails);
  };
