import { CsvEditorRow } from 'hellospa/page/prep-and-send/data/types/csv-editor-row';
import { uploadSignerFileBulkSend } from 'hello-react/web-app-client/namespace/prep-and-send';
import { encodeCsv } from '../utils/encode-csv-as-data-url';

function makeCsvFile(rows: CsvEditorRow[]): File {
  const headers = Object.keys(rows[0]);
  const csvAsString = [headers, ...rows.map(Object.values)]
    .map((line) => encodeCsv(line))
    .join('\n');
  const blob = new Blob([csvAsString], { type: 'text/csv' });
  return new File([blob], 'test.csv');
}

export class BulkSendService {
  static async updateSigners(rows: CsvEditorRow[], bulkSendDataKey: string) {
    return uploadSignerFileBulkSend(bulkSendDataKey, makeCsvFile(rows));
  }
}
