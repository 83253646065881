import * as deepIntegration from './deep-integration';

export enum SaveDropboxPathStatusValues {
  Success = 'ok',
  Error = 'error',
}
type DeepIntegration = typeof deepIntegration;

export default class MockDeepIntegration implements DeepIntegration {
  async pollSavingBackToDropboxStatus(): Promise<any> {
    throw new Error('Not Implemented');
  }

  async pollTemplateStatusForDeepIntegration(): Promise<any> {
    throw new Error('Not Implemented');
  }

  async saveDefaultSavePath(): Promise<any> {
    throw new Error('Not Implemented');
  }

  async saveDropboxFolderPath(): Promise<any> {
    return { status: SaveDropboxPathStatusValues.Success };
  }
}
