import { defineMessages } from 'react-intl';
import { ThunkAction } from 'hellospa/redux/types';
import { pollExternalFile, pollFileStatus } from './file';
import {
  SaveDropboxPathStatusValues,
  SaveStatusStatusValues,
  SaveBackErrorTypes,
} from 'hellospa/page/prep-and-send/data/types/deep-integration';
import { Actions } from 'hellospa/page/prep-and-send/data/types/';
import { ApiApp, UpdateApiAppAction } from '../types/api-app';
import { UserFileTypes, FilePollResponseStatus } from '../types/file';
import * as workflowActions from './workflow';
import { FolderPathBody } from 'hellospa/components/deep-integration/dropin/types';
import { unreachable } from 'js/sign-components/common/ts-utils';
import delay from 'hellospa/common/utils/delay';
import * as hsEmbedded from 'js/sign-components/common/messages';
import * as selectors from 'hellospa/page/prep-and-send/data/selectors';
import * as actions from 'hellospa/page/prep-and-send/data/actions';
import { trackHeapCustomEvent } from 'js/sign-components/common/heap';
import { NotificationBannerType } from 'hellospa/components/notification-banner/data/types';
import { DefaultSavePathRequest } from 'hellospa/components/deep-integration/folder-picker/types';

const MAX_POLL_COUNT = 50;
const POLL_TIME = NODE_ENV === 'test' ? 1 : 2500;

const messages = defineMessages({
  backendValidationError: {
    id: '',
    description:
      'Validation error message to be displayed when user did not select a folder.',
    defaultMessage: 'Folder selection is required.',
  },
  backendErrorGeneric: {
    id: '',
    description: 'Title explaining an unexpected error in the application.',
    defaultMessage: 'Can’t continue because something went wrong',
  },
  backendErrorDropboxQuota: {
    id: '',
    description:
      'Error message explaining that the user is out of disk space on dropbox.',
    defaultMessage: 'You are out of storage space on Dropbox',
  },
  backendErrorNetworkIssue: {
    id: '',
    description:
      'Error message explaining that the user is out of disk space on dropbox.',
    defaultMessage: 'Could not save file to Dropbox',
  },
  dbxErrorRequestSent: {
    id: '',
    description:
      'Error message explaining to the user that even though there was an error their request was sent.',
    defaultMessage:
      'Don’t worry, Your signature request was successfully sent. You will receive a signed copy in your email',
  },
});

const errorMessageFromType = (type: string | undefined) => {
  switch (type) {
    case SaveBackErrorTypes.Quota:
      return {
        errorTitle: messages.backendErrorDropboxQuota,
        errorInstructions: messages.dbxErrorRequestSent,
      };
    case SaveBackErrorTypes.Network:
      return {
        errorTitle: messages.backendErrorNetworkIssue,
        errorInstructions: messages.dbxErrorRequestSent,
      };
    default:
      return {
        errorTitle: messages.backendErrorGeneric,
        errorInstructions: messages.dbxErrorRequestSent,
      };
  }
};

/**
 * Kicks off the loading of the external download
 * which is the first step in the Deep Integration
 */
export const convertDeepIntegrationFile =
  (): ThunkAction<void> => async (dispatch, getState) => {
    const integration = selectors.getIntegration(getState());
    const files = selectors.getFiles(getState());
    if (!integration) {
      throw new Error('Deep not found in flag.integration');
    }

    /**
     * If this is the initial load where the file is not in state yet,
     * poll to get the file externally.
     * */
    if (files.length < 1) {
      trackHeapCustomEvent('deep_integration_initial_loading');
      const { data } = integration;

      // DI can have multiple external cache key & they are comma(,) seperated
      const externalCacheKeys = data.externalCacheKey.split(',');
      for (const [i, externalCacheKey] of externalCacheKeys.entries()) {
        dispatch({
          type: Actions.CreateFile,
          payload: {
            name: 'temp',
            type: UserFileTypes.External,
            rootSnapshotGuid: externalCacheKey, // creating a temporary file to show progress
            order: i,
            fields: [],
            status: FilePollResponseStatus.Converting,
            pwRequired: false,
          },
        });
        dispatch(pollExternalFile(data.externalCacheKey));
      }

      const integrationError = selectors.getIntegrationError(getState());
      if (integrationError) {
        throw new Error('Deep Integration file polling error');
      }
    } else {
      /**
       * Otherwise, just poll the file's status.
       */
      const [file] = files;
      if (file.status === FilePollResponseStatus.Ok) {
        return;
      }
      await dispatch(pollFileStatus(file.rootSnapshotGuid));
    }
  };

/**
 * Saves the dropbox path to the BE and moves to the next page on success
 *
 * @param {FolderPathBody} body
 */
export const saveDropboxPath =
  (body: FolderPathBody): ThunkAction<Promise<boolean>> =>
  async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();

    const response =
      await appActions.deepIntegration.saveDropboxFolderPath(body);
    if (response.status === SaveDropboxPathStatusValues.Success) {
      dispatch(workflowActions.nextPageInWorkflow());
      return true;
    } else {
      dispatch(
        actions.createBannerMessage(
          messages.backendValidationError,
          NotificationBannerType.Err,
        ),
      );
      return false;
    }
  };

/**
 * Set the chosen filename in the integration data
 */
export const setFilename =
  (filename: string): ThunkAction<void> =>
  async (dispatch) => {
    dispatch({
      type: Actions.SetIntegrationExternalFilename,
      payload: filename,
    });
  };

/**
 * The deep integration flow ends with a loading screen
 * This function polls the backend to find out when the
 * file was successfully saved back to Dropbox
 *
 * @param {string} transmissionGroupGuid
 */
export const pollSavingBackToDropboxStatus =
  (
    transmissionGroupGuid: string,
    passGuidToParent?: boolean,
    isSelfSign?: boolean,
  ): ThunkAction<void> =>
  async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();
    const apiApp = selectors.getApiApp(getState());
    const isDocToTemplateEnabled =
      selectors.shouldConvertDocToTemplate(getState());
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < MAX_POLL_COUNT; i++) {
      const data =
        await appActions.deepIntegration.pollSavingBackToDropboxStatus(
          transmissionGroupGuid,
        );
      let error;
      switch (data.status) {
        case SaveStatusStatusValues.Complete:
          hsEmbedded.postMessage(
            {
              type: hsEmbedded.messages.DEEP_INTEGRATION_FINISHED,
              payload: {
                filePath: data.filePath,
                signatureRequestId: data.signatureRequestId,
                ...(passGuidToParent && { guid: transmissionGroupGuid }),
                ...(isDocToTemplateEnabled && { isDocToTemplateEnabled: true }),
                ...(isSelfSign && { isSelfSign: true }),
              },
            },
            apiApp!.parentUrl,
          );
          return;
        case SaveStatusStatusValues.Pending:
        case SaveStatusStatusValues.Queued:
        case SaveStatusStatusValues.Processing:
          // Just do the next iteration
          break;
        case SaveStatusStatusValues.Failed:
        case SaveStatusStatusValues.Error:
          error = errorMessageFromType(data.error);
          dispatch(actions.showDeepIntegrationError(error));
          return;
        default:
          unreachable(data);
      }
      await delay(POLL_TIME, 1000);
    }
  };

/**
 * For the deep integration at the end of create/edit template flow
 * we show a post-loading screen that checks the status of the template.
 * Once the status is ok we send a js message to the parent
 *
 * @param transmissionGroupGuid
 * @returns
 */
export const pollTemplateStatusForDeepIntegration =
  (transmissionGroupGuid: string): ThunkAction<Promise<void>> =>
  async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();
    const apiApp = selectors.getApiApp(getState());
    for (let i = 0; i < MAX_POLL_COUNT; i++) {
      const data = await appActions.prepAndSend.getCreateTemplateStatus(
        transmissionGroupGuid,
        true,
      );
      switch (data.status) {
        case 'ok':
          if (apiApp) {
            hsEmbedded.postMessage(
              {
                type: hsEmbedded.messages.USER_CREATE_TEMPLATE,
                payload: {
                  template_guid: data.templateGuid,
                },
              },
              apiApp!.parentUrl,
            );
          }
          return;
        case 'error':
          // TODO: Implement error states
          // eslint-disable-next-line no-console
          console.error('DI: Template status returned an error');
          break;
        case 'pending':
        default:
      }
      await delay(250, 0);
    }
  };

/**
 * Saves the default dropbox path to the BE
 *
 * @param {FolderPathBody} body
 */
export const saveDefaultSavePath =
  (body: DefaultSavePathRequest): ThunkAction<Promise<boolean>> =>
  async (dispatch, getState, getExtra) => {
    const { appActions } = getExtra();

    const response = await appActions.deepIntegration.saveDefaultSavePath(body);
    if (response.status === SaveDropboxPathStatusValues.Success) {
      dispatch({
        type: Actions.SetIntegrationDefaultSavePath,
        payload: body.defaultSavePath,
      });
      return true;
    } else {
      return false;
    }
  };

/**
 * This action should only be used during tests or storybook
 */
export const setApiApp = (payload: ApiApp): UpdateApiAppAction => {
  if (NODE_ENV !== 'test' && !IS_STORYBOOK) {
    // This action is only available for testing or Storybook.
    throw new Error('Invalid action');
  }
  return {
    type: Actions.UpdateApiApp,
    payload,
  };
};
