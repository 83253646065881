import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { postMessage, messages } from 'js/sign-components/common/messages';
import {
  Document,
  Field,
  SignerOrder,
  Signer,
  DocumentRotation,
} from 'signer-app/types/editor-types';
import {
  SaveEmbeddedRequestResponse,
  SaveEmbeddedTemplateResponse,
} from 'hello-react/web-app-client/namespace/editor';
import { RequestEventData } from 'js/sign-components/common/message-types';

declare global {
  interface Window {
    preventNavigationAwayOverride?: number;

    // Embedded v1
    HelloSign: {
      EVENT_SIGNED: 'signature_request_signed';
      EVENT_DECLINED: 'signature_request_declined';
      EVENT_CANCELED: 'signature_request_canceled';
      EVENT_REASSIGNED: 'signature_request_reassigned';
      EVENT_SENT: 'signature_request_sent';
      EVENT_TEMPLATE_CREATED: 'template_created';
      EVENT_ERROR: 'error';

      XWM: {
        // https://github.com/hellosign/hellosign-embedded/blob/637e2e1f04dd80b9ac3a20f6511c54002d6f2768/src/embedded.js#L67
        send: (event: unknown, parentUrl: string, target?: unknown) => void;
      };
    };
  }
}

// post only within app{*}.hellosign.com
const internalMessage = () => `https:${HfReactHelper.urlHelper('')}`;

type InitOptions = {
  parentUrl: string;
  keepSessionAlive: () => void;
  onClose: () => void;
};

const embeddedScript =
  'https://cdn.hellosign.com/public/js/hellosign-embedded.LATEST.min.js';
export function initEmbedded({
  parentUrl,
  keepSessionAlive,
  onClose,
}: InitOptions) {
  const parentURLOrigin = parentUrl.replace(/([^:]+:\/\/[^/]+).*/, '$1');

  // append embedded v1 script
  const script = document.createElement('script');
  script.src = embeddedScript;
  script.async = true;
  document.body.appendChild(script);

  // Listen for Embedded v2 messages.
  window.addEventListener('message', ({ data, origin }) => {
    if (origin === parentURLOrigin) {
      switch (data.type) {
        case messages.USER_CANCEL_REQUEST:
          onClose();
          break;
        default:
          // No default action.
          break;
      }
    }
  });

  // HelloSign Embedded v2
  postMessage(
    {
      type: messages.APP_INITIALIZE,
    },
    parentUrl,
  );

  // keep session alive every 60 seconds
  setInterval(() => {
    keepSessionAlive();
  }, 60000);
}

type TemplateEventData = {
  success: SaveEmbeddedTemplateResponse['success'];
  templateStatusId: SaveEmbeddedTemplateResponse['template_status_id'];
  templateId: SaveEmbeddedTemplateResponse['template_id'];
  templateInfo: {
    title: SaveEmbeddedTemplateResponse['template_info']['title'];
    message: SaveEmbeddedTemplateResponse['template_info']['message'];
    signerRoles: SaveEmbeddedTemplateResponse['template_info']['signer_roles'];
    ccRoles: SaveEmbeddedTemplateResponse['template_info']['cc_roles'];
  };
};

export type FinalizeOptions = {
  eventData: RequestEventData | TemplateEventData;
  parentUrl: string;
  redirectUrl: string | undefined;
};

export function finalizeEmbeddedFlow({
  parentUrl,
  redirectUrl,
  eventData,
}: FinalizeOptions) {
  let eventName;

  // The embedded v1 event and redirect use snake_case, so this event data has
  // to get converted
  let saveDataResponse:
    | SaveEmbeddedTemplateResponse
    | SaveEmbeddedRequestResponse;
  if ('templateId' in eventData) {
    // Embedded v1
    eventName = window.HelloSign
      ? window.HelloSign.EVENT_TEMPLATE_CREATED
      : 'template_created';

    saveDataResponse = {
      success: eventData.success,
      template_status_id: eventData.templateStatusId,
      template_id: eventData.templateId,
      template_info: {
        title: eventData.templateInfo.title,
        message: eventData.templateInfo.message,
        signer_roles: eventData.templateInfo.signerRoles,
        cc_roles: eventData.templateInfo.ccRoles,
      },
    };

    // HelloSign Embedded v2
    postMessage(
      {
        type: messages.USER_CREATE_TEMPLATE,
        payload: eventData,
      },
      parentUrl,
    );
  } else {
    // Embedded v1
    eventName = window.HelloSign
      ? window.HelloSign.EVENT_SENT
      : 'signature_request_sent';
    saveDataResponse = {
      success: eventData.success,
      signature_request_id: eventData.signatureRequestId,
      signature_request_info: {
        title: eventData.signatureRequestInfo.title,
        message: eventData.signatureRequestInfo.message,
        expires_at: eventData.signatureRequestInfo.expiresAt,
        signatures: eventData.signatureRequestInfo.signatures,
        cc_email_addresses: eventData.signatureRequestInfo.ccEmailAddresses,
      },
    };

    // HelloSign Embedded v2
    postMessage(
      {
        type: messages.USER_SEND_REQUEST,
        payload: eventData,
      },
      parentUrl,
    );
  }

  const event = Object.assign(saveDataResponse, { event: eventName });
  // Message parent window for embedded v1
  if (window.HelloSign) {
    window.HelloSign.XWM.send(event, parentUrl);
  }

  // Disable navigation warning, since we are finalizing the flow
  // (i.e., the flow has already been completed and saved to the server)
  window.preventNavigationAwayOverride = 1;

  // Redirect or close
  if (redirectUrl) {
    // Redirect + add event data to url parameters
    let url = redirectUrl;
    const parts = url.split('://');
    if (parts.length > 1) {
      // Remove the protocol and force it to be the same as the iframe to prevent the redirection
      // from being blocked by protocol mismatch
      url = `//${parts.slice(1).join('://')}`;
    }

    const serializeMessageValue = (val: unknown) => {
      let value = '';
      if (typeof val === 'object') {
        value = JSON.stringify(val);
      }
      return encodeURIComponent(value);
    };
    Object.keys(event).forEach((item) => {
      const value = serializeMessageValue(
        // @ts-ignore
        event[item],
      );
      url += url.indexOf('?') > 0 ? '&' : '?';
      url += `${item}=${value}`;
    });

    window.location.href = url;
  } else {
    // Message parent window for embedded v1
    if (window.HelloSign) {
      window.HelloSign.XWM.send('user-done', parentUrl);
    }

    // HelloSign Embedded v2
    postMessage(
      {
        type: messages.USER_FINISH_REQUEST,
      },
      parentUrl,
    );
  }
}

type ContinueData = {
  isTemplate: boolean;
  isTemplateLink: boolean;
  documents: Document[];
  fields: Field[];
  signers: Signer[];
  snapshots: unknown[];
  signerOrder: SignerOrder;
  rotate: DocumentRotation;
  signerOptions: unknown;
};
export function docuploaderContinue(data: ContinueData) {
  postMessage(
    {
      type: messages.USER_PREP_SEND_EDITOR_CONTINUE,

      is_template: data.isTemplate,
      is_template_link: data.isTemplateLink,
      documents: data.documents,
      fields: data.fields,
      signers: data.signers,
      snapshots: data.snapshots,
      signer_order: data.signerOrder,
      rotate: data.rotate,
      signer_options: data.signerOptions,
    },
    internalMessage(),
  );
}

export function docuploaderValidation(validationData: unknown) {
  // @ts-ignore It's not clear what shape validationData is
  postMessage(validationData, internalMessage());
}

export function embeddedCancel(parentUrl: string) {
  window.parent.postMessage('HS-CLOSE', '*');

  // HelloSign Embedded v2
  postMessage(
    {
      type: messages.USER_CANCEL_REQUEST,
    },
    parentUrl,
  );
}
