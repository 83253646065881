import * as yup from 'yup';
import { Actions } from './index';
import { defineMessages } from 'react-intl';
import { intl } from 'hellospa/common/hs-intl-provider';
import { containsEmailOrUrl } from 'js/sign-components/common/ts-utils';
import { Meta } from './meta';

const messages = defineMessages({
  documentTitle: {
    id: '',
    defaultMessage: 'Document title',
    description:
      'Label for input fields that gets injected into error messages',
  },
  messageLabel: {
    id: '',
    defaultMessage: 'Message',
    description:
      'Label for message input fields that gets injected into error messages',
  },
  titleNotEmailOrURL: {
    id: '',
    defaultMessage:
      'URLs and email addresses are not permitted in document titles',
    description: "Error Instructing that URL or Emails can't be document title",
  },
  nameNotEmailOrURL: {
    id: '',
    defaultMessage:
      'URLs and email addresses are not permitted in first and last name',
    description: "Error Instructing that URL or Emails can't be name",
  },
  firstAndLastNameRequired: {
    id: '',
    defaultMessage: 'First and last names are required',
    description: 'text that gets injected into error messages',
  },
  nameMaxLimit: {
    id: '',
    defaultMessage: 'Name must be at most 128 characters',
    description:
      "Error message to be displayed when signer's name is longer than 128 characters",
  },
});

export interface Document {
  readonly title: string;
  readonly message: string;
  readonly expiresAt: Date | null | undefined;
}

export interface SenderNameAndTitle {
  readonly firstname: string;
  readonly lastname: string;
  readonly title: string;
  readonly message: string;
}

export const documentTitleSchema = yup.lazy(
  (value: string): yup.StringSchema => {
    const name = yup
      .string()
      .trim()
      .label(intl.formatMessage(messages.documentTitle))
      .max(200)
      .required();
    const nameSchema = yup
      .string()
      .trim()
      .test(
        'Not email or Url',
        intl.formatMessage(messages.titleNotEmailOrURL),
        (title) => !containsEmailOrUrl(title),
      )
      .test(
        'Title is not longer than 200 characters',
        "Template names and Document titles can't exceed 200 characters",
        (title) => title.length <= 200,
      );

    // if string is empty, allow to pass
    return !value || !value.length ? name : nameSchema.max(200);
  },
);

export const nameTitleSchema = (
  requiredErrMsg: string,
  urlEmailErrMsg: string,
) =>
  yup.lazy((value: string): yup.StringSchema => {
    const nameInputSchema = yup.string().trim();
    const name = yup.string().trim().required(requiredErrMsg).max(128);
    const nameSchema = yup
      .string()
      .trim()
      .test('Not email or Url', urlEmailErrMsg, (name) => {
        const validUrl = nameInputSchema.url().isValidSync(name);
        const validEmail = nameInputSchema.email().isValidSync(name);

        return !validUrl && !validEmail;
      });

    // if string is empty, allow to pass
    return !value || !value.length ? name : nameSchema.max(128);
  });

export const documentSchema = yup.object<Document>({
  title: documentTitleSchema,
  message: yup
    .string()
    .trim()
    .label(intl.formatMessage(messages.messageLabel))
    .max(5000),
  expiresAt: yup.date().nullable().notRequired(),
});

export const userNameAndTitleSchema = yup.object<SenderNameAndTitle>({
  firstname: nameTitleSchema(
    intl.formatMessage(messages.firstAndLastNameRequired),
    intl.formatMessage(messages.nameNotEmailOrURL),
  ),
  lastname: nameTitleSchema(
    intl.formatMessage(messages.firstAndLastNameRequired),
    intl.formatMessage(messages.nameNotEmailOrURL),
  ),
  title: documentTitleSchema,
  message: yup
    .string()
    .trim()
    .label(intl.formatMessage(messages.messageLabel))
    .max(5000),
});

export const faxDocumentSchema = yup.object<Document>({
  title: yup.string().trim().label(intl.formatMessage(messages.documentTitle)),
  message: yup
    .string()
    .trim()
    .label(intl.formatMessage(messages.messageLabel))
    .max(5000),
  expiresAt: yup.date().nullable().notRequired(),
});

export const emailSchema = yup.object<Document>({
  title: yup.string(),
  message: yup
    .string()
    .trim()
    .label(intl.formatMessage(messages.messageLabel))
    .max(5000),
  expiresAt: yup.date().nullable().notRequired(),
});

export interface UpdateDocumentAction {
  type: Actions.UpdateDocument;
  payload: Partial<Document>;
  meta: Partial<Meta>;
}

export type DocumentActions = UpdateDocumentAction;
