/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  no-void
*/
import BaseModel from 'common/models/base/model';

/**
 * wrapper around multiform submit. Note that
 * this file is generally created by a component, and
 * emitted to listener which most would most likely pass this
 * back to a model. (CC)
 */

// TODO - bind upload path
// TODO - ability to change upload path
module.exports = BaseModel.extend({
  /**
   * properties
   */

  // private property on file - this is the form object submitting multipart data. Only way to do
  // this in the browser. (CC)
  _form: void 0,

  // the physical file content. We'll want to use this later when using pure JS
  // to upload files
  content: void 0,

  // name of the file to upload
  name: void 0,

  /**
   */

  initialize() {
    if (!this._form) throw new Error('_form must exist for file models');
  },

  /**
   * uploads the file to the browser
   */

  upload() {
    // currently unused
    // // jquery required to properly emit onsubmit
    // this._form.action = app.service.transport.getAPIPath(pathname);
    // $(this._form).submit();
    //
    // // nothing for now
    // return Promise.resolve();
  },
});
