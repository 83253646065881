import {
  DeepIntegrationRecipient,
  DeepIntegrationSendRecipients,
  EditorValidationType,
  EditorView,
  MessageType,
} from 'js/sign-components/common/message-types';
import {
  postTo,
  useWindowMessage,
  canPostToParent,
} from 'js/sign-components/common/messages';
import { useEventListener } from 'signer-app/utils/use-event-listener';

export { postTo, useWindowMessage, canPostToParent };
export {
  MessageType,
  DeepIntegrationRecipient,
  DeepIntegrationSendRecipients,
  EditorValidationType,
  EditorView,
};

/**
 * This is used to notify the mobile app if the user gets a timeout warning.
 * The timeout and alert are managed in jquery.hellofax.js. That code fires a
 * custom event, which is picked up here and forwarded to the parent from
 * Editor->P&S. Then P&S also uses this hook, so it listens for that message and
 * forwards to its parent P&S->parent page.
 */
export function useForwardSessionTimeout(parentUrl: string | null) {
  useEventListener(document, 'sessionTimeoutWarning', () => {
    if (parentUrl == null) return;
    postTo(
      window.parent,
      {
        type: MessageType.IntegrationSessionTimeoutWarning,
      },
      parentUrl,
    );
  });

  useWindowMessage(MessageType.IntegrationSessionTimeoutWarning, () => {
    if (parentUrl == null) {
      return;
    }

    postTo(
      window.parent,
      {
        type: MessageType.IntegrationSessionTimeoutWarning,
      },
      parentUrl,
    );
  });

  useEventListener(document, 'userLogout', (event) => {
    if (parentUrl == null) return;

    // Notify the dispatcher the event is forwarded
    // by canceling the userLogout event
    event.preventDefault();

    postTo(
      window.parent,
      {
        type: MessageType.IntegrationUserLogout,
      },
      parentUrl,
    );
  });
  useWindowMessage(MessageType.IntegrationUserLogout, () => {
    if (parentUrl == null) {
      return;
    }

    postTo(
      window.parent,
      {
        type: MessageType.IntegrationUserLogout,
      },
      parentUrl,
    );
  });
}
