/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs
*/
import APIService from 'signer/api';
import SignerEndpoints from 'signer/api/endpoints';
import BaseApplication from 'common/application';
import models from './models';
import Actions from './actions';
import HelloSignEndpoints from './api/endpoints';

/**
 * This is a standealone version of the legacy application constructor.  It's
 * separated here so that it can be imported without dragging along _all_ of the
 * hellosign components into the bundle.  Attaching all of the app components
 * is necessary in the legacy architecture because they need to be available on
 * the window, but it's no longer necessary with the new architecture. This is a
 * transitory step towards making the hellosign app into more modular,
 * spa-style apps.
 */
function Application(properties) {
  BaseApplication.call(this, {
    ...properties,
  });

  this.models = models;

  this.notifier = this.notifierCollection;

  // set data here to prevent undefined exceptions in api/endpoints
  this.serverSideGeneratedData = {};

  this.actions = Actions.create({ app: this });

  // the API service is added as a global notifier so that any
  // part of the application can implicitly execute a message to the API
  // service and receive a response.
  // I.E: the API service is decoupled from the APP.
  // I.E: the app is more testable.
  this.notifier.push(
    APIService.create({
      // attach notifier so that the API service can announce
      // when there is a response from the service
      notifier: this.notifier,

      // combine both endpoints
      endpoints: Object.assign(
        SignerEndpoints.create(this),
        HelloSignEndpoints.create(this),
      ),
    }),
  );

  this.user = models.User.create({
    notifier: this.notifier,
  });
}

BaseApplication.extend(Application, {
  hydrate(data) {
    // this stuff should be an API at some point
    this.user.emailAddress = data.emailAddress;
    this.serverSideGeneratedData = data;
  },
});

export default Application;
