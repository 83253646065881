import * as Yup from 'yup';

// I'm only using Yup to capture validation errors. schema.validate throws,
// which is a bit awkward, so this function only returns arrays of errors.
export default async function capture<
  T extends object | null | undefined = object,
>(schema: Yup.Schema<T>, value: T): Promise<Yup.ValidationError[]> {
  try {
    await schema.validate(value, { abortEarly: false });
  } catch (err: any) {
    if (!err.inner) {
      return [err];
    }

    // with abortEarly: false, `.inner` is an array of ALL validation errors
    return err.inner;
  }

  return [];
}
