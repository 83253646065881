import React from 'react';
import ResponsiveLayout from './layout-responsive';
import { DefaultLayoutContext } from './context';

export default function DefaultLayout(props) {
  const context = React.useContext(DefaultLayoutContext);

  // Pulled from old layout to support legacy pages.
  if (context === undefined) {
    return props.children;
  }

  return <ResponsiveLayout {...props} />;
}
