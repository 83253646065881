/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React from 'react';
import classNames from 'classnames';
import styles from './layout.module.css';

export function HellosignLayout({ children, responsive }) {
  // The 'site-wrapper' is needed as a js-hook for the notifications component
  return (
    <div
      id="site-wrapper"
      // Use of literal "hellosign" here is acceptable. Questions? #ask-hs-frontend.
      // eslint-disable-next-line no-restricted-syntax
      className={classNames(styles.boxRule, 'hellosign-layout-container', {
        [styles.responsive]: responsive,
      })}
    >
      {children}
    </div>
  );
}

export default HellosignLayout;
