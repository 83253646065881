import React from 'react';
import classnames from 'classnames';
import styles from './icons.scss';

const SvgCircleError = (props) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    className={classnames(styles.icon, props.className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.272 4.98957C11.0436 3.66589 12.9561 3.66589 13.7277 4.98957L20.7248 16.9928C21.5021 18.3261 20.5403 20 18.997 20H5.00268C3.45936 20 2.49758 18.3261 3.27483 16.9928L10.272 4.98957Z"
      fill="#DD1D1D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0066 14.862C11.4919 14.862 11.0899 14.476 11.0577 13.9614L10.8165 8.6383C10.7843 7.96286 11.3311 7.39999 11.9905 7.39999C12.6659 7.39999 13.2127 7.94678 13.1805 8.6383L12.9554 13.9614C12.9232 14.476 12.5051 14.862 12.0066 14.862ZM10.72 17.1134C10.72 16.4058 11.2989 15.8108 12.0066 15.8108C12.7142 15.8108 13.2931 16.4058 13.2931 17.1134C13.2931 17.805 12.7142 18.4 12.0066 18.4C11.2989 18.4 10.72 17.805 10.72 17.1134Z"
      fill="white"
    />
  </svg>
);

export default React.memo(SvgCircleError);
