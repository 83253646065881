import * as React from 'react';

function ErrorTriangleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M2.85955 16.9571L10.2934 4.79256C11.0733 3.51643 12.9267 3.51643 13.7066 4.79256L21.1404 16.9571C21.9549 18.2898 20.9957 20 19.4339 20H4.56611C3.00425 20 2.04512 18.2898 2.85955 16.9571Z"
        fill="#DD1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0069 14.862C11.4923 14.862 11.0902 14.476 11.0581 13.9614L10.8168 8.6383C10.7847 7.96286 11.3314 7.39999 11.9908 7.39999C12.6662 7.39999 13.213 7.94678 13.1809 8.6383L12.9557 13.9614C12.9236 14.476 12.5054 14.862 12.0069 14.862ZM10.7203 17.1134C10.7203 16.4058 11.2993 15.8108 12.0069 15.8108C12.7145 15.8108 13.2934 16.4058 13.2934 17.1134C13.2934 17.805 12.7145 18.4 12.0069 18.4C11.2993 18.4 10.7203 17.805 10.7203 17.1134Z"
        fill="#FFFFFF"
      />
    </svg>
  );
}

export default React.memo(ErrorTriangleIcon);
