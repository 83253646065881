import React, { useState, Dispatch, SetStateAction } from 'react';
import { TourName } from 'js/sign-components/common/TourName';
import { createSimpleDataContext } from 'js/sign-components/common/create-simple-data-context';

type ToursContextShape = {
  activeTour: TourName | null;
  setActiveTour: Dispatch<SetStateAction<TourName | null>>;
  addToCompletedTours: (tour: TourName) => void;
  completedTours: TourName[];
};

export const ToursContext =
  createSimpleDataContext<ToursContextShape>('Tours Context');

export type ToursContextProviderProps = React.PropsWithChildren<{
  completedTours: TourName[];
}>;

export function ToursProvider(props: ToursContextProviderProps) {
  const [activeTour, setActiveTour] = useState<TourName | null>(null);
  /**
   * When we send a request to complete a tour
   */
  const [completedTours, setCompletedTours] = useState<TourName[]>(
    props.completedTours,
  );

  const addToCompletedTours = React.useCallback((tour: TourName) => {
    setCompletedTours((tours) => {
      if (tours.includes(tour)) {
        return tours;
      }
      return [...tours, tour];
    });
  }, []);
  return (
    <ToursContext.Provider
      value={{
        activeTour,
        setActiveTour,
        addToCompletedTours,
        completedTours,
      }}
    >
      {props.children}
    </ToursContext.Provider>
  );
}
