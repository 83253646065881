import * as React from 'react';

function FieldsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M11 11l.316-.949a1 1 0 00-1.265 1.265L11 11zm3 9l-.949.316a1 1 0 001.656.391L14 20zm6-6l.707.707a1 1 0 00-.39-1.656L20 14zm-9.949-2.684l3 9 1.898-.632-3-9-1.898.632zm10.265 1.735l-9-3-.632 1.898 9 3 .632-1.898zm-5.609 7.656l3-3-1.414-1.414-3 3 1.414 1.414zm3-3l3-3-1.414-1.414-3 3 1.414 1.414zm-1.414 0l3 3 1.414-1.414-3-3-1.414 1.414z"
        fill="currentColor"
      />
      <path
        d="M11.2 12H4V5h8v6.125"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default React.memo(FieldsIcon);
