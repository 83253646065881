import * as React from 'react';

function SignersIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 17h10a5 5 0 00-10 0zm-2 .002A7 7 0 1119 17v1a1 1 0 01-1 1H6a1 1 0 01-1-1V17.001z"
        fill="currentColor"
      />
      <circle cx={12} cy={7} r={3} stroke="currentColor" strokeWidth={2} />
    </svg>
  );
}

export default React.memo(SignersIcon);
