import { StoreShape, MOAR_NAMESPACE_KEY } from 'hellospa/redux/namespaces';
import { MoarNamespaceState } from './types';

export const getMoarState = (state: StoreShape): MoarNamespaceState =>
  state[MOAR_NAMESPACE_KEY];

export const getHasErrored = (state: StoreShape) =>
  getMoarState(state).hasErrored;

export const getResult = (state: StoreShape) => getMoarState(state).result;

export const getPages = (state: StoreShape) => getMoarState(state).pages;

export const getResetKey = (state: StoreShape) => getMoarState(state).resetKey;

export const getRecipient = (state: StoreShape) =>
  getMoarState(state).recipient;
