// Import all required DIG helpers and stylesheets.
import '@dropbox/dig-foundations/dist/tokens.css';
import '@dropbox/dig-fonts/dist/index.css';
import '@dropbox/dig-components/dist/index.css'; // eslint-disable-line no-restricted-syntax
import '@dropbox/dig-foundations/dist/index.css';

// Define the HelloSign themes names.
const signTheme = 'Sign'; // eslint-disable-line no-restricted-syntax

// Define the HelloSign DIG theme classes.
export const signThemeClassName = `dig-theme--${signTheme}`;
