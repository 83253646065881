import * as yup from 'yup';
import { defineMessages } from 'react-intl';
import { intl } from 'hellospa/common/hs-intl-provider';
import { Actions } from './index';
import {
  DeepIntegrationErrorDescriptor,
  FileErrorDescriptor,
} from 'hellospa/components/prep-and-send/file-uploader/messages';
import { SaveDropboxPathStatusValues } from 'hello-react/web-app-client/namespace/deep-integration-mock';

const messages = defineMessages({
  fileName: {
    id: '',
    defaultMessage: 'File name',
    description:
      'Label for input fields that gets injected into error messages',
  },
  fileNameInvalid: {
    id: '',
    defaultMessage: 'File name has invalid characters',
    description: 'Error message for invalid value for File name field.',
  },
});

export interface Dropin {
  readonly filename: string;
  readonly folderPath: string;
}

export const dropinSchema = yup.object<Dropin>().shape({
  // Regex should always be kept in sync with DeepIntegration::isFileNameIsValid
  filename: yup
    .string()
    .trim()
    .label(intl.formatMessage(messages.fileName))
    .matches(/^((?![/\\]+).)*$/i, intl.formatMessage(messages.fileNameInvalid))
    .required(),
  folderPath: yup.string().trim().min(1).required(),
});

// dropbox/savePath responses
export { SaveDropboxPathStatusValues };

interface SaveDropboxPathResponseError {
  status: SaveDropboxPathStatusValues.Error;
  errorMsg: string;
}

export interface SaveDropboxPathResponseSuccess {
  status: SaveDropboxPathStatusValues.Success;
}

export type SaveDropboxPathResponse =
  | SaveDropboxPathResponseError
  | SaveDropboxPathResponseSuccess;

// dropbox/saveStatus responses
export enum SaveStatusStatusValues {
  Complete = 'complete',
  Pending = 'pending',
  Queued = 'queued',
  Processing = 'processing',
  Failed = 'failed',
  Error = 'error',
}

export interface SaveStatusComplete {
  status: SaveStatusStatusValues.Complete;
  filePath: string;
  filename: string;
  signatureRequestId: string;
}

export interface SaveStatusPending {
  status: SaveStatusStatusValues.Pending;
}

export interface SaveStatusQueued {
  status: SaveStatusStatusValues.Queued;
}

export interface SaveStatusProcessing {
  status: SaveStatusStatusValues.Processing;
}

export interface SaveStatusFailed {
  status: SaveStatusStatusValues.Failed;
  error?: string;
}

export interface SaveStatusError {
  status: SaveStatusStatusValues.Error;
  error?: string;
}

export type SaveStatusResponse =
  | SaveStatusComplete
  | SaveStatusPending
  | SaveStatusQueued
  | SaveStatusProcessing
  | SaveStatusFailed
  | SaveStatusError;

export interface SetIntegrationError {
  type: Actions.SetIntegrationError;
  payload: FileErrorDescriptor;
}

export interface SetDeepIntegrationError {
  type: Actions.SetDeepIntegrationError;
  payload: DeepIntegrationErrorDescriptor;
}

export interface SetIntegrationDefaultSavePath {
  type: Actions.SetIntegrationDefaultSavePath;
  payload: string;
}

export interface SetIntegrationExternalFilename {
  type: Actions.SetIntegrationExternalFilename;
  payload: string;
}

export enum SaveBackErrorTypes {
  Quota = 'quota',
  Network = 'network',
}

export type DeepIntegrationActions =
  | SetIntegrationError
  | SetIntegrationExternalFilename
  | SetIntegrationDefaultSavePath
  | SetDeepIntegrationError;
