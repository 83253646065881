import './notice-bar.scss';

import React from 'react';

import Notice from './notice';
import * as NoticeTypes from '../../constants/condor/notice-types';

class CondorNoticeBar extends React.PureComponent {
  onCloseNotice = (id) => () => {
    this.props.onRequestCloseNotice(id);
  };

  render() {
    const { notices } = this.props;

    if (notices.length === 0) {
      return null;
    }

    return (
      <div className="condor-notice-bar">
        <div className="condor-notice-bar__notices">
          {notices.map(
            ({
              id,
              message,
              html,
              type,
              closeAutomatically,
              slug,
              trackAsHeapEvent,
            }) => (
              <Notice
                key={id}
                id={id}
                message={message}
                html={html}
                type={type}
                slug={slug}
                trackAsHeapEvent={trackAsHeapEvent}
                closeAutomatically={
                  typeof closeAutomatically !== 'undefined'
                    ? closeAutomatically
                    : type !== NoticeTypes.ERROR
                }
                onCloseNotice={this.onCloseNotice(id)}
              />
            ),
          )}
        </div>
      </div>
    );
  }
}

export default CondorNoticeBar;
