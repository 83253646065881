/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len,
  react/no-find-dom-node
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const SHOW_EVENTS = {
  hover: 'mouseenter',
  focus: 'focus',
};

const HIDE_EVENTS = {
  hover: 'mouseleave',
  focus: 'blur',
};

/**
 * Tooltip
 *
 * @example
 *
 * // Showing on hover (default)
 * <Tooltip content='my tooltip' arrowPosition='left' />
 *
 * // Showing on focus (default)
 * <input id='some-input' />
 * <Tooltip
 *      content='my tooltip'
 *      arrowPosition='left'
 *      trigger='focus'
 *      lockToElement='#some-input'
 *  />
 */
const Tooltip = createReactClass({
  propTypes: {
    content: PropTypes.string.isRequired,
    text: PropTypes.string,
    arrowPosition: PropTypes.string,
    isToggled: PropTypes.bool,
    isToggleable: PropTypes.bool, // If false, tooltip will always be permanently displayed
    classes: PropTypes.string,
    cta: PropTypes.object,
    lockToElement: PropTypes.string, // Optional element to lock to

    /**
     * DEPRECATED
     *
     * To change how the tooltip is toggled, use the `trigger` prop below.
     */
    toggleOn: PropTypes.string, // Events to toggle on (change/blur/etc) if using the above
    contentStyle: PropTypes.object, // Additional styles for tooltip content
    minWidth: PropTypes.string,

    /**
     * Sets the appropriate show/hide listeners on the trigger element.  The
     * default is hover.
     *
     *  - `hover` will show the tooltip on mouseover and hide on mouseleave
     *  - `focus` should be used for input elements.  The tooltip will be
     *  shown on focus and hidden on blur.
     */
    trigger: PropTypes.oneOf(['hover', 'focus']),
  },

  getDefaultProps() {
    return {
      text: '?',
      arrowPosition: 'bottom',
      isToggled: false,
      isToggleable: true,
      lockToElement: null,
      trigger: 'hover',
    };
  },

  getInitialState() {
    return {
      toggled: this.props.isToggled,
      isToggleable: this.props.isToggleable,
    };
  },
  // TODO: refactor the jQuery out of this
  componentDidMount() {
    const root = $(ReactDOM.findDOMNode(this));

    if (this.state.isToggleable && !this.props.lockToElement) {
      this.bindToggleEvents('.m-tooltip--text', root);
    } else if (this.state.isToggleable) {
      // Using an element to lock to
      this.bindToggleEvents(this.props.lockToElement);
    }
    this.adjustTooltipPosition();
  },

  componentWillUnmount() {
    if (typeof this.unBindToggleEvents === 'function') {
      this.unBindToggleEvents();
    }
  },

  bindToggleEvents(...selectors) {
    const $toggler = $(...selectors);

    // Kept for legacy reasons
    if (this.props.toggleOn) {
      $toggler.bind(this.props.toggleOn, this.toggleTip);

      this.unbindToggleEvents = () =>
        $toggler.unbind(this.props.toggleOn, this.toggleTip);
    } else {
      const showEvent = this.getShowEvent();
      const hideEvent = this.getHideEvent();

      $toggler.bind(showEvent, this.show);
      $toggler.bind(hideEvent, this.hide);

      this.unbindToggleEvents = () => {
        $toggler.unbind(showEvent, this.show);
        $toggler.unbind(hideEvent, this.hide);
      };
    }
  },

  getShowEvent() {
    return SHOW_EVENTS[this.props.trigger] || SHOW_EVENTS.hover;
  },

  getHideEvent() {
    return HIDE_EVENTS[this.props.trigger] || HIDE_EVENTS.hover;
  },

  show() {
    this.setState(
      {
        toggled: true,
      },
      this.adjustTooltipPosition,
    );
  },

  hide() {
    this.setState(
      {
        toggled: false,
      },
      this.adjustTooltipPosition,
    );
  },

  adjustTooltipPosition() {
    const root = $(ReactDOM.findDOMNode(this));
    const parent = root.parent();
    const isVisible = parent.css('display') !== 'none';
    let tooltipBubbleEl;
    let newTop;
    let newLeft;

    if (!isVisible) {
      // Display the tooltip container so we can correctly size it
      parent.show();
    }

    // Adjust vertical positioning if the tooltip is toggled on
    if (this.state.toggled && !this.props.lockToElement) {
      if ($.inArray(this.props.arrowPosition, ['left', 'right']) >= 0) {
        tooltipBubbleEl = $('.m-tooltip--bubble', root);
        tooltipBubbleEl.css({ 'min-width': this.props.minWidth });
        const currentTop = tooltipBubbleEl.offset().top;
        const offsetParentTop = tooltipBubbleEl.offsetParent().offset().top;
        if (offsetParentTop === 0) {
          newTop = currentTop - tooltipBubbleEl.height() / 2 + 7;
          tooltipBubbleEl.css('top', newTop);
        } else {
          const tooltipTextEl = $('.m-tooltip--text', root);
          const tooltipTextCenter =
            tooltipTextEl.offset().top + tooltipTextEl.height() / 2;
          const vertAdjustment =
            tooltipTextCenter - (currentTop + tooltipBubbleEl.height() / 2);
          newTop = currentTop - offsetParentTop + vertAdjustment;
          tooltipBubbleEl.css('top', newTop);
        }
      }
    } else if (this.state.toggled && this.props.lockToElement) {
      // height of box - offset of element
      const lockedElNode = $(this.props.lockToElement);
      const lockedElPosition = lockedElNode.position();
      const lockedElWidth = lockedElNode.outerWidth();
      const lockedElHeight = lockedElNode.innerHeight();
      tooltipBubbleEl = $('.m-tooltip--bubble', root);

      // Currently only used for left arrow positioning vs an element
      if (this.props.arrowPosition == 'left') {
        newLeft = lockedElPosition.left + lockedElWidth;
        tooltipBubbleEl.css({ left: newLeft });
        tooltipBubbleEl.css({ 'min-width': this.props.minWidth });
        // Set top value after setting left to account for the new width of the
        // tooltip bubble, since it's auto-width. This puts the vertical center in
        // the correct location
        const lockedElVertMiddle = lockedElPosition.top + lockedElHeight / 2;
        newTop = lockedElVertMiddle - tooltipBubbleEl.innerHeight() / 2;
        tooltipBubbleEl.css({ top: newTop });
      } else if (this.props.arrowPosition == 'bottom') {
        newLeft = Math.abs(
          lockedElPosition.left +
            lockedElWidth / 2 -
            tooltipBubbleEl.outerWidth() / 2,
        );
        tooltipBubbleEl.css({ left: newLeft });
        newTop = lockedElPosition.top - tooltipBubbleEl.height() - 10;
        tooltipBubbleEl.css({ top: newTop });
      }
    }

    if (!isVisible) {
      parent.hide();
    }
  },

  /*
   * Function to toggle tooltip. If using custom events, you can attach a value payload  - 'show' will show the tooltip, anything else will hide
   * This is useful in implementing more complex display logic.
   */

  toggleTip(e) {
    // Using custom event payload
    if (e && e.value) {
      this.setState({
        toggled: e.value === 'show',
      });
      // Every other case
    } else {
      this.setState({
        toggled: !this.state.toggled,
      });
    }

    this.adjustTooltipPosition();
  },

  render() {
    let classes = 'm-tooltip';
    if (this.props.classes) {
      classes += ` ${this.props.classes}`;
    }

    let cta;
    let ctaClass;
    if (this.props.cta) {
      ctaClass = `m-tooltip--bubble--content--cta ${this.props.cta.class || ''}`;
      cta = (
        <a href={this.props.cta.url} className={ctaClass}>
          {this.props.cta.text}
        </a>
      );
    }

    let bubble;
    if (
      !this.state.isToggleable ||
      (this.state.isToggleable && this.state.toggled)
    ) {
      bubble = (
        <span className={`m-tooltip--bubble ${this.props.arrowPosition}`}>
          <span className={'m-tooltip--bubble--arrow left'}></span>
          <span className={'m-tooltip--bubble--arrow top'}></span>
          <span className="m-tooltip--bubble--content">
            {this.props.content} {cta}
          </span>
          <span className={'m-tooltip--bubble--arrow right'}></span>
          <span className={'m-tooltip--bubble--arrow bottom'}></span>
        </span>
      );
    }

    const renderText = this.props.lockToElement ? '' : this.props.text;
    return (
      <span className={classes}>
        <span className="m-tooltip--text">{renderText}</span>
        {bubble}
      </span>
    );
  },
});

module.exports = Tooltip;
