import React from 'react';
import { CodeLine } from '@dropbox/dig-icons/assets';
import { DebugPanel } from 'hello-react/components/debug-panel';
import { GlobalPageProps, setPageProps } from 'hellospa/slices/page-props';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'hellospa/redux/types';

export function CapturePageProps({
  pageProps,
}: {
  pageProps?: GlobalPageProps;
}) {
  const dispatch = useDispatch<Dispatch>();
  React.useEffect(() => {
    if (pageProps) {
      dispatch(setPageProps(pageProps));
    }
  }, [dispatch, pageProps]);

  const PagePropsPanel = () => {
    return (
      <React.Fragment>
        <DebugPanel.SectionHeader>PageProps</DebugPanel.SectionHeader>
        <pre>{JSON.stringify(pageProps, null, 2)}</pre>
      </React.Fragment>
    );
  };

  return (
    <DebugPanel title="PageProps" icon={CodeLine} Component={PagePropsPanel} />
  );
}
