import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonProps } from '@dropbox/dig-components/buttons';

import styles from './skip-to-content-link.module.scss';

export type SkipToContentLinkProps = Partial<ButtonProps>;

export default function SkipToContentLink({
  href = '#site-wrapper', // Note: you should include tabIndex="-1" on the target element
  className = '',
  ...props
}: SkipToContentLinkProps) {
  return (
    <Button
      href={href}
      variant="primary"
      className={classnames(styles.skipToContentLink, className)}
      data-testid="skip-to-main-content-link"
      {...props}
    >
      <FormattedMessage
        id=""
        description="Button to skip to main page content."
        defaultMessage="Skip to main content"
      />
    </Button>
  );
}
