import { ThunkAction } from 'hellospa/redux/types';
import {
  Actions,
  PrepAndSendAction,
} from 'hellospa/page/prep-and-send/data/types';
import {
  ExternalFolderResponse,
  externalFolderDataResponseSchema,
  externalFolderAuthResponseSchema,
  externalServiceAuthStatusSchema,
  UploadIntegrationType,
  ExternalServiceAuthStatusResponse,
} from '../types/integration';

export const externalFolderContents =
  (
    serviceType: string,
    offset: number,
    folderReference: string,
  ): ThunkAction<Promise<ExternalFolderResponse>> =>
  async (dispatch, getState, getExtra) => {
    const { externalFolderContents } = getExtra().appActions.prepAndSend;

    const response = await externalFolderContents(
      serviceType,
      offset,
      folderReference,
    );

    // if response has auth url then validate against auth schema
    if ('authUrl' in response && response.authUrl) {
      return externalFolderAuthResponseSchema.validate(response);
    }

    return externalFolderDataResponseSchema.validate(response);
  };

export const externalServiceAuthStatus =
  (
    serviceType: UploadIntegrationType,
  ): ThunkAction<Promise<ExternalServiceAuthStatusResponse>> =>
  async (dispatch, getState, getExtra) => {
    const { externalServiceAuthStatus } = getExtra().appActions.prepAndSend;

    const response = await externalServiceAuthStatusSchema.validate(
      await externalServiceAuthStatus(serviceType),
    );

    return response;
  };

export const setIntegrationStatus = (
  integration: UploadIntegrationType,
  status: boolean,
): PrepAndSendAction => ({
  type: Actions.UpdateIntegrationStatus,
  payload: {
    integration,
    status,
  },
});
