import { ReactElement, ReactNode } from 'react';
import { OverlayAnchorRef } from '@dropbox/dig-components/overlay';
import { WorkflowStatusType } from 'hello-react/web-app-client/namespace/integration';

export enum IntegrationContext {
  HubSpot = 'hubspot',
}

export enum IntegrationReturnToLabel {
  HubSpot = 'HubSpot',
  SignInPage = 'sign in page',
  Default = 'previous page',
}

export enum ThirdPartyCookiesUrl {
  Chrome = 'https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop',
  Edge = 'https://support.microsoft.com/en-us/microsoft-edge/temporarily-allow-cookies-and-site-data-in-microsoft-edge-597f04f2-c0ce-f08c-7c2b-541086362bd2',
  Firefox = 'https://support.mozilla.org/en-US/kb/third-party-cookies-firefox-tracking-protection',
  Safari = 'https://support.apple.com/guide/safari/prevent-cross-site-tracking-sfri40732/16.1/mac/13.0',
}

export type WorkflowStatusProps = {
  statusType: WorkflowStatusType;
  actionUrl?: string;
  actionEventOverride?: () => void;
  context?: string;
  originatedAction?: PrepAndSendOriginatedAction;
};

export type WorkflowStatusData = {
  title: string;
  description?: string | ReactElement | ReactNode;
  image?: ReactElement;
  actionButtonLabel?: string;
  secondaryActionButtonLabel?: string;
  displayHSLogo?: boolean;
  actionEvent?: () => void;
  secondaryActionEvent?: () => void;
};

export enum PrepAndSendOriginatedAction {
  CreateOneTimeDoc = 'createOneTimeDoc',
  CreateTemplate = 'createTemplate',
  SendTemplate = 'sendTemplate',
  EditTemplate = 'editTemplate',
}

export type PrepAndSendLoadProps = {
  response: PrepAndSendSuccess;
  originatedAction: PrepAndSendOriginatedAction;
  context: string;
};

export type PrepAndSendSuccess = {
  success: true;
  claimUrl: string;
  clientId: string;
  signatureRequestId?: string;
};

export type HsErrorResponse = {
  error: string;
  statusCode: number;
  success: boolean;
};

export type IntegrationSpaProps = {
  associatedObjectId: number;
  associatedObjectType: string;
  identifier: string;
  context: string;
};

export type SendRemindersPageProps = {
  associatedObjectId: number;
  associatedObjectType: string;
  identifier: string;
  context: string;
  signatureRequestId: string;
  getRemainingSigners: boolean;
};

export type SignatureRequest = {
  signerName: string;
  signerEmailAddress: string;
};

export type SignatureRequestSuccess = {
  success: true;
  signatures: Array<SignatureRequest>;
  title: string;
  isComplete: boolean;
  isDeclined: boolean;
};

export type SignatureRequestLoadProps = {
  response: SignatureRequestSuccess;
};

export type IntegrationNamespaceState = {
  selectedTemplateId: string | null;
  workflowError: WorkflowStatusType | null;
  statusPageEmail: string | null;
  templateListEmpty: boolean;
  renderCreateTemplate: boolean;
  renderCreateOneTimeDoc: boolean;
};
export interface SetWorkflowError {
  type: Actions.SetWorkflowError;
  payload: WorkflowStatusType | null;
}

export interface SetSelectedTemplateId {
  type: Actions.SetSelectedTemplateId;
  payload: string;
}

export interface SetStatusPageEmail {
  type: Actions.SetStatusPageEmail;
  payload: string;
}

export interface SetTemplateListEmpty {
  type: Actions.SetTemplateListEmpty;
  payload: boolean;
}

export interface SetRenderCreateTemplate {
  type: Actions.SetRenderCreateTemplate;
  payload: boolean;
}

export interface SetRenderCreateOneTimeDoc {
  type: Actions.SetRenderCreateOneTimeDoc;
  payload: boolean;
}

export enum Actions {
  SetWorkflowError = 'integration/SetWorkflowError',
  SetSelectedTemplateId = 'integration/SetSelectedTemplateId',
  SetTemplateListEmpty = 'integration/SetTemplateListEmpty',
  SetRenderCreateTemplate = 'integration/SetRenderCreateTemplate',
  SetStatusPageEmail = 'integration/SetStatusPageEmail',
  SetRenderCreateOneTimeDoc = 'integration/SetRenderCreateOneTimeDoc',
}

export type IntegrationAction =
  | SetSelectedTemplateId
  | SetWorkflowError
  | SetTemplateListEmpty
  | SetRenderCreateTemplate
  | SetStatusPageEmail
  | SetRenderCreateOneTimeDoc;

export type Template = {
  templateId: string;
  title: string;
  isLocked: boolean;
  canEdit: boolean;
};

export type ListInfo = {
  numPages: number;
  numResults: number;
  page: number;
  pageSize: number;
  maxNumTemplates?: number;
};

export type TemplateList = {
  templates: Template[];
  listInfo: ListInfo;
};

export type CancelSignatureRequestPageProps = {
  associatedObjectId: number;
  associatedObjectType: string;
  identifier: string;
  context: string;
  signatureRequestId: string;
  getRemainingSigners: boolean;
};

export type PageContainerTooltipProps = {
  tooltipTriggerRef: OverlayAnchorRef;
  title: object;
  body: object;
  link: string;
};

export interface EditTemplateResponse {
  editUrl: string;
  clientId: string;
  success: true;
}

export function isEditTemplate(x: any): x is EditTemplateResponse {
  return x && x.success && x.editUrl !== undefined && x.clientId !== undefined;
}

export type UseGDriveNewOAuthResponse = {
  useNewOAuth: boolean;
  inABTest: boolean;
};
