/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  func-names,
  guard-for-in,
  import/no-extraneous-dependencies,
  no-restricted-syntax
*/
import React from 'react';
import ReactDOM from 'react-dom';
import HSIntlProvider from 'hellospa/common/hs-intl-provider';

/**
 * ui specific actions
 */

module.exports = {
  /**
   */

  showModal(modalComponent, options, modalClassName) {
    const div = document.createElement('div');
    div.setAttribute('padding', '15px');

    ReactDOM.render(<HSIntlProvider>{modalComponent}</HSIntlProvider>, div);

    const $div = $(div);

    // not very react-like. Works for now
    $div.find('.close').click(() => {
      $.fancybox.close();
    });

    const baseOptions = {
      // 'type'            : 'iframe',
      showCloseButton: true,
      height: 300,
      width: 490,
    };

    if (options) {
      for (const k in options) {
        baseOptions[k] = options[k];

        if (
          typeof options.showCloseButton !== 'undefined' &&
          !options.showCloseButton
        ) {
          // if there is no close button then remove padding
          baseOptions.baseClass = 'fancybox-wrapper-v3';
        }
      }
    }

    baseOptions.onStart = function () {
      $('#fancybox-overlay').addClass(
        `m-fancybox-modal-overlay ${modalClassName} is-hidden`,
      );
      $('#fancybox-wrap').addClass(
        `m-fancybox-modal-wrap ${modalClassName} is-hidden`,
      );
      $('#fancybox-inner').addClass(`m-fancybox-modal-inner ${modalClassName}`);
    };

    baseOptions.onComplete = function () {
      $('#fancybox-overlay, #fancybox-wrap').removeClass('is-hidden');
      if (
        options &&
        options.onComplete &&
        typeof options.onComplete === 'function'
      ) {
        options.onComplete();
      }
    };

    baseOptions.onClosed = function () {
      $('#fancybox-overlay, #fancybox-wrap, #fancybox-inner').attr('class', '');
      if (
        options &&
        options.onClosed &&
        typeof options.onClosed === 'function'
      ) {
        options.onClosed();
      }
    };

    $div.fancybox(baseOptions).click();
    $div.fancybox(baseOptions).unbind('click');
  },

  /**
   */

  alert() {},
};
