import * as React from 'react';

function WarningCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx="12" cy="12" r="8" fill="#FCB212" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0065 14.462C11.4919 14.462 11.0899 14.076 11.0577 13.5614L10.8165 8.2383C10.7843 7.56287 11.3311 7 11.9904 7C12.6659 7 13.2127 7.54678 13.1805 8.2383L12.9554 13.5614C12.9232 14.076 12.5051 14.462 12.0065 14.462ZM10.72 16.7135C10.72 16.0058 11.2989 15.4108 12.0065 15.4108C12.7141 15.4108 13.2931 16.0058 13.2931 16.7135C13.2931 17.405 12.7141 18 12.0065 18C11.2989 18 10.72 17.405 10.72 16.7135Z"
        fill="#FFFFFF"
      />
    </svg>
  );
}

export default React.memo(WarningCircleIcon);
