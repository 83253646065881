import * as uuid from 'uuid';
import { ThunkAction } from 'hellospa/redux/types';
import {
  Actions,
  PrepAndSendAction,
} from 'hellospa/page/prep-and-send/data/types';
import {
  UnreachableError,
  PartialUnion,
} from 'js/sign-components/common/ts-utils';
import * as selectors from 'hellospa/page/prep-and-send/data/selectors';
import { CCRole, CCTypes, CCEmail, CC } from '../types/cc';
import { Recipient } from '../types/recipient';

// Generates new CCRole object, does not persist to Redux
export const generateCCRole = (payload: Partial<CCRole> = {}): CCRole => ({
  id: uuid.v4(),
  type: CCTypes.CCRole,
  name: '',
  ...payload,
});

// Generates new CCEmail object, does not persist to Redux
export const generateCCEmail = (payload: Partial<CCEmail> = {}): CCEmail => ({
  id: uuid.v4(),
  type: CCTypes.CCEmail,
  email: '',
  ...payload,
});

export const createCC =
  (cc: PartialUnion<CC, 'type'>): ThunkAction<CC> =>
  (dispatch, getState) => {
    const currentCCs = selectors.getCCs(getState());
    switch (cc.type) {
      case CCTypes.CCEmail: {
        const payload = generateCCEmail(cc);
        if (payload.role && payload.role.name) {
          const isDuplicate = currentCCs.some((cc: CC) => {
            if ('role' in cc && cc.role && 'role' in payload && payload.role) {
              return cc.role.name === payload.role.name;
            }
            return false;
          });
          if (!isDuplicate) {
            dispatch({ type: Actions.CreateCC, payload });
          }
        } else {
          dispatch({ type: Actions.CreateCC, payload });
        }
        return payload;
      }
      case CCTypes.CCRole: {
        const payload = generateCCRole(cc);
        dispatch({ type: Actions.CreateCC, payload });
        return payload;
      }
      default:
        // If this doesn't throw or error TS thinks payload might not get set.
        throw new UnreachableError(cc);
    }
  };

export const setCCs =
  (ccs: Array<PartialUnion<CC, 'type'>>): ThunkAction<void> =>
  (dispatch) => {
    const payload = ccs.map((cc) => {
      switch (cc.type) {
        case CCTypes.CCEmail: {
          const payload = generateCCEmail(cc);
          dispatch({ type: Actions.CreateCC, payload });
          return payload;
        }
        case CCTypes.CCRole: {
          const payload = generateCCRole(cc);
          dispatch({ type: Actions.CreateCC, payload });
          return payload;
        }
        default:
          // If this doesn't throw or error TS thinks payload might not get set.
          throw new UnreachableError(cc);
      }
    });

    dispatch({
      type: Actions.SetCCs,
      payload,
    });
  };

export const deleteCC = (id: CC['id']): PrepAndSendAction => ({
  type: Actions.DeleteCC,
  payload: id,
});

export const updateCC =
  (id: Recipient['id'], updates: Partial<CC>): ThunkAction<void> =>
  async (dispatch) => {
    dispatch({
      type: Actions.UpdateCC,
      payload: {
        id,
        updates,
      },
    });
  };
