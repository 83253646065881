import React from 'react';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import type { WebAppClientNamespaces } from 'hello-react/web-app-client/build-web-app-client';
import { Store, ExtraArgument } from './types';
import {
  TEMPLATE_NAMESPACE_KEY,
  TS_EXAMPLE_NAMESPACE_KEY,
  MOAR_NAMESPACE_KEY,
  PREP_AND_SEND_NAMESPACE_KEY,
  NOTIFICAION_BANNER_NAMESPACE_KEY,
  DOCUMENT_PREVIEW_NAMESPACE_KEY,
  SHAREPOINT_NAMESPACE_KEY,
  INTEGRATION_NAMESPACE_KEY,
  RECENT_TEMPLATES_NAMESPACE_KEY,
  StoreShape,
} from './namespaces';
import templateReducer from 'hellospa/page/templates/template-slice';
import { tsExampleReducer } from 'hellospa/components/ts-example/data/reducer';
import moarReducer from 'hellospa/page/moar/data/slice';
import { prepAndSendReducer } from 'hellospa/page/prep-and-send/data/reducer';
import { notificationBannerReducer } from 'hellospa/components/notification-banner/data/reducer';
import { documentPreviewReducer } from 'js/sign-components/signer-preview/data/reducer';
import { sharePointReducer } from 'hellospa/components/sharepoint/data/reducer';
import pagePropsReducer from 'hellospa/slices/page-props';
import { FeatureFlags } from 'js/sign-components/common/feature-flags';
import { integrationReducer } from 'hellospa/components/integration/data/reducer';
import recentTemplatesReducer from 'hellospa/components/dashboard/recent-templates/recent-templates-slice';

const extraArguments = {
  appActions: {} as WebAppClientNamespaces,
  featureFlags: {} as FeatureFlags,
  hasFeatureFlag: (_: keyof FeatureFlags) => false,
};
/* istanbul ignore next */
const getExtraArguments: ExtraArgument = () => extraArguments;

// Exported for use in tests
export function constructStore(
  getExtra = getExtraArguments,
): Store<StoreShape> {
  const reducer = combineReducers({
    pageProps: pagePropsReducer,
    [TEMPLATE_NAMESPACE_KEY]: templateReducer,
    [MOAR_NAMESPACE_KEY]: moarReducer,
    [TS_EXAMPLE_NAMESPACE_KEY]: tsExampleReducer,
    [PREP_AND_SEND_NAMESPACE_KEY]: prepAndSendReducer,
    [NOTIFICAION_BANNER_NAMESPACE_KEY]: notificationBannerReducer,
    [DOCUMENT_PREVIEW_NAMESPACE_KEY]: documentPreviewReducer,
    [SHAREPOINT_NAMESPACE_KEY]: sharePointReducer,
    [INTEGRATION_NAMESPACE_KEY]: integrationReducer,
    [RECENT_TEMPLATES_NAMESPACE_KEY]: recentTemplatesReducer,
  });

  return configureStore({
    reducer,
    devTools: {
      // Use of literal "hellosign" here is acceptable. Questions? #ask-hs-frontend.
      // eslint-disable-next-line no-restricted-syntax
      name: 'hellosign',
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: getExtra,
        },
        serializableCheck: false,
      }),
  });
}

type Props = React.PropsWithChildren<{
  getExtra?: ExtraArgument;
  store?: Store;
}>;

type TestProps = Props & {
  // pass a mock object for the test
  appActions?: any;
};
/* istanbul ignore next */
export function TestStoreProvider({ children, appActions }: TestProps) {
  const storeRef = React.useRef<Store>();
  if (!storeRef.current) {
    storeRef.current = constructStore(() => ({
      ...extraArguments,
      appActions,
    }));
  }

  return <Provider store={storeRef.current}>{children}</Provider>;
}
