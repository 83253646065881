import './wdyr';

// Style
// Page Base
import 'hellospa/dig/vis2020';

import React from 'react';
import ReactDOM from 'react-dom';
import parseDomNodeContents from 'common/utils/parse-dom-node-contents';
import configureRaven from 'common/utils/sentry';
import Application from 'hellosign/detached-application';
import { isProduction } from 'js/sign-components/common/ts-utils';
import Root from './root';
import runLegacy from './legacy';
import './pending-translations';

const rootProps = '__root-props__';
const app = new Application();
const initialState = parseDomNodeContents(rootProps);
if (Array.isArray(initialState.pageProps)) {
  // PHP confuses Arrays (indexed collections) with Objects (key/value pairs).
  // If no keys have been added to pageProps it generates an array and causes a
  // propTypews warning/error.
  initialState.pageProps = {};
}

/**
 * The main render function. Receives the initial state and renders the root
 * React component.
 */
function render(initialState) {
  ReactDOM.render(
    <Root app={app} {...initialState} />,
    document.getElementById('root'),
  );
}

/**
 * Runs the app. Takes the initial state and does any final initialization
 * logic before calling the render function.
 */
function run(initialState) {
  const featureFlags = initialState?.featureFlags;

  configureRaven(
    {
      id: initialState?.user?.guid,

      // We no longer include email on production so as to
      // not send personally identifiable information (PII)
      // to Sentry. See DEV-10906.
      ...(!isProduction()
        ? {
            email: initialState?.user?.emailAddress,
          }
        : {}),
    },
    featureFlags,
  );

  render(initialState);
}

const runAll = () => {
  run(initialState);
  if (initialState.user) {
    runLegacy(initialState);
  }
};

runAll();
