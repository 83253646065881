/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-extraneous-dependencies
*/
// Loader for using WebFont via NPM for the signature modal in the inAppLayout

import WebFont from 'webfontloader';

if (!window.WebFont) {
  window.WebFont = WebFont;
}
