import { createSimpleDataContext } from 'js/sign-components/common/create-simple-data-context';
import React, { useState } from 'react';

export type EmailValidationContextShape = {
  emailValidationErrors: Record<string, string | null>;
  emailValidationBypassList: Record<string, boolean>;
  updateEmailValidationBypassList: (key: string, value: boolean) => void;
  updateEmailValidationErrors: (key: string, error: string | null) => void;
};

export const { Provider, useContext: useEmailValidationContext } =
  createSimpleDataContext<EmailValidationContextShape>(
    'EmailValidationContext',
  );

const EmailValidationProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [emailValidationErrors, setEmailValidationErrors] = useState<
    Record<string, string | null>
  >({});
  const [emailValidationBypassList, setEmailValidationBypassList] = useState<
    Record<string, boolean>
  >({});

  const updateEmailValidationBypassList = async (
    key: string,
    value: boolean,
  ) => {
    setEmailValidationBypassList((prevEmailValidationBypassList) => ({
      ...prevEmailValidationBypassList,
      [key]: value,
    }));
  };

  const updateEmailValidationErrors = async (
    key: string,
    error: string | null,
  ) => {
    setEmailValidationErrors((prevEmailValidationErrors) => ({
      ...prevEmailValidationErrors,
      [key]: error,
    }));
  };

  return (
    <>
      <Provider
        value={{
          emailValidationErrors,
          emailValidationBypassList,
          updateEmailValidationBypassList,
          updateEmailValidationErrors,
        }}
      >
        {children}
      </Provider>
    </>
  );
};

export default EmailValidationProvider;
