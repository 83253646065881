import { FeatureFlags } from 'js/sign-components/common/feature-flags';
import invariant from 'invariant';

/**
 * JSON-parses the inner HTML of a node in the DOM.  Used for retrieving initial
 * client-side state rendered out by the server.
 */
export default function parseDomNodeContents(id: string): unknown {
  const element = document.getElementById(id);

  if (element && element.textContent !== null) {
    return JSON.parse(element.textContent);
  } else {
    throw new Error(`Could not parse contents of node with ID ${id}.`);
  }
}

export type BundleProps = {
  locale: string;
  csrfToken: string;
  siteCode: string;
  featureFlags: FeatureFlags;
  userGuid?: string;
};
export function parseBundleProps(): BundleProps {
  const id = '__bundle-props__';
  const el = document.getElementById(id);

  if (el) {
    const bundleProps = JSON.parse(el.innerHTML);
    invariant(typeof bundleProps === 'object', 'Expected to find an object');
    invariant(typeof bundleProps.locale === 'string', 'Missing locale');
    invariant(typeof bundleProps.csrfToken === 'string', 'Missing csrfToken');
    invariant(typeof bundleProps.siteCode === 'string', 'Missing siteCode');
    invariant(
      typeof bundleProps.featureFlags === 'object',
      'Missing featureFlags',
    );
    return bundleProps;
  } else {
    throw new Error(`Could not parse contents of node with ID ${id}.`);
  }
}
