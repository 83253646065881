import { CsvEditorRow } from '../types/csv-editor-row';
import { CsvEditorError } from '../types/csv-editor-error';
import { CsvEditorRowMeta } from '../types/csv-editor-row-meta';

export const initialState = {
  rows: [] as CsvEditorRow[],
  rowHeaders: [] as string[],
  errors: [] as CsvEditorError[],
  rowMetadata: {} as Record<string, CsvEditorRowMeta>,
  uploadErrors: [] as string[],
};
export const CSV_EDITOR_KEY = 'csvEditor' as const;
export type CsvEditorState = typeof initialState;

export type UpdateCellPayload = {
  rowIdx: number;
  column: string;
  value: string;
};

export type SetEditorCsvData = {
  rows: CsvEditorRow[];
  rowHeaders: string[];
  errors: CsvEditorError[];
};
