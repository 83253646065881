import * as yup from 'yup';

import type { Flags, FlagsActions } from './flags';
import { RequestTypes, AnalyticsRequestType } from './request-types';
import type { Settings, SettingsActions } from './settings';
import type { Meta, MetaActions } from './meta';
import type { Document, DocumentActions } from './document';
import type { FileActions, UserFilesKeyed } from './file';
import type { RecipientActions, RecipientKeyed } from './recipient';
import type { WorkflowActions, Workflow } from './workflow';
import type { CoverPageActions, CoverPage } from './coverPage';
import type { IntegrationActions } from './integration';
import type {
  DataSchema,
  TemplateResponse,
} from 'hello-react/web-app-client/namespace/prep-and-send';
import type { UserActions, User } from './user';
import type { ApiAppActions, ApiApp } from './api-app';
import type { BulkSendActions, BulkSendData } from './bulk-send';
import type { CCActions, CC } from './cc';
import type { DeepIntegrationActions } from './deep-integration';
import type {
  DeepIntegrationErrorDescriptor,
  FileErrorDescriptor,
} from 'hellospa/components/prep-and-send/file-uploader/messages';
import type { EmbeddedData } from './embedded';
import type { Rule } from 'signer-app/conditional-logic/types';
import type { Page, Field } from 'signer-app/types/editor-types';
import {
  CSV_EDITOR_KEY,
  CsvEditorState,
} from 'hellospa/page/prep-and-send/data/csv-editor/csv-editor-types';

export const transmissionGroupGuidSchema = yup.string().trim().required();

export { RequestTypes, AnalyticsRequestType };

export type Contact = {
  id: string;
  name: string;
  value: string;
  numUses: number;
  type: 'F' | 'E';
};

export type Template = {
  id: string;
  title: string;
  isLocked: boolean;
  isOrdered: boolean;
  dateFormat: string;
  allowReassign: boolean;
  roles: string[];
};

export type EditorFields = {
  pages: Page[];
  fields: Field[];
  pdfFields: Field[];
  rules: Rule[];
  initialPageRotation: number;
};

export type PrepAndSendNamespaceState = {
  // Contacts/referAFriend should probably be moved out of the P&S namespace.
  // It's not exclusive to P&S because the import contacts modal is accessible
  // from the user's profile. Right now it doesn't seem worth the effort of
  // creating a new namespace because it's not simple and changes a bunch of
  // files. When I add @react-js/toolkit I intend to make it easier to make new
  // namespaces.
  readonly referAFriendURL?: string;
  readonly contacts: {
    readonly lastUpdated: number;
    // The contacts are sorted by numUses
    readonly sortedData: Contact[];
  };
  readonly transmissionGroupGuid: string;
  readonly signatureRequestId: string;
  readonly document: Document;
  readonly templates: TemplateResponse[];
  // The Partials here acknowledge that not every ID will exist
  readonly files: UserFilesKeyed;
  readonly editorFields: EditorFields;
  readonly editorFeatures: Array<string>;
  readonly coverPage: Partial<CoverPage>;
  readonly recipients: Array<RecipientKeyed>;
  readonly ccs: Array<CC>;
  readonly user: User;
  readonly apiApp: undefined | ApiApp;
  readonly flags: Flags;
  readonly settings: Settings;
  readonly meta: Meta;
  readonly workflow: Workflow;
  readonly bulkSendData: BulkSendData;
  readonly integrationError?: FileErrorDescriptor;
  readonly deepIntegrationError?: DeepIntegrationErrorDescriptor;
  readonly embeddedData?: EmbeddedData;
  readonly numFields: number;
  readonly nextClickTracker: number;
  readonly requestIsFinished: boolean;
  readonly [CSV_EDITOR_KEY]: CsvEditorState;
};

export enum Actions {
  SetReferAFriendURL = 'prep-and-send/SetReferAFriendURL',
  UpdateContacts = 'prep-and-send/UpdateContacts',
  SetValidationErrors = 'prep-and-send/SetValidationErrors',
  Init = 'prep-and-send/Init',
  UpdateEditorFields = 'prep-and-send/UpdateEditorFields',
  SetFlags = 'prep-and-send/SetFlags',
  UpdateSettings = 'prep-and-send/UpdateSettings',
  UpdateMeta = 'prep-and-send/UpdateMeta',
  UpdateUser = 'prep-and-send/UpdateUser',
  UpdateApiApp = 'prep-and-send/UpdateApiApp',
  SetWorkflow = 'prep-and-send/SetWorkflow',
  CreateRecipient = 'prep-and-send/CreateRecipient',
  UpdateRecipient = 'prep-and-send/UpdateRecipient',
  UpdateCC = 'prep-and-send/UpdateCC',
  CreateCC = 'prep-and-send/CreateCC',
  SetCCs = 'prep-and-send/SetCCs',
  DeleteCC = 'prep-and-send/DeleteCC',
  DeleteRecipient = 'prep-and-send/DeleteRecipient',
  ResetRecipients = 'prep-and-send/ResetRecipients',
  CreateAttachment = 'prep-and-send/CreateAttachment',
  UpdateAttachment = 'prep-and-send/UpdateAttachment',
  DeleteAttachment = 'prep-and-send/DeleteAttachment',
  UpdateDocument = 'prep-and-send/UpdateDocument',
  CreateFile = 'prep-and-send/CreateFile',
  ConvertFile = 'prep-and-send/ConvertFile',
  UpdateFile = 'prep-and-send/UpdateFile',
  DeleteFile = 'prep-and-send/DeleteFile',
  UploadFile = 'prep-and-send/UploadFile',
  UpdateField = 'prep-and-send/UpdateField',
  UseTemplate = 'prep-and-send/UseTemplate',
  DeleteTemplate = 'prep-and-send/DeleteTemplate',
  ExternalFile = 'prep-and-send/ExternalFile',
  UpdateIntegrationStatus = 'prep-and-send/UpdateIntegrationStatus',
  PollFile = 'prep-and-send/PollFile',
  ReorderFile = 'prep-and-send/ReorderFile',
  UpdateCoverPage = 'prep-and-send/UpdateCoverPage',
  DeleteCoverPage = 'prep-and-send/DeleteCoverPage',
  CreateCoverPage = 'prep-and-send/CreateCoverPage',
  SynchronizeStore = 'prep-and-send/SynchronizeStore',
  SetBulkSendData = 'prep-and-send/SetBulkSendData',
  ResetSignerFile = 'prep-and-send/ResetBulkSendData',
  SetIntegrationError = 'prep-and-send/SetIntegrationError',
  SetDeepIntegrationError = 'prep-and-send/SetDeepIntegrationError',
  SetIntegrationExternalFilename = 'prep-and-send/SetIntegrationExternalFilename',
  SetIntegrationDefaultSavePath = 'prep-and-send/SetIntegrationDefaultSavePath',
  UpdateNumberFields = 'prep-and-send/UpdateNumberFields',
  // TODO: clean up when DEV-12786 is done
  UpdateNextClickTracker = 'prep-and-send/UpdateNextClickTracker',
  UpdateRequestIsFinished = 'prep-and-send/UpdateRequestIsFinished',
  UpdateShouldConvertDocToTemplate = 'prep-and-send/UpdateShouldConvertDocToTemplate',
  BulkSendInitialize = 'bulksend/Initialize',
  SetSignatureRequestId = 'prep-and-send/SetSignatureRequestId',
}

export enum DropzoneDisplayTypes {
  MenuLayout = 'menu',
  InlineLayout = 'inline',
}

export interface InitAction {
  type: Actions.Init;
  payload: DataSchema;
}

export interface UpdateEditorFieldsAction {
  type: Actions.UpdateEditorFields;
  payload: DataSchema['data']['editorFields'];
}

export interface SetReferAFriendURLAction {
  type: Actions.SetReferAFriendURL;
  payload: string;
}
export interface UpdateContactsAction {
  type: Actions.UpdateContacts;
  payload: {
    lastUpdated: number;
    sortedData: Contact[];
  };
}
export interface UpdateNumberFieldsAction {
  type: Actions.UpdateNumberFields;
  payload: number;
}

// TODO: clean up when DEV-12786 is done
export interface UpdateNextClickTracker {
  type: Actions.UpdateNextClickTracker;
  payload: number;
}
export interface UpdateRequestIsFinished {
  type: Actions.UpdateRequestIsFinished;
  payload: boolean;
}

export interface UpdateShouldConvertDocToTemplate {
  type: Actions.UpdateShouldConvertDocToTemplate;
  payload: boolean;
}

export interface BulkSendInitializeSuccess {
  type: Actions.BulkSendInitialize;
  payload: string;
}

export interface ResetSignerFileAction {
  type: Actions.ResetSignerFile;
}

export interface SetSignatureRequestId {
  type: Actions.SetSignatureRequestId;
  payload: string;
}

export type PrepAndSendAction =
  | InitAction
  | UpdateEditorFieldsAction
  | RecipientActions
  | CCActions
  | FlagsActions
  | SettingsActions
  | MetaActions
  | UserActions
  | ApiAppActions
  | DocumentActions
  | FileActions
  | UpdateContactsAction
  | SetReferAFriendURLAction
  | CoverPageActions
  | WorkflowActions
  | IntegrationActions
  | BulkSendActions
  | DeepIntegrationActions
  | UpdateNumberFieldsAction
  // TODO: clean up when DEV-12786 is done
  | UpdateNextClickTracker
  | UpdateRequestIsFinished
  | UpdateShouldConvertDocToTemplate
  | BulkSendInitializeSuccess
  | ResetSignerFileAction
  | SetSignatureRequestId;
