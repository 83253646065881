import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { StoreShape } from 'hellospa/redux/types';
import { CsvEditorRow } from 'hellospa/page/prep-and-send/data/types/csv-editor-row';
import { CsvEditorError } from 'hellospa/page/prep-and-send/data/types/csv-editor-error';
import { CsvEditorRowMeta } from 'hellospa/page/prep-and-send/data/types/csv-editor-row-meta';
import { BulkSendService } from 'hellospa/page/prep-and-send/data/service/bulk-send-service';
import { CsvEditorSelectors } from './csv-editor-selectors';
import {
  CSV_EDITOR_KEY,
  CsvEditorState,
  initialState,
  SetEditorCsvData,
  UpdateCellPayload,
} from './csv-editor-types';
import * as selectors from '../selectors';

export const autoSave = createAsyncThunk(
  'prep-and-send/csv/save',
  async (_: undefined, { getState, rejectWithValue, dispatch }) => {
    const state = getState() as StoreShape;
    const bulkSendDataKey = selectors.getBulkSendDataKey(state);
    const csvRows = CsvEditorSelectors.getRows(state);
    try {
      const response = await BulkSendService.updateSigners(
        csvRows,
        bulkSendDataKey,
      );
      if (response?.success) {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          setCsvEditorErrors({
            // coerce Yup's `Ref` type to CsvEditorError
            errors: response.rowErrors as unknown as CsvEditorError[],
          }),
        );
      }
      return null;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const { actions, reducer } = createSlice({
  name: CSV_EDITOR_KEY,
  initialState,
  reducers: {
    setCsvEditorData(state, action: PayloadAction<SetEditorCsvData>) {
      const { rows, rowHeaders, errors } = action.payload;
      state.rows = rows;
      state.rowHeaders = rowHeaders;
      state.errors = errors;
      state.rowMetadata = rows.reduce(
        (acc: Record<string, CsvEditorRowMeta>, row: CsvEditorRow, idx) => {
          acc[idx] = {
            dirty: [],
            fixed: [],
            saving: [],
            submitted: false,
          };
          return acc;
        },
        {},
      );
    },
    setCsvEditorErrors(
      state,
      action: PayloadAction<{ errors: CsvEditorError[] }>,
    ) {
      const { errors } = action.payload;
      state.errors = errors;
    },
    setCsvEditorUploadErrors(state, action: PayloadAction<string[]>) {
      state.uploadErrors = action.payload;
    },
    updateCell(state, action: PayloadAction<UpdateCellPayload>) {
      const { rowIdx, column, value } = action.payload;
      state.rows = state.rows.map(
        (row: CsvEditorRow, idx: number): CsvEditorRow => {
          if (idx !== rowIdx) {
            return row;
          }
          row[column] = value;
          state.rowMetadata[rowIdx].dirty = uniq(
            state.rowMetadata[rowIdx].dirty.concat(column),
          );
          state.rowMetadata[rowIdx].submitted = false;
          return row;
        },
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(autoSave.pending, (state: CsvEditorState) => {
        const { rowMetadata } = state;
        for (const rowGuid of Object.keys(rowMetadata)) {
          const metadata = rowMetadata[rowGuid];
          state.rowMetadata[rowGuid] = {
            saving: metadata.dirty,
            fixed: metadata.fixed,
            dirty: [],
            submitted: true,
          };
        }
      })
      .addCase(autoSave.rejected, (state) => {
        const { rowMetadata } = state;
        for (const rowGuid of Object.keys(state.rowMetadata)) {
          const metadata = rowMetadata[rowGuid];
          state.rowMetadata[rowGuid] = {
            dirty: metadata.saving,
            saving: [],
            fixed: metadata.fixed,
            submitted: true,
          };
        }
      })
      .addCase(autoSave.fulfilled, (state) => {
        const { rowMetadata } = state;
        for (const rowGuid of Object.keys(rowMetadata)) {
          const metadata = rowMetadata[rowGuid];
          state.rowMetadata[rowGuid] = {
            fixed: uniq([...metadata.fixed, ...metadata.saving]),
            saving: [],
            dirty: [],
            submitted: false,
          };
        }
      });
  },
});

export const {
  setCsvEditorData,
  setCsvEditorErrors,
  setCsvEditorUploadErrors,
  updateCell,
} = actions;
export default reducer;
