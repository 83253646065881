// https://developers.heap.io/reference#track
export enum UserSurveyEvent {
  SignatureRequestSent = 'signature_request_sent',
  DocsRedesignPaidControl = 'docs_redesign_paid_control',
  TemplatesPageClick = 'templates_page_click',
  StarredTemplatesPageClick = 'starred_templates_page_click',
  BulkInviteButtonClick = 'bulk_invite_button_click',
  InviteUserModalCancelClick = 'invite_user_modal_cancel_click',
  InviteUserModalInviteButtonClick = 'invite_user_modal_invite_user_click',
  ExitWhatNeedsToBeSignedScreen = 'exit_what_needs_to_be_signed_screen',
  ExitWhoNeedsToSignScreen = 'exit_who_needs_to_sign_screen',
  ExitEditor = 'exit_editor',
  ExitFinalSendForSigScreen = 'exit_final_send_for_sig_screen',
  APIDashboardCSAT = 'hellosign_api_dashboard_csat',
  SignAIServiceOutToOffice = 'sign_ai_out_of_office_survey',
  SignAIOutToOfficeSummaryAndTranslation = 'sign_ai_out_of_office_survey_summary_and_translation',

  // Use of literal "hellosign" here is acceptable. Questions? #ask-hs-frontend.
  // eslint-disable-next-line no-restricted-syntax
  CompletedHelloSignFlow = 'completed_hellosign_flow', // eslint-disable-line no-restricted-syntax
  BulkSendImprovementCSAT = 'Bulk_send_improvement_CSAT',
}

export enum UserSurveyPosition {
  BottomLeft = 'bottomLeft', // UserLeap default
  BottomRight = 'bottomRight',
  TopLeft = 'topLeft',
  TopRight = 'topRight',
  Center = 'center',
}

declare global {
  interface Window {
    UserLeap?: {
      (call: string, value: string | string[]): () => void;
      (call: string, key: string, value: string | number): () => void;
      framePosition: string;
    };
  }
}

function trackEvent(eventName: UserSurveyEvent) {
  if ('UserLeap' in window && window.UserLeap && eventName) {
    window.UserLeap('track', eventName);
  }
}

function trackIntegrationEvent(
  eventName: UserSurveyEvent,
  integrationContext: string,
) {
  if (window.UserLeap) {
    window.UserLeap('track', `${eventName}_${integrationContext}`);
  }
}

function setPosition(position: UserSurveyPosition) {
  if ('UserLeap' in window && window.UserLeap) {
    window.UserLeap.framePosition = position;
  }
}

function addAttribute(key: string, value: string | number) {
  if ('UserLeap' in window && window.UserLeap) {
    window.UserLeap('setAttribute', key, value);
  }
}

function removeAttribute(key: string) {
  if ('UserLeap' in window && window.UserLeap) {
    window.UserLeap('removeAttributes', [key]);
  }
}

export const UserSurvey = {
  trackEvent,
  trackIntegrationEvent,
  setPosition,
  addAttribute,
  removeAttribute,
};
