export type TsExampleNamespaceState = {
  count: number;
};

export enum Actions {
  /* eslint-disable no-unused-vars */
  Increment = 'ts-example/Increment',
  Decrement = 'ts-example/Decrement',
}

export interface Increment {
  type: Actions.Increment;
}

export interface Decrement {
  type: Actions.Decrement;
}

export type TsExampleAction = Increment | Decrement;
